import { PWAMeetingEvent, sendMsgToPWA } from '../../pwa-integration';
import { getMeetingChannelId, isEnablePMC } from '../../service';
import { isExternalControlledMode } from '../../util';

import { saveKeepPhoneConnected } from '../../../actions/MeetingActions';
import {
  enterOrExitFullScreen,
  setIsMinimizeMode,
  setIsPIPMode,
} from '../../../actions/MeetingUIActions';
import { setLeaveInDialog } from '../../../features/feedback/redux/feedback-actions';
import { toggleShowLeaveMeetingOptionBox } from '../../../features/footer/redux/footer-action';
import { renameZRChildUserDisplayName } from '../../../reducers/AttendeesListReducer';
import { EventQueueProvider } from '../../event-queue-provider';
import { isPanelist, isViewOnly } from '../../service/user-types';
import { leaveMeetingThunkAction } from './end-meeting-request';
import { feedbackOptions } from '../../../features/feedback/enum';
import meetingConfig from 'meetingConfig';
import { isWebinar } from '../../service/meeting-types';
import { coOrHostSelector } from '../selector';
import { isAudioBridge } from '../../op-feature-option';
import { getMeetingDetailsBinary } from '../../../features/feedback/util';

function openSurveyLink(survey) {
  if (survey) {
    window.open(survey, '_blank');
  }
}

export function sendLeaveMsgToPWAOnExit(isLeave, feedbackAndSurvey) {
  return (dispatch, getState) => {
    const {
      meeting: { meetingTopic, meetingId, meetingNumber },
    } = getState();

    let leaveMeetingData = null;
    openSurveyLink(feedbackAndSurvey.survey);
    if (feedbackAndSurvey.feedback) {
      leaveMeetingData = {
        feedback: feedbackAndSurvey.feedback,
        feedbackCustMessage: feedbackAndSurvey.feedbackCustMessage,
        meetingNumber: meetingNumber,
        postAttendeeUrl: feedbackAndSurvey.postAttendeeUrl,
        meetingId,
        nodeId: feedbackAndSurvey.nodeId,
        meetingDetails: feedbackAndSurvey.meetingDetailsBinary,
      };
    } else if (feedbackAndSurvey.postAttendeeUrl) {
      leaveMeetingData = {
        postAttendeeUrl: feedbackAndSurvey.postAttendeeUrl,
      };
    }
    sendMsgToPWA(
      isLeave ? PWAMeetingEvent.LEAVE : PWAMeetingEvent.END,
      leaveMeetingData,
    );
    const channelId = getMeetingChannelId();
    if (isEnablePMC() && channelId) {
      sendMsgToPWA(PWAMeetingEvent.SEND_CHANNEL_ID, {
        channelId,
        topic: meetingTopic,
      });
    }
  };
}

export function isSupportFeedbackAndSurvey(isHungUpRemotely = false) {
  return (dispatch, getState) => {
    const {
      meeting: {
        isOriginhost,
        currentUser,
        restrictFeatures,
        currentUser: { bHold, userRole, userId },
        useWBVideo,
      },
      feedback: {
        provideFeedbackByMyself,
        kickedByHost,
        leaveFromWaitingRoom,
        leaveForLockMeeting,
        leaveInDialog,
        surveyUrl,
      },
    } = getState();
    const isHostOrCoHost = coOrHostSelector(getState());

    const feedbackOption = meetingConfig.meetingOptions.WebclientEnableFeedback;
    const {
      enableFeedbackTextField,
      feedbackCustMessage,
      enableNewSurveyFlow,
      surveyNewFlowExcludeHostCoHost,
    } = meetingConfig.meetingOptions;
    const isFeedback =
      !isExternalControlledMode() &&
      !bHold &&
      !kickedByHost &&
      !leaveForLockMeeting &&
      !leaveFromWaitingRoom &&
      !leaveInDialog &&
      (feedbackOption === feedbackOptions.FEEDBACK_EVERYONE ||
        (feedbackOption === feedbackOptions.FEEDBACK_RANDOMLY &&
          !isHungUpRemotely &&
          provideFeedbackByMyself));

    const meetingDetailsBinary = getMeetingDetailsBinary({
      isWebinar: isWebinar(),
      isAudioBridge: isAudioBridge(),
      useWBVideo: useWBVideo || false,
    });
    const isSurvey = (() => {
      if (isExternalControlledMode() || !surveyUrl) {
        return false;
      }
      if (enableNewSurveyFlow) {
        if (
          surveyNewFlowExcludeHostCoHost &&
          (isHostOrCoHost ||
            isOriginhost ||
            meetingConfig.isAlternativeHost ||
            isPanelist(userRole))
        ) {
          return false;
        }
        return true;
      }
      return (
        (!isWebinar() && !isOriginhost && !restrictFeatures.meetingSurvey) ||
        (isViewOnly(currentUser.userRole) && !restrictFeatures.webinarSurvey)
      );
    })();

    return {
      feedback: isFeedback
        ? `${feedbackOption}${Number(enableFeedbackTextField)}`
        : false,
      feedbackCustMessage,
      survey: isSurvey ? surveyUrl : false,
      postAttendeeUrl:
        meetingConfig.postAttendeeUrl && !isExternalControlledMode()
          ? meetingConfig.postAttendeeUrl
          : '',
      nodeId: userId,
      meetingDetailsBinary: meetingDetailsBinary,
    };
  };
}
export const cancelToLeave = () => (dispatch, getState) => {
  const {
    meeting: { isHost },
  } = getState();
  dispatch(setLeaveInDialog(true));
  if (isHost) {
    dispatch(toggleShowLeaveMeetingOptionBox(true));
    return true;
  }
  dispatch(leaveMeetingThunkAction());
  return false;
};

export function setIsMinimizeModeThunk(data) {
  return (dispatch, getState) => {
    const {
      meetingUI: { fullScreen },
    } = getState();
    if (fullScreen) {
      dispatch(enterOrExitFullScreen(false));
    }
    dispatch(setIsMinimizeMode(data));
  };
}

export function setKeepPhoneConnected(keepPhoneConnected) {
  return (dispatch) => {
    dispatch(saveKeepPhoneConnected(keepPhoneConnected));
  };
}

export function setZRChildUserName(participant, displayName) {
  return (dispatch) => {
    dispatch(renameZRChildUserDisplayName({ participant, displayName }));
  };
}

export const addNewChatListener = () => {
  return (dispatch, getState) => {
    EventQueueProvider.inject(
      { getState, dispatch },
      require('../../../features/new-chat/chat-evt-conf').default,
      'chat-evt-conf',
    );
    EventQueueProvider.inject(
      { getState, dispatch },
      require('../../../features/participants/participants-evt.conf').default,
      'participants-evt.conf',
    );
  };
};
export const toggleMinimize = (isMinimizeMode) => (dispatch) => {
  sendMsgToPWA(
    isMinimizeMode
      ? PWAMeetingEvent.MEETING_MAXIMIZE
      : PWAMeetingEvent.MEETING_MINIMIZE,
  );
  dispatch(setIsMinimizeModeThunk(!isMinimizeMode));
};

export const togglePIP = (isPIPMode) => (dispatch) => {
  dispatch(setIsPIPMode(!isPIPMode));
};
