import { globalVariable } from '../../../../global/global-variable';
import { sendSocketMessage } from '../../../../actions/SocketActions';
import {
  ADD_RENDER_VIDEO,
  STOP_RENDER_VIDEO,
  ZOOM_RENDER_VIDEO,
  ALL_COLOR_AFTER_VIDEO_STOP,
  WATERMARK_COVER_TYPE,
  VIDEO_LAYOUT_ZONE,
  VIDEO_TYPE_90P,
  VIDEO_LAYOUT_SPEAK_VIEW,
} from '../../enum';

import {
  WS_VIDEO_MULTI_UNSUBSCRIBE_REQ,
  WS_VIDEO_MULTI_SUBSCRIBE_REQ,
} from '../../../../constants/ZoomSocketEventTypes';

import renderDiff from '../../service/renderDiff';
import subscribeDiff from '../../service/subscribeDiff';
import calculateQuality from '../../service/calculateQuality';
import { getVideoFillMode } from '../../service/service';
import {
  setIsSubscribeActiveChannel,
  setActiveChannelQuality,
  setSubscribedVideoMaxQuality,
} from '../video-action';
import { isEnableVideoWaterMaskSelector } from '../selectors/video-status-selector';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isViewOnly } from '../../../../global/service/user-types';
import { isUseUnifiedRender } from '../../../../global/op-feature-option';
import {
  isInSharingModeSelector,
  isSimuliveVideoMode,
  isSupportMultiView,
  isTeslaMode,
} from '../../../../global';
import {
  activeSpeakerIdSelector,
  videoBasicAllAttendeeListSelector,
} from '../selectors/video-list-selector';
import { spotlightVideoListSelector } from '../selectors/video-common-selector';
import { pinVideoListSelector } from '../../../../global/redux/base-menu-selector';

export function subscribeVideo(videos) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId: currentUserId },
      },
    } = getState();

    const subInfoList = [];
    videos.forEach((item) => {
      if (item.user.userId !== currentUserId) {
        subInfoList.push({
          id: item.user.userId,
          size: item.quality,
          bOn: false,
        });
        if (
          item.zone === VIDEO_LAYOUT_ZONE.SPEAKER_ACTIVE_VIDEO &&
          item.quality === 3 &&
          JsMediaSDK_Instance.Zoom_Monitor
        ) {
          /** monitor log about subscribe 720p */
          JsMediaSDK_Instance.Zoom_Monitor.add_monitor('WCLSZ3');
        }
      }
    });
    if (subInfoList.length) {
      dispatch(
        sendSocketMessage({
          evt: WS_VIDEO_MULTI_SUBSCRIBE_REQ,
          body: { subInfoList },
        }),
      );
    }
  };
}

export function unsubscribeVideo(videos) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId: currentUserId },
      },
    } = getState();

    const subIDList = [];
    videos.forEach((item) => {
      if (item.user.userId !== currentUserId) {
        subIDList.push({ id: item.user.userId });
      }
    });
    if (subIDList.length) {
      dispatch(
        sendSocketMessage({
          evt: WS_VIDEO_MULTI_UNSUBSCRIBE_REQ,
          body: { subIDList },
        }),
      );
    }
  };
}

export function stopRenderVideo({
  user,
  canvasId,
  RGBA,
  doNotClean = false,
  x,
  y,
  width,
  height,
  zone,
}) {
  return () => {
    if (globalVariable.avSocket?.sendSocket && user.displayVideoOn) {
      globalVariable.avSocket.sendSocket(STOP_RENDER_VIDEO, {
        userId: user.userId,
        canvas: canvasId,
        RGBA,
        doNotClean,
        x,
        y,
        width,
        height,
        zone,
      });
    }
  };
}

export function zoomRenderVideo({
  userId,
  width,
  height,
  x,
  y,
  canvasId,
  zone,
}) {
  return () => {
    globalVariable.avSocket.sendSocket(ZOOM_RENDER_VIDEO, {
      userId,
      x,
      y,
      width,
      height,
      canvas: canvasId,
      zone,
      RGBA: ALL_COLOR_AFTER_VIDEO_STOP,
    });
  };
}

export function addRenderVideo({
  userId,
  width,
  height,
  x,
  y,
  canvasId,
  quality,
  zone,
  fillMode,
}) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      meeting: {
        waterMarkText,
        currentUser: { userId: currentUserId },
      },
      video: { watermarkOpacityLevel, watermarkCoverType, watermarkPosition },
    } = state;

    const enableWaterMark = isEnableVideoWaterMaskSelector(state);

    globalVariable.avSocket.sendSocket(ADD_RENDER_VIDEO, {
      userId,
      width,
      height,
      x,
      y,
      canvas: canvasId,
      quality,
      isMyself: userId === currentUserId,
      enableWaterMark,
      waterMarkText,
      zone,
      watermarkOpacity: watermarkOpacityLevel,
      watermarkRepeated: watermarkCoverType === WATERMARK_COVER_TYPE.REPEATED,
      watermarkPosition,
      fillMode,
    });
  };
}

export function calculateCurrentRenderVideo({
  previousCurrentRenderVideo,
  currentRenderVideo,
}) {
  return () => {
    const renderDiffMap = renderDiff(
      previousCurrentRenderVideo || [],
      currentRenderVideo,
    );

    const { cuurentUnsubscribeList, cuurentSubscribeList } = subscribeDiff({
      previousCurrentRenderVideo,
      currentRenderVideo,
      renderDiffMap,
    });

    return {
      renderDiffMap,
      cuurentSubscribeList,
      cuurentUnsubscribeList,
    };
  };
}

export function notifyMediaSDKVideoInfo(renderDiffMap) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { isClientPageVisible },
    } = state;

    renderDiffMap.stop.forEach((item) => {
      dispatch(
        stopRenderVideo({
          user: item.user,
          canvasId: item.canvasId,
          RGBA: ALL_COLOR_AFTER_VIDEO_STOP,
          doNotClean: !isClientPageVisible,
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
          zone: item.zone,
        }),
      );
    });

    renderDiffMap.zoom
      .filter((item) => !item.onlyChangeQuality)
      .forEach((item) => {
        dispatch(
          zoomRenderVideo({
            userId: item.user.userId,
            x: item.x,
            y: item.y,
            width: item.width,
            height: item.height,
            canvasId: item.canvasId,
            zone: item.zone,
          }),
        );
      });

    renderDiffMap.start.forEach((item) => {
      dispatch(
        addRenderVideo({
          userId: item.user.userId,
          width: item.width,
          height: item.height,
          x: item.x,
          y: item.y,
          canvasId: item.canvasId,
          quality: item.quality,
          zone: item.zone,
          fillMode: getVideoFillMode(),
        }),
      );
    });
  };
}

export function notifyRWGVideoInfo({
  cuurentUnsubscribeList,
  cuurentSubscribeList,
}) {
  return (dispatch) => {
    dispatch(unsubscribeVideo(cuurentUnsubscribeList));
    dispatch(subscribeVideo(cuurentSubscribeList));
  };
}

export function renderVideoThunk({
  previousCurrentRenderVideo,
  currentRenderVideo,
  previousReceivedVideoQuality,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { receivedVideoQuality },
      meeting: {
        currentUser: { userRole },
      },
    } = state;

    if (
      (!previousCurrentRenderVideo || !previousCurrentRenderVideo.length) &&
      !currentRenderVideo?.length
    ) {
      return;
    }
    const isSelfSupport1080P = isWebinar() && isViewOnly(userRole);
    // calculateQuality
    const currentRenderVideoTemp = calculateQuality(
      currentRenderVideo || [],
      receivedVideoQuality,
      isSelfSupport1080P,
    );

    const previousCurrentRenderVideoTemp = calculateQuality(
      previousCurrentRenderVideo || [],
      previousReceivedVideoQuality,
      isSelfSupport1080P,
    );

    //get render/subscribe info from previous render list
    const { renderDiffMap, cuurentSubscribeList, cuurentUnsubscribeList } =
      dispatch(
        calculateCurrentRenderVideo({
          previousCurrentRenderVideo: previousCurrentRenderVideoTemp,
          currentRenderVideo: currentRenderVideoTemp,
        }),
      );

    if (isSimuliveVideoMode() || !isUseUnifiedRender()) {
      dispatch(notifyMediaSDKVideoInfo(renderDiffMap));
    }

    dispatch(
      notifyRWGActiveChannel({
        cuurentSubscribeList,
        currentRenderVideo,
        previousCurrentRenderVideo,
        cuurentUnsubscribeList,
      }),
    );

    dispatch(
      notifyRWGVideoInfo({ cuurentUnsubscribeList, cuurentSubscribeList }),
    );

    if (CLIENT_ENV === 'development') {
      // record current max decode size  only for dev
      const subscribedVideoMaxQuality = currentRenderVideoTemp.reduce(
        (maxSize, item) =>
          item?.user?.displayVideoOn
            ? Math.max(maxSize, item.quality)
            : maxSize,
        0,
      );
      dispatch(setSubscribedVideoMaxQuality(subscribedVideoMaxQuality));
    }
  };
}

export function subscribeVideoThunk({
  previousCurrentRenderVideo,
  currentRenderVideo,
  previousReceivedVideoQuality,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { receivedVideoQuality },
      meeting: {
        currentUser: { userRole },
      },
    } = state;

    if (
      (!previousCurrentRenderVideo || !previousCurrentRenderVideo.length) &&
      !currentRenderVideo.length
    ) {
      return;
    }
    const isSelfSupport1080P = isWebinar() && isViewOnly(userRole);
    // calculateQuality
    const currentRenderVideoTemp = calculateQuality(
      currentRenderVideo || [],
      receivedVideoQuality,
      isSelfSupport1080P,
    );

    const previousCurrentRenderVideoTemp = calculateQuality(
      previousCurrentRenderVideo || [],
      previousReceivedVideoQuality,
      isSelfSupport1080P,
    );

    //get render/subscribe info from previous render list
    const { cuurentSubscribeList, cuurentUnsubscribeList } = dispatch(
      calculateCurrentRenderVideo({
        previousCurrentRenderVideo: previousCurrentRenderVideoTemp,
        currentRenderVideo: currentRenderVideoTemp,
      }),
    );

    // active channel subscribe/unsubscribe
    dispatch(
      notifyRWGActiveChannel({
        cuurentSubscribeList,
        currentRenderVideo,
        previousCurrentRenderVideo,
        cuurentUnsubscribeList,
      }),
    );

    dispatch(
      notifyRWGVideoInfo({ cuurentUnsubscribeList, cuurentSubscribeList }),
    );

    if (CLIENT_ENV === 'development') {
      // record current max decode size  only for dev
      const subscribedVideoMaxQuality = currentRenderVideoTemp.reduce(
        (maxSize, item) =>
          item?.user?.displayVideoOn
            ? Math.max(maxSize, item.quality)
            : maxSize,
        0,
      );
      dispatch(setSubscribedVideoMaxQuality(subscribedVideoMaxQuality));
    }
  };
}

function notifyRWGActiveChannel({
  cuurentSubscribeList,
  currentRenderVideo,
  previousCurrentRenderVideo,
  cuurentUnsubscribeList,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: {
        isClientPageVisible,
        isClientDisableReceiveVideo,
        UI: { videoLayout, isVideoRenderTransform },
        isTeslaParked,
        isSubscribeActiveChannel,
      },
      meetingUI: { isMinimizeMode, isPIPMode },
    } = state;

    const spotlightVideoList = spotlightVideoListSelector(state);
    const pinVideoList = pinVideoListSelector(state);
    const isInSharingMode = isInSharingModeSelector(state);
    const hasShareArea =
      isTeslaMode() ||
      (isInSharingMode && isTeslaParked && !isVideoRenderTransform);
    const allViewParticipants = videoBasicAllAttendeeListSelector(state);

    if (
      !isMinimizeMode &&
      !isPIPMode &&
      !isClientDisableReceiveVideo &&
      isClientPageVisible &&
      isSupportMultiView() &&
      videoLayout === VIDEO_LAYOUT_SPEAK_VIEW &&
      (spotlightVideoList || []).length === 0 &&
      (pinVideoList || []).length === 0 &&
      allViewParticipants.length > 1 &&
      !hasShareArea
    ) {
      removeRedundantUnsubscriptions({
        previousCurrentRenderVideo,
        cuurentUnsubscribeList,
        isSubscribeActiveChannel,
      });
      const activeSpeakerId = activeSpeakerIdSelector(state);
      const existActive = (currentRenderVideo || []).some(
        (item) =>
          item?.user &&
          item.user.userId >> 10 === activeSpeakerId >> 10 &&
          item.user.bVideoOn,
      );

      // active is not exist in currentRenderVideo
      if (!existActive) {
        // unsubscribe active channel
        dispatch(unsubscribeActiveChannel());
        dispatch(
          cancelDuplicateSubscribe({
            previousCurrentRenderVideo,
            cuurentSubscribeList,
          }),
        );
        return;
      }

      const subscribeActiveIdx = (cuurentSubscribeList || []).findIndex(
        (item) =>
          item?.user && item.user.userId >> 10 === activeSpeakerId >> 10,
      );

      if (subscribeActiveIdx === -1) {
        return;
      }
      const activeUser = cuurentSubscribeList[subscribeActiveIdx];
      // subscribe active channel
      dispatch(subscribeActiveChannel(activeUser.quality));
      // small window only subscribe low quality, beacuse active channel already subscribe high quality
      activeUser.quality = VIDEO_TYPE_90P;
      dispatch(
        cancelDuplicateSubscribe({
          previousCurrentRenderVideo,
          cuurentSubscribeList,
        }),
      );
    } else {
      dispatch(unsubscribeActiveChannel());
    }
  };
}

function unsubscribeActiveChannel() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { isSubscribeActiveChannel },
    } = state;

    if (!isSubscribeActiveChannel) {
      return;
    }
    dispatch(setActiveChannelQuality(-1));
    dispatch(setIsSubscribeActiveChannel(false));
    dispatch(
      sendSocketMessage({
        evt: WS_VIDEO_MULTI_UNSUBSCRIBE_REQ,
        body: { subIDList: [{ id: 1 }] },
      }),
    );
  };
}

function subscribeActiveChannel(quality) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { isSubscribeActiveChannel, activeChannelQuality },
    } = state;

    if (isSubscribeActiveChannel && activeChannelQuality === quality) {
      return;
    }
    const subInfoList = [
      {
        id: 1,
        size: quality,
        bOn: false,
      },
    ];
    dispatch(setActiveChannelQuality(quality));
    dispatch(setIsSubscribeActiveChannel(true));
    // subscribe active channel
    dispatch(
      sendSocketMessage({
        evt: WS_VIDEO_MULTI_SUBSCRIBE_REQ,
        body: { subInfoList },
      }),
    );
  };
}

function cancelDuplicateSubscribe({
  previousCurrentRenderVideo,
  cuurentSubscribeList,
}) {
  return () => {
    // cancel duplicate subscribe
    if (previousCurrentRenderVideo && (cuurentSubscribeList || []).length > 0) {
      const newCuurentSubscribeList = cuurentSubscribeList.filter(
        (subscribeItem) => {
          const isDuplicateSubscribe = previousCurrentRenderVideo.some(
            (item) => {
              return (
                item.user.userId === subscribeItem.user.userId &&
                item.user.bVideoOn &&
                item.user.displayVideoOn
              );
            },
          );

          return !isDuplicateSubscribe;
        },
      );

      if (newCuurentSubscribeList.length !== cuurentSubscribeList.length) {
        cuurentSubscribeList.splice(0);
        cuurentSubscribeList.push(...newCuurentSubscribeList);
      }
    }
  };
}

function removeRedundantUnsubscriptions({
  previousCurrentRenderVideo,
  cuurentUnsubscribeList,
  isSubscribeActiveChannel,
}) {
  if (
    !isSubscribeActiveChannel ||
    !previousCurrentRenderVideo ||
    !cuurentUnsubscribeList
  ) {
    return;
  }
  const previousRenderActiveUser = previousCurrentRenderVideo.find(
    (item) =>
      item.zone === VIDEO_LAYOUT_ZONE.SPEAKER_ACTIVE_VIDEO &&
      item.user.bVideoOn,
  );
  // exist active channel subscription in the render list of last time
  if (previousRenderActiveUser) {
    // exist preactive user in the cuurentUnsubscribeList
    const previousActiveIndex = cuurentUnsubscribeList.findIndex((item) => {
      return item.user.userId === previousRenderActiveUser.user.userId;
    });
    // exist private subscription of preactive user
    const hasActivePrivateSubscribe = previousCurrentRenderVideo.some(
      (item) =>
        item.zone !== VIDEO_LAYOUT_ZONE.SPEAKER_ACTIVE_VIDEO &&
        item.user.userId === previousRenderActiveUser.user.userId,
    );
    if (previousActiveIndex !== -1 && !hasActivePrivateSubscribe) {
      cuurentUnsubscribeList.splice(previousActiveIndex, 1);
    }
  }
}
