import { iText } from '../../global/util';

export const VIEW_ON_CHANNEL_NAME = (channelName) =>
  iText(`View on ${channelName}`, 'apac.live_stream.view_on_channel_name', [
    channelName,
  ]);

export const LIVE_ON_CHANNEL_NAME = (channelName) =>
  iText(`Live on ${channelName}`, 'apac.live_stream.live_on_channel_name', [
    channelName,
  ]);

export const COPY_LINK = iText('Copy Link', 'apac.live_stream.copy_link');

export const STOP_LIVE_STREAM = iText(
  'Stop Live Stream',
  'apac.live_stream.stop_live_stream',
);

export const MEETING_IS_NOW_STREAMING_LIVE_ON_CHANNEL_NAME = (channelName) =>
  iText(
    `Meeting is now streaming live on ${channelName}`,
    'apac.live_stream.meeting_is_now_streaming_live_on_channel_name',
    [channelName],
  );

export const CUSTOM_LIVE_STREAMING_SERVICE = iText(
  'Custom Live Streaming Service',
  'apac.live_stream.custom_live_streaming_service',
);

export const ACCEPT_TXT = iText('Accept', 'apac.live_stream.accept_txt');
export const LLS_PERMISSION_DIALOG_TITLE = iText(
  'Request to livestream',
  'apac.live_stream.request_permission_title',
);
export const GRANT_PERMISSION_TEXT = iText(
  'Grant Permission',
  'apac.live_stream.grant_permission',
);
export const LLS_PERMISSION_DIALOG_CONTENT = (displayName, reqURL) =>
  iText(
    `${displayName} is asking for permission to livestream to a custom streaming service (${reqURL}).`,
    'apac.live_stream.request_permission_content',
    [displayName, reqURL],
  );
export const REMOVE_PERMISSION_TEXT = iText(
  'Remove Permission to Livestream',
  'apac.live_stream.remove_permission',
);

export const ACTIVE_STREAMS_TEXT = (length) =>
  iText(`Active Streams (${length}) `, 'apac.live_stream.active_streams_text', [
    length,
  ]);
export const CUSTOM_STREAMING_SERVICE_TEXT = iText(
  'Custom Streaming Service',
  'apac.live_stream.custom_streaming_service_text',
);
export const LIVE_ON_TEXT = iText('Live on', 'apac.live_stream.live_on_text');
export const LIVE_STOP_TEXT = iText('Stop', 'apac.live_stream.live_stop_text');
