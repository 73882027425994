import { CONTROL_MODE_COMMON_ERROR_TYPE } from '../../enum';
import {
  ZOOM_CONNECTOR_EVT_ENUM,
  GOOGLE_MEET_END_MEETING_REASON,
  GOOGLE_MEET_TRANSITION_STATE,
  GOOGLE_MEET_ERROR_CODE_PREFIX as CODE_PREFIX,
  GOOGLE_MEET_ERROR_CODE_SUFFIX as CODE_SUFFIX,
  CONTENT_SHARE_STATE,
  VIDEO_RESOLUTION,
} from './enum';
import { coOrHostSelector } from '../../../global/redux';
import { makeLogger, reportToGlobalTracing } from '../../../global/logger';
import { setAvDisabledTipVisible } from '../../../actions/MeetingUIActions';
import {
  setSelfMuteOrUnmute,
  setControllerDisableAudio,
} from '../../../features/audio/redux/audio-action';
import {
  unmuteAudio,
  muteAudio,
} from '../../../features/audio/redux/audio-thunk-action';
import {
  showNotAllowUnmuteDialog,
  muteVideo,
  showDisableSharingDialog,
} from '../../../features/dialog/redux/dialog-thunk-action';
import {
  setControllerDisableVideo,
  setIsNeedNotifyStartVideoFail,
  setShouldRebackPreviousView,
  setShowSelfView,
} from '../../../features/video/redux/video-action';
import {
  startCaptureVideo,
  stopCaptureVideo,
} from '../../../features/video/redux/thunks/start-stop-capture-video-thunk';
import { isVideoEncodeReadySelector } from '../../../features/video/redux/selectors/video-status-selector';
import {
  VIDEO_LAYOUT_SPEAK_VIEW,
  MUTE_VIDEO_MODAL_STOP,
  VIDEO_LAYOUT_GALLERY_VIEW,
} from '../../../features/video/enum';
import { setVideoLayoutThunkImpl } from '../../../features/video/redux/thunks/set-video-layout-thunk';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import { leaveMeetingThunkAction } from '../../../global/redux/thunk-action/end-meeting-request';
import { isAutoTurnOnVideo } from '../../utils';
import { globalVariable } from '../../../global/global-variable';
import {
  START_DESKTOP_SHARING,
  SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID,
  SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID,
  SHARER_PERMISSION_CODE,
} from '../../../features/sharing/enum';
import { getShareCaptureMode } from '../../../features/sharing/service';
import {
  stopSharer,
  pauseOrResumeShareAudio,
} from '../../../features/sharing/redux/sharing-thunk-action';

export const externalEvtHandler = (store, adaptor) => {
  const { getState, dispatch } = store;

  // Immediately set Speaker View
  // because setAllThunks() is not finished setting up the alias for setVideoLayoutThunk yet at this point, we call the function directly
  dispatch(setShouldRebackPreviousView(VIDEO_LAYOUT_GALLERY_VIEW));
  dispatch(setVideoLayoutThunkImpl(VIDEO_LAYOUT_SPEAK_VIEW));

  // Always force self view to be true to avoid privacy issues related to this value being off.
  dispatch(setShowSelfView(true));

  let {
    meeting: {
      meetingTopic,
      currentUser: { muted },
      meetingNumber,
    },
    meetingUI: { isOnHold },
    sharing: { sharerIsShareOptionLocked, sharerEnabled },
  } = getState();

  const selectHardware = (label, selectedLabel, callback) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const targetDevice = devices.find(
        (device) =>
          device.kind === label && device.label.includes(selectedLabel),
      );

      if (targetDevice) {
        callback(targetDevice.deviceId);
      }
    });
  };

  const videoLogger = makeLogger(['Google-Meet Video']);
  const audioLogger = makeLogger(['Google-Meet Audio']);
  const shareLogger = makeLogger(['Google-Meet Share']);

  const handlePostMessage = async ({ data, origin }) => {
    if (!adaptor.isAllowedOrigin(origin)) return;
    let type = (data || {}).type;

    if (type !== 'roomSystemStateResponse') {
      type = ((data || {}).data || {}).type;
    }

    const state = getState();
    const {
      meeting: {
        res,
        bCanUnmute,
        bCanUnmuteVideo,
        loginUserUid,
        currentUser,
        currentUser: {
          userRole,
          isAllowTalk: isCurrentUserAllowTalk,
          bVideoMute: isCurrentUserVideoMuted,
        },
      },
      settings: {
        vbSettings: { enableVB, enableForceUseVB },
      },
      audio: { isMicrophoneForbidden },
      video: {
        UI: { loading },
        cameraDevicesList,
        isCameraCaptureLoading,
        isCameraForbidden,
        isNeedNotifyStartVideoFail,
      },
      dialog: {
        unmuteByHost: { visible: isUnmuteByHostVisible },
      },
      sharing: { sharerSsrc, sharerPermissionCode },
    } = state;

    const isViewOnlyUser = isViewOnly(userRole);
    const coOrHost = coOrHostSelector(state);
    const isVideoEncodeReady = isVideoEncodeReadySelector(state);

    switch (type) {
      case 'roomSystemStateResponse': {
        const { selectedCameraLabel, selectedMicLabel } =
          (data || {}).data || {};

        // store selected camera label
        selectHardware('videoinput', selectedCameraLabel, adaptor.setCameraId);

        // store selected mic label
        selectHardware('audioinput', selectedMicLabel, adaptor.setMicId);

        break;
      }
      case ZOOM_CONNECTOR_EVT_ENUM.MUTE_AUDIO: {
        if (data.isHardwareLevel) {
          dispatch(setAvDisabledTipVisible(true));
          dispatch(setControllerDisableAudio(true));
        }

        if (isMicrophoneForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_AUDIO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_AUDIO_FAILED,
          });
          audioLogger.error(
            `Value of isMicrophoneForbidden: ${isMicrophoneForbidden}`,
            ['GOOGLE_MEET_MUTE_AUDIO'],
          );

          return;
        }

        if (isWebinar() && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_AUDIO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_AUDIO_FAILED,
          });
          audioLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_MUTE_AUDIO',
          ]);
          audioLogger.error(
            `Value of isCurrentUserAllowToTalk: ${isCurrentUserAllowTalk}`,
            ['GOOGLE_MEET_MUTE_AUDIO'],
          );

          return;
        }

        adaptor.notifyControllerMeetingUpdate({ audioMuted: true });
        dispatch(setSelfMuteOrUnmute(true));
        dispatch(muteAudio(currentUser.userId));
        audioLogger.log('Google-Meet audio successfully muted', [
          'GOOGLE_MEET_MUTE_AUDIO',
        ]);

        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.UNMUTE_AUDIO: {
        const isAllowedToMute =
          coOrHost || bCanUnmute || !currentUser.muted || isUnmuteByHostVisible;

        if (data.isHardwareLevel) {
          dispatch(setControllerDisableAudio(false));
        }

        if (isMicrophoneForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
          });
          audioLogger.error(
            `Value of isMicrophoneForbidden: ${isMicrophoneForbidden}`,
            ['GOOGLE_MEET_UNMUTE_AUDIO'],
          );
          return;
        }

        if (isWebinar() && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
          });
          dispatch(showNotAllowUnmuteDialog());
          audioLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_UNMUTE_AUDIO',
          ]);
          audioLogger.error(
            `Value of isCurrentUserAllowToTalk: ${isCurrentUserAllowTalk}`,
            ['GOOGLE_MEET_UNMUTE_AUDIO'],
          );
          return;
        }

        if (!isAllowedToMute) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
          });
          dispatch(showNotAllowUnmuteDialog());
          audioLogger.error(
            `Value of isAllowedToMute: ${isAllowedToMute}`,
            'GOOGLE_MEET_UNMUTE_AUDIO',
          );
          return;
        }

        adaptor.notifyControllerMeetingUpdate({ audioMuted: false });
        dispatch(setSelfMuteOrUnmute(true));
        dispatch(unmuteAudio(currentUser.userId));
        audioLogger.log('Google-Meet audio successfully unmuted', [
          'GOOGLE_MEET_UNMUTE_AUDIO',
        ]);

        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.MUTE_VIDEO: {
        if (data.isHardwareLevel) {
          dispatch(setAvDisabledTipVisible(true));
          dispatch(setControllerDisableVideo(true));
        }

        if (isWebinar() && isViewOnlyUser) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_VIDEO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_VIDEO_FAILED,
          });
          videoLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_MUTE_VIDEO',
          ]);
          return;
        }

        if (isCameraCaptureLoading || isCameraForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_VIDEO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_VIDEO_FAILED,
          });
          videoLogger.error(
            `Value of isCameraCaptureLoading: ${isCameraCaptureLoading}`,
            ['GOOGLE_MEET_MUTE_VIDEO'],
          );
          videoLogger.error(
            `Value of isCameraForbidden: ${isCameraForbidden}`,
            ['GOOGLE_MEET_MUTE_VIDEO'],
          );
          return;
        }

        adaptor.notifyControllerMeetingUpdate({ videoMuted: true });
        dispatch(stopCaptureVideo());
        videoLogger.log('Google-Meet video successfully muted', [
          'GOOGLE_MEET_MUTE_VIDEO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.UNMUTE_VIDEO: {
        if (data.isHardwareLevel) {
          dispatch(setControllerDisableVideo(false));
        }

        if (isWebinar() && isViewOnlyUser) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_VIDEO,
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
          });
          videoLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
        }

        if (!isVideoEncodeReady || loading) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_VIDEO,
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
          });
          videoLogger.error(
            `Value of isVideoEncodeReady: ${isVideoEncodeReady}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
          videoLogger.error(`Value of loading: ${loading}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
        }

        if (cameraDevicesList.length < 1) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_VIDEO,
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
          });
          videoLogger.error(
            `Value of cameraDeviceList.length: ${cameraDevicesList.length}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
        }

        if (
          (isCurrentUserVideoMuted && !coOrHost) ||
          (!bCanUnmuteVideo && !coOrHost)
        ) {
          dispatch(
            muteVideo({
              type: MUTE_VIDEO_MODAL_STOP,
            }),
          );
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_VIDEO,
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
          });
          videoLogger.error(
            `Value of isCurrentUserVideoMuted: ${isCurrentUserVideoMuted}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
          videoLogger.error(`Value of bCanUnmuteVideo: ${bCanUnmuteVideo}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
          return;
        }

        if (loginUserUid && enableForceUseVB && !enableVB) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_VIDEO,
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
          });
          videoLogger.error(`Value of loginUserId: ${loginUserUid}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
          videoLogger.error(`Value of enableForceUseVB: ${enableForceUseVB}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
          videoLogger.error(`Value of enableVB: ${enableVB}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
        }

        if (isNeedNotifyStartVideoFail) {
          dispatch(setIsNeedNotifyStartVideoFail(false));
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_VIDEO,
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
          });
          videoLogger.error(
            `Value of isNeedNotifyStartVideoFail: ${isNeedNotifyStartVideoFail}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
        }

        adaptor.notifyControllerMeetingUpdate({ videoMuted: false });
        dispatch(startCaptureVideo());
        videoLogger.log('Google-Meet video successfully unmuted', [
          'GOOGLE_MEET_UNMUTE_VIDEO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.USER_INITIATED_LOG_UPLOAD: {
        const config = {
          userId: currentUser?.userId,
          filter: () => true,
          confId: currentUser?.confId,
          conID: currentUser?.conId,
          svcUrl: currentUser?.svcUrl,
          res,
        };

        reportToGlobalTracing(config);
        break;
      }

      case GOOGLE_MEET_END_MEETING_REASON.LOCAL_HANGUP: {
        const isHungUpRemotely = true;

        adaptor.notifyControllerMeetingIsEnding();
        dispatch(leaveMeetingThunkAction({ isHungUpRemotely }));
        adaptor.notifyControllerMeetingHasEnded();
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.START_CONTENT_SHARE: {
        if (
          sharerPermissionCode ===
            SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE &&
          !coOrHost
        ) {
          dispatch(showDisableSharingDialog());
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.CONTENT_SHARE_NOT_ALLOWED,
            errorCode:
              CODE_PREFIX.APPLICATION_ERROR + CODE_SUFFIX.CONTENT_SHARE_FAILED,
          });
          shareLogger.error(
            `Value of sharerPermissionCode: ${sharerPermissionCode}`,
            ['GOOGLE_MEET_START_CONTENT_SHARE'],
          );
          return;
        }

        const getDeviceId = async (data, deviceKind) => {
          const deviceLabel =
            deviceKind.indexOf('video') !== -1
              ? data?.video?.deviceLabel
              : data?.audio?.deviceLabel;

          const devices = await navigator.mediaDevices.enumerateDevices();
          const selectedDevice = devices.find(
            (device) =>
              device.kind == deviceKind && device.label === deviceLabel,
          );

          return selectedDevice?.deviceId ?? '';
        };

        const contentShareParamsData = (data || {}).data?.data;
        const audioDeviceId = await getDeviceId(
          contentShareParamsData,
          'audioinput',
        );
        const videoDeviceId = await getDeviceId(
          contentShareParamsData,
          'videoinput',
        );

        globalVariable.avSocket.sendSocket(START_DESKTOP_SHARING, {
          mode: getShareCaptureMode(),
          ssid: sharerSsrc,
          canvas: SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID,
          video: SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID,
          share2ndCamera: true,
          share2ndCameraParams: {
            VideoSelectValue: videoDeviceId,
            AudioSelectValue: audioDeviceId,
            frameRate: 60,
            width: VIDEO_RESOLUTION.WIDTH,
            height: VIDEO_RESOLUTION.HEIGHT,
          },
        });
        shareLogger.log('Google-Meet started content share successfully', [
          'GOOGLE_MEET_START_CONTENT_SHARE',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.MUTE_CONTENT_SHARE_AUDIO: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { isAudioMuted: true },
        });
        dispatch(pauseOrResumeShareAudio(true));
        shareLogger.log('Google-Meet mute content share audio successfully', [
          'GOOGLE_MEET_MUTE_CONTENT_SHARE_AUDIO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.UNMUTE_CONTENT_SHARE_AUDIO: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { isAudioMuted: false },
        });
        dispatch(pauseOrResumeShareAudio(false));
        shareLogger.log('Google-Meet unmute content share audio successfully', [
          'GOOGLE_MEET_UNMUTE_CONTENT_SHARE_AUDIO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.STOP_CONTENT_SHARE: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { state: CONTENT_SHARE_STATE.INACTIVE },
        });
        dispatch(stopSharer());
        shareLogger.log('Google-Meet stopped content share successfully', [
          'GOOGLE_MEET_STOP_CONTENT_SHARE',
        ]);
        break;
      }

      default:
        break;
    }
  };

  const initialMeetingStatus = () => {
    if (!window.parent) {
      return;
    }
    let state = GOOGLE_MEET_TRANSITION_STATE.CONNECTING;
    if (isOnHold) {
      state = GOOGLE_MEET_TRANSITION_STATE.LOBBY;
    }

    const meetingState = {
      name: meetingTopic,
      clientMeetingId: meetingNumber.toString(),
      audioMuted: !!muted,
      videoMuted: !isAutoTurnOnVideo(),
      contentShare: {
        isAllowed: sharerIsShareOptionLocked || sharerEnabled,
        state: CONTENT_SHARE_STATE.INACTIVE,
        isAudioMuted: false,
      },
      state,
    };
    adaptor.notifyControllerInitialMeetingStatus(meetingState);
  };

  window.addEventListener('message', handlePostMessage);
  adaptor.requestRoomSystemState();
  initialMeetingStatus();

  return () => {
    window.removeEventListener('message', handlePostMessage);
  };
};
