import React from 'react';
import meetingConfig from 'meetingConfig';
import { isEnableWebinarChat, isCoOrHost } from '../../global/service';
import {
  chatPriviledgeToReceiverId,
  chatPriviledgeToText,
  DLPCheckType,
  panelistChatPriviledgeToText,
  panelistChatPriviledgeToReceiverId,
  CHAT_MSG_TYPE_TO_ALL,
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  CHAT_MSG_TYPE_TO_PANELIST,
  BUILT_IN_CHAT_RECEIVER_MAP,
  CHAT_FILE_TYPE,
  CHAT_FILE_DOWNLOAD_STATUS,
  CHAT_FILE_UPLOAD_STATUS,
  CHAT_FILE_UPLOAD_ERROR_TYPE,
} from './constants';
import {
  everyoneText,
  FILE_DOWNLOADED_AGAIN_TEXT,
  FILE_DOWNLOADING_TIP,
  FILE_DOWNLOAD_TIP,
  FILE_REMOVED_TIP,
  FILE_RESEND_TIP,
  FILE_UPLOADING_TIP,
  privatelyText,
  thirdPartyFileOpenTip,
} from './resource';
import {
  CHAT_PRIVILEDGE_ALL,
  CHAT_PRIVILEDGE_ALL_PANELIST,
  CHAT_PRIVILEDGE_EVERYONE_PUBLICLY,
} from '../../constants/Constants';
import { WEBINAR_ATTENDEES } from '../../constants/UserRoles';
import { isAudioType, isVideoType } from '@zoom/zoom-react-ui';
import X2JS from '@zoom/pwa-x2js';
import { CHAT_FROM } from '../../global/constant';
import { isWebinar } from '../../global/service/meeting-types';
import { isPanelist, isViewOnly } from '../../global/service/user-types';

/**
 * get receiverId is participant or not
 */
export const isBuildInChatReceiver = (receiverId) => {
  return BUILT_IN_CHAT_RECEIVER_MAP.has(receiverId);
};

export const isMMRSupportWRTwoWayChat = (mmrFlag) => {
  if (isWebinar()) return false;
  return (mmrFlag & (1 << 28)) !== 0;
};

export const isWRTwoWayChatWebEnable = () => {
  return !!meetingConfig.meetingOptions.isEnableTwoWayChatInWaitingRoom;
};

export const getPrivately = (type) => (
  <span
    className="chat-privately"
    style={{ padding: type === 1 ? '2px 0' : '0' }}
  >
    {`(${privatelyText})`}
  </span>
);

/**
 * fork from https://github.com/IonicaBizau/regex-parser.js
 * @param input {string}
 * @returns {RegExp}
 */
const regexParser = (() => {
  const cacheRegExp = {};
  return (input) => {
    // Validate input
    if (typeof input !== 'string') {
      return null;
    }

    if (!cacheRegExp[input]) {
      // Parse input
      const m = input.match(/(\/?)(.+)\1([a-z]*)/i);
      // Invalid flags
      if (m[3] && !/^(?!.*?(.).*?\1)[gmixXsuUAJ]+$/.test(m[3])) {
        cacheRegExp[input] = new RegExp(input);
      } else {
        // Create the regular expression
        cacheRegExp[input] = new RegExp(m[2], m[3]);
      }
    }

    return cacheRegExp[input];
  };
})();

export const DLPCheck = (chatModerationPolicy, text) => {
  if (!chatModerationPolicy || !text) {
    return null;
  }
  return chatModerationPolicy.reduce((s, v) => {
    if (s) {
      return s;
    }

    const {
      ruleType, // 1: keywords, 2: regular expression
      ruleValue, // keyword: 'a,b,c'
      action: blockType, // 1: no action, 2: ask user to confirm, 3: block the message
    } = v;

    if (ruleType === DLPCheckType.keyword) {
      // keyword check
      if (
        ruleValue
          .split(',')
          .map((v) => v.trim())
          .filter((v) => v)
          .some((keyword) =>
            text
              .split(' ')
              .map((v) => v.trim())
              .filter((v) => v)
              .some((v) => v.toLowerCase() === keyword.toLowerCase()),
          )
      ) {
        return blockType;
      }
    } else if (ruleType === DLPCheckType.regex) {
      // RegExp check
      const regexp = regexParser(ruleValue);
      if (regexp && regexp.test(text)) {
        return blockType;
      }
    }

    return null;
  }, null);
};

// only archive chat will disable chat when archiving failed
export const archivingStatus = {
  success: 1,
  fail: 0,
  leave: 2,
  noStatus: -1,
};

export const archivingDataReady = (status, enabled) => {
  return status !== undefined && enabled;
};

export const archiveChatDisabled = (archiveInfo) => {
  const { disableChat, status, enabled } = archiveInfo;

  return (
    archivingDataReady(status, enabled) &&
    disableChat &&
    status === archivingStatus.fail
  );
};

const getDefaultChatPriviledge = () => {
  const chatDefaultSetting = {
    chatPrivilege: meetingConfig.meetingOptions.chatPrivilege || 1,
    panelistChatPrivilege: 0,
    webinarAttendeeChatPrivilege: 0,
  };
  if (isWebinar() && isEnableWebinarChat()) {
    chatDefaultSetting.panelistChatPrivilege =
      meetingConfig.meetingOptions.panelistChatPrivilege || 0;
    chatDefaultSetting.webinarAttendeeChatPrivilege =
      meetingConfig.meetingOptions.webinarAttendeeChatPrivilege || 0;
  }
  return chatDefaultSetting;
};
export const defaultChatSetting = getDefaultChatPriviledge();

/**
 *
 * // Chat Priviledge
  export const CHAT_PRIVILEDGE_ALL = 1; // everyone and anyone directly
  export const CHAT_PRIVILEDGE_ALL_PANELIST = 2; // host and panelist
  export const CHAT_PRIVILEDGE_HOST = 3; // host and co-host
  export const CHAT_PRIVILEDGE_NOONE = 4; // no one
  export const CHAT_PRIVILEDGE_EVERYONE_PUBLICLY = 5; // every one
  export const CHAT_SEND_MSG = 0;
  export const CHAT_RECEIVE_MSG = 1;
 */
export function getDefaultReceiverByRole() {
  const userRole = meetingConfig.userRole;
  const isViewOnly = userRole === 0;
  const isPanelist = userRole === -1;
  const isHost = userRole === 1;
  const isEnableWebinarChatInOp = isEnableWebinarChat();
  const { chatPrivilege } = meetingConfig.meetingOptions;
  if (isWebinar() && isEnableWebinarChatInOp) {
    if (isViewOnly) {
      let privilege =
        chatPrivilege === CHAT_PRIVILEDGE_ALL
          ? defaultChatSetting.webinarAttendeeChatPrivilege // only 1 or 2 valid
          : defaultChatSetting.chatPrivilege;
      if (
        privilege !== CHAT_PRIVILEDGE_ALL &&
        privilege !== CHAT_PRIVILEDGE_ALL_PANELIST
      ) {
        privilege = CHAT_PRIVILEDGE_ALL_PANELIST;
      }
      return {
        receiver: chatPriviledgeToText[privilege],
        receiverId: chatPriviledgeToReceiverId[privilege],
      };
    }
    if (isPanelist) {
      const privilege = meetingConfig.meetingOptions.panelistChatPrivilege;
      return {
        receiver: panelistChatPriviledgeToText[privilege],
        receiverId: panelistChatPriviledgeToReceiverId[privilege],
      };
    }
    if (isHost) {
      const privilege = CHAT_PRIVILEDGE_ALL;
      return {
        receiver: chatPriviledgeToText[privilege],
        receiverId: chatPriviledgeToReceiverId[privilege],
      };
    }
  }
  return {
    receiver: everyoneText,
    receiverId:
      chatPriviledgeToReceiverId[chatPrivilege] ?? CHAT_MSG_TYPE_TO_ALL,
  };
}

export function canChatWithOther({
  isMe,
  currentUser,
  targetId,
  targetUser,
  isThisWebinar,
  isPrivateChatEnabled,
  curChatPrivilege,
  fr,
}) {
  if (fr === CHAT_FROM.IM) return;
  // not Me & not bHold
  if (isMe) return false;
  if (currentUser.bHold) return false;
  if (targetId === currentUser.userId) return false;
  // meeting
  if (!isThisWebinar) {
    if (
      isCoOrHost(currentUser) &&
      targetId !== CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS
    ) {
      // cohost can chat with anyone
      return targetId !== currentUser.userId;
    }
    if (!_.isEmpty(targetUser)) {
      // for meeting, targetUser is normal participant
      if (isCoOrHost(targetUser)) return true;
      if (
        curChatPrivilege === CHAT_PRIVILEDGE_EVERYONE_PUBLICLY &&
        isPrivateChatEnabled
      ) {
        return true;
      }
      if (isCoOrHost(currentUser) && targetUser.bHold) return true;
    } else if (
      // everyone/waiting room participant
      (targetId === CHAT_MSG_TYPE_TO_ALL &&
        [CHAT_PRIVILEDGE_ALL, CHAT_PRIVILEDGE_EVERYONE_PUBLICLY].includes(
          curChatPrivilege,
        )) ||
      (isCoOrHost(currentUser) && targetUser.bHold)
    ) {
      return true;
    }
    return false;
  }
  // webinar
  if (_.isEmpty(targetUser)) {
    if (targetId === CHAT_MSG_TYPE_TO_ALL) {
      if (isViewOnly(currentUser.userRole)) {
        // attendee -> everyone
        return curChatPrivilege === CHAT_PRIVILEDGE_ALL;
      }
      // hosts -> everyone
      if (isCoOrHost(currentUser)) {
        return true;
      }
      // panelist -> everyone
      return curChatPrivilege === CHAT_PRIVILEDGE_ALL;
    }
    // in webinar, users always can chat to hosts and panelists
    return targetId === CHAT_MSG_TYPE_TO_PANELIST;
  }
  if (targetUser.role && targetUser.role === WEBINAR_ATTENDEES) {
    // attendee -> attendee
    if (isViewOnly(currentUser.userRole)) return false;
    // hosts -> attendee
    if (isCoOrHost(currentUser)) return true;
    // panelists -> attendee
    if (isPanelist(currentUser.userRole)) {
      return curChatPrivilege === CHAT_PRIVILEDGE_ALL;
    }
  }
  if (isPanelist(targetUser)) {
    // panelists -> panelist & hosts -> panelists
    if (isPanelist(currentUser.userRole) || isCoOrHost(currentUser))
      return isPrivateChatEnabled;
    // attendee -> panelist
    if (isViewOnly(currentUser.userRole)) return false;
  }
  if (isCoOrHost(targetUser)) {
    // panelists -> hosts
    if (isPanelist(currentUser.userRole)) return isPrivateChatEnabled;
    // attendee -> hosts
    if (isViewOnly(currentUser.userRole)) return false;
  }
  return false;
}

export const getFileSuffix = (fileName) => {
  if (!fileName) {
    return '';
  }
  const suffixIndex = fileName.lastIndexOf('.');
  if (suffixIndex < 0) {
    return '';
  }
  const suffix = fileName.substring(suffixIndex + 1).toLocaleLowerCase();
  return suffix;
};

export const fileIconTypes = [
  'txt',
  'excel',
  'doc',
  'ppt',
  'pdf',
  'img',
  'audio',
  'video',
  'zip',
  'code',
  'html',
  'sketch',
];

export const fileMimeTypes = [
  ['text/plain', 'text/csv'],
  [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  ['application/pdf'],
  ['image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/tiff'],
  ['audio/*'],
  ['video/*'],
  ['application/zip', 'application/x-7z-compressed', 'application/x-gzip'],
  ['cpp', 'java', 'js', 'ts', 'jsx', 'tsx', 'php', 'xml'],
  ['text/html'],
  ['sketch'],
];
export const imageFileMimeTypes = fileMimeTypes[5];
export const suffixToMimeTypeMap = {
  txt: 'text/plain',
  pdf: 'application/pdf',
  bmp: 'image/bmp',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  tiff: 'image/tiff',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  mpg: 'video/*',
  mpeg: 'video/*',
  avi: 'video/*',
  mov: 'video/*',
  wmv: 'video/*',
  mp4: 'video/*',
  mid: 'audio/*',
  wav: 'audio/*',
  mp3: 'audio/*',
  wma: 'audio/*',
  zip: 'application/zip',
  '7z': 'application/x-7z-compressed',
  gz: 'application/x-gzip',
  html: 'text/html',
  csv: 'text/csv',
};

export const getFileSize = (size) => {
  if (size < 1024) {
    return `${size} bytes`;
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)} KB`;
  }
  return `${(size / 1024 / 1024).toFixed(1)} MB`;
};

export const getFileIcon = (fileType, suffix) => {
  const targetFileTypeIndex = fileMimeTypes.findIndex(
    (types) =>
      types.findIndex(
        (type) =>
          type === fileType ||
          (isAudioType(type) && isAudioType(fileType)) ||
          (isVideoType(type) && isVideoType(fileType)) ||
          (!fileType && suffix === type),
      ) > -1,
  );
  const targetFileType = fileIconTypes[targetFileTypeIndex];
  if (targetFileType) {
    return targetFileType;
  }
  return 'general';
};

export const getMimeTypeByName = (fileName) => {
  return suffixToMimeTypeMap[getFileSuffix(fileName)] || '';
};

export function isMessageSentByUser(senderId, msgId, meetingChatList) {
  const msgIdToSenderIdObject = meetingChatList.reduce((acc, curr) => {
    function getGroupOfMessagesForOneSender(senderId, chatMsgsArray) {
      return chatMsgsArray.reduce((acc, curr) => {
        return {
          [curr.msgId]: senderId,
          ...acc,
        };
      }, {});
    }
    return {
      ...acc,
      ...getGroupOfMessagesForOneSender(curr.senderId, curr.chatMsgs || []),
    };
  }, {});

  return senderId === msgIdToSenderIdObject[msgId];
}

export const getShareTypeName = (type) => {
  if (!type) return '';
  switch (type.toLocaleLowerCase()) {
    case 'gdrive':
    case 'googledrive':
      return 'Google Driver';
    case 'dropbox':
      return 'Dropbox';
    case 'onedrive':
    case 'microsoftonedrive':
      return 'One Drive';
    case 'box':
      return 'Box';
    case 'sharepoint':
    case 'microsoftsharepoint':
      return 'Share Point';
    default:
      return '';
  }
};

export const getChatFileErrorTip = (file) => {
  const { uploadError, uploadErrorType } = file;
  if (
    uploadError &&
    uploadErrorType === CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED
  ) {
    return `-   ${FILE_REMOVED_TIP}`;
  } else if (uploadError) {
    return `-   ${FILE_RESEND_TIP}`;
  } else {
    return `-   ${FILE_DOWNLOAD_TIP}`;
  }
};

export const getChatFileActionTip = (file) => {
  const { downloadStatus, uploadCanceled, uploadStatus } = file;
  const readyForDownload =
    uploadStatus === CHAT_FILE_UPLOAD_STATUS.SUCCESS || !uploadStatus;
  if (
    readyForDownload &&
    downloadStatus !== CHAT_FILE_DOWNLOAD_STATUS.SUCCESS &&
    downloadStatus !== CHAT_FILE_DOWNLOAD_STATUS.DOWNLOADING &&
    downloadStatus !== CHAT_FILE_DOWNLOAD_STATUS.ERROR
  ) {
    return `-   ${FILE_DOWNLOAD_TIP}`;
  } else if (downloadStatus === CHAT_FILE_DOWNLOAD_STATUS.SUCCESS) {
    return `-   ${FILE_DOWNLOADED_AGAIN_TEXT}`;
  } else if (uploadCanceled) {
    return `-   ${FILE_RESEND_TIP}`;
  } else {
    return null;
  }
};

export const getChatFileText = (file) => {
  const { fileType, shareType, uploadError, uploadStatus, downloadStatus } =
    file;
  if (fileType === CHAT_FILE_TYPE.THIRD_PARTY) {
    return thirdPartyFileOpenTip(getShareTypeName(shareType));
  } else if (
    uploadError ||
    downloadStatus === CHAT_FILE_DOWNLOAD_STATUS.ERROR
  ) {
    return getChatFileErrorTip(file);
  } else if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.PENDING) {
    return FILE_UPLOADING_TIP;
  } else if (downloadStatus === CHAT_FILE_DOWNLOAD_STATUS.DOWNLOADING) {
    return FILE_DOWNLOADING_TIP;
  } else if (
    !uploadError ||
    (!uploadStatus && downloadStatus !== CHAT_FILE_DOWNLOAD_STATUS.ERROR)
  ) {
    return getChatFileActionTip(file);
  } else {
    return null;
  }
};

const x2js = new X2JS();

export const convertXml2Json = (params) => {
  return x2js.xml_str2json(params);
};

export function convertJSon2XML(val) {
  return x2js.json2xml_str(val);
}

export function getCorrect3rdPartyFileUrl(fileInfo) {
  let correctUrl;
  if (fileInfo) {
    const { previewPath, previewUrl } = fileInfo;
    if (previewPath && meetingConfig.integrationDomain) {
      let finalPreviewPath = previewPath;
      if (finalPreviewPath.indexOf('/') !== 0) {
        finalPreviewPath = finalPreviewPath + '/';
      }
      correctUrl = meetingConfig.integrationDomain + finalPreviewPath;
    } else {
      if (meetingConfig.fileTransfer.isVerify3rdFileUrlEnabled) {
        // not set previewPath
        correctUrl = '';
      } else {
        correctUrl = previewUrl;
      }
    }
  }

  return correctUrl;
}

/**
 * @param {Object} fileInfo
 * @property {string} fileID
 * @property {string} fileObj
 * @param {string} fileServerDomain
 * @returns string
 */
export function getFileDownloadUrl(fileInfo, fileServerDomain) {
  if (!fileInfo.fileID && !fileInfo.fileObj) {
    return '';
  }
  return fileInfo.fileObj
    ? `https://${fileServerDomain}/zoomfile/download?objkey=${fileInfo.fileObj}`
    : `https://${fileServerDomain}/file/${fileInfo.fileID}`;
}
