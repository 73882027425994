import { iText } from '../../global/util';

// common
export const DIALOG_CANCEL = iText('Cancel', 'apac.dialog.btn_cancle');
export const DIALOG_YES = iText('Yes', 'apac.wc_yes');
export const DIALOG_NO = iText('No', 'apac.wc_no');
export const DIALOG_SAVE = iText('Save', 'apac.dialog.btn_save');
export const DIALOG_RETRY = iText('Retry', 'apac.dialog.btn_retry');
export const DIALOG_EXIT = iText('Exit', 'apac.dialog.btn_exit');
export const DIALOG_OK = iText('OK', 'apac.dialog.ok');
export const DIALOG_CHANGE = iText('Change', 'apac.dialog.change');
export const DIALOG_DENY = iText('Deny', 'apac.dialog.deny');
export const DIALOG_APPROVE = iText('Approve', 'apac.dialog.approve');
export const DIALOG_DECLINE = iText('Decline', 'apac.dialog.decline');
export const DIALOG_STOP = iText('Stop', 'apac.dialog.stop');
export const DIALOG_ADD = iText('Add', 'apac.dialog.add');
export const DIALOG_VIEW_DETAILS = iText(
  'View Details',
  'apac.dialog.view_details',
);
export const DIALOG_CREATE = iText('Create', 'apac.dialog.btn_create');
export const DIALOG_CLOSE = iText('Close', 'apac.wc_chat.close');
export const DIALOG_CONTINUE = iText('Continue', 'apac.wc_continue');
export const DIALOG_LEAVE = iText(
  'Leave',
  'apac.has_been_removed_dialog.ok_text',
);
export const CALL = iText('Call', 'apac.wc_call');
export const DIALOG_CONFIRM_TITLE = iText(
  'Confirmation',
  'apac.wc_dlg_confirm',
);
export const DIALOG_END_WEBINAR = iText(
  'End Webinar',
  'apac.dialog.btn_endconf_webinar',
);
export const DIALOG_END_MEETING = iText(
  'End Meeting',
  'apac.dialog.btn_endconf_meeting',
);
export const DIALOG_LEAVE_WEBINAR = iText(
  'Leave Webinar',
  'apac.dialog.btn_leaveconf_webinar',
);
export const DIALOG_LEAVE_MEETING = iText(
  'Leave Meeting',
  'apac.dialog.btn_leaveconf_meeting',
);
export const DIALOG_MEETING_ALERT = iText(
  'Meeting alert',
  'apac.wc_video.meeting_alert',
);
export const DIALOG_ERROR_CODE = iText('Error Code:', 'apac.dialog.error_code');
// lock conf
export const LOCK_CONF_WEBINAR_HEADER = iText(
  'Lock Webinar',
  'apac.dialog.lock_webinar',
);
export const LOCK_CONF_WEBINAR_HEADER_DESC1 = iText(
  'Are you sure you want to lock this webinar now?',
  'apac.dialog.lock_webinar_confirm',
);
export const LOCK_CONF_WEBINAR_HEADER_DESC2 = iText(
  'Locking will prevent any other people from joining the webinar.',
  'apac.dialog.webinar_lock_prevent_join',
);
export const LOCK_CONF_MEETING_HEADER = iText(
  'Lock Meeting',
  'apac.dialog.lock_meeting',
);
export const LOCK_CONF_MEETING_HEADER_DESC1 = iText(
  'Are you sure you want to lock this meeting now?',
  'apac.dialog.lock_meeting_confirm',
);
export const LOCK_CONF_MEETING_HEADER_DESC2 = iText(
  'Locking will prevent any other people from joining the meeting.',
  'apac.dialog.meeting_lock_prevent_join',
);
export const LOCK_CONF_LOCK_BTN = iText('Lock', 'apac.toolbar_lock');
export const LOCK_CONF_WARNING = iText(
  'Do not warn me again.',
  'apac.dialog.warning',
);

// unlock conf
export const UNLOCK_CONF_TITLE = iText(
  'New attendees can join this meeting once unlocked.',
  'apac.wc_unlock_meeting_content',
);
export const UNLOCK_MEETING = iText('Unlock Meeting', 'apac.wc_unlock_meeting');
export const UNLOCK_MEETING_CONTENT = iText(
  'The meeting has been locked by the host',
  'apac.wc_unlock_meeting_content2',
);
// hangup
export const HANG_UP_BTN = iText('Hang Up', 'apac.dialog.btn_hangup');
export const HANG_UP_CONFIRM = iText(
  'Are you sure you want to disconnect from audio?',
  'apac.dialog.hangup_confirm',
);
export const HANG_UP_CONFIRM_2 = iText(
  'You will be disconnected from audio and remain as a viewer.',
  'apac.dialog.remain_view',
);

// end conf
export const END_CONF_END_WEBINAR_TITLE = iText(
  'End this webinar',
  'apac.dialog.end_webinar',
);
export const END_CONF_END_WEBINAR_CONFIRM_1 = iText(
  'Are you sure you want to end this webinar now?',
  'apac.dialog.end_webinar_confirm',
);
export const END_CONF_END_WEBINAR_CONFIRM_2 = iText(
  'Everyone on the call will be disconnected and webinar will be over?',
  'apac.dialog.webinar_over',
);
export const END_CONF_LEAVE_WEBINAR_TITLE = iText(
  'Leave this webinar',
  'apac.dialog.leave_webinar',
);
export const END_CONF_LEAVE_WEBINAR_CONFIRM = iText(
  'Are you sure you want to leave this webinar now?',
  'apac.dialog.leave_webinar_confirm',
);
export const END_CONF_END_MEETING_TITLE = iText(
  'End this meeting',
  'apac.dialog.end_meeting',
);
export const END_CONF_END_MEETING_CONFIRM_1 = iText(
  'Are you sure you want to end this meeting now?',
  'apac.dialog.end_meeting_confirm',
);
export const END_CONF_END_MEETING_CONFIRM_2 = iText(
  'Everyone on the call will be disconnected and meeting will be over?',
  'apac.dialog.meeting_over',
);
export const END_CONF_LEAVE_MEETING_TITLE = iText(
  'Leave this meeting',
  'apac.dialog.leave_meeting',
);
export const END_CONF_LEAVE_MEETING_CONFIRM = iText(
  'Are you sure you want to leave this meeting now?',
  'apac.dialog.leave_meeting_confirm',
);

// rename
export const RENAME_TITLE = iText(
  'Enter a new name below',
  'apac.dialog.rename',
);
export const RENAME_LABEL_NAME = iText('Name', 'apac.dialog.newname');

// expel
export const EXPEL_CONFIRM = iText(
  'Do you want to remove',
  'apac.wc_remove_user',
);
export const expelAttendee = (name) =>
  iText(
    `Do you want to remove ${name}? Once removed, ${name} will not be able to rejoin the webinar`,
    'apac.wc_remove_attendee',
    [name, name],
  );

// reconnect conf
export const RECONNECT_CONF_TITLE = iText(
  'Something went wrong',
  'apac.dialog.something_wrong',
);
export const RECONNECT_CONF_CONFIRM = iText(
  'The server encountered an internal error and was unable to process your request.',
  'apac.dialog.server_error',
);

// expel info
export const EXPEL_INFO_TITLE = iText(
  'You have been removed',
  'apac.dialog.you_are_removed',
);
export const EXPEL_INFO_WEBINAR_DESC = iText(
  'You have been removed from this webinar by the host.',
  'apac.dialog.webinar_removed_by_host',
);
export const EXPEL_INFO_MEETING_DESC = iText(
  'You have been removed from this meeting by the host.',
  'apac.dialog.meeting_removed_by_host',
);

// conf ended
export const CONF_ENDED_WEBINAR_TITLE = iText(
  'The webinar has been ended',
  'apac.dialog.webinar_ended',
);
export const CONF_ENDED_WEBINAR_DESC = iText(
  'This webinar has been ended by host',
  'apac.dialog.host_end_webinar',
);
export const CONF_ENDED_WEBINAR_BY_RSGW_TITLE = iText(
  'This webinar has ended',
  'apac.dialog.rsgw_end_webinar',
);
export const CONF_ENDED_BY_UNKNOWN_ERROR = iText(
  'An unknown error occurred',
  'apac.dialog.webinar_end_by_error',
);
export const JOIN_WEBINAR_LATER_DESC = iText(
  'Please join this webinar later.',
  'apac.dialog.join_webinar_later',
);
export const CONF_ENDED_MEETING_TITLE = iText(
  'The meeting has been ended',
  'apac.dialog.meeting_ended',
);
export const CONF_ENDED_MEETING_DESC = iText(
  'This meeting has been ended by host',
  'apac.dialog.host_end_meeting',
);
export const CONF_ENDED_MEETING_BY_START_NEW_MEETING = iText(
  'This meeting has ended as someone has started a new meeting with this account',
  'apac.dialog.meeting_ended_by_new_meeting',
);
export const CONF_ENDED_MEETING_BY_ADMIN = iText(
  'This meeting has been ended by your admin',
  'apac.dialog.meeting_ended_by_admin',
);
export const CONF_ENDED_WEBINAR_BY_ADMIN = iText(
  'This webinar has been ended by your admin',
  'apac.dialog.webinar_ended_by_admin',
);

// conf full
export const CONF_FULL_WEBINAR_TITLE = iText(
  'The webinar is at capacity',
  'apac.dialog.webinar_capacity_reached_title',
);
export const CONF_FULL_WEBINAR_DESC = iText(
  'This webinar has reached the maximun number of participants. Please try again later.',
  'apac.dialog.webinar_capacity_reached_content1',
);
export const CONF_FULL_WEBINAR_DESC2 = iText(
  'This webinar has reached the maximun number of participants.You can watch a live stream of this webinar from your browser.',
  'apac.dialog.webinar_capacity_reached_content2',
);
export const CONF_FULL_WEBINAR_BTN = iText(
  'Watch Live Stream',
  'apac.dialog.webinar_capacity_reached_btn',
);

export const CONF_FULL_MEETING_TITLE = iText(
  'Meeting capacity has been reached',
  'apac.dialog.meeting_capacity_reached',
);
export const CONF_FULL_MEETING_DESC = iText(
  'You cannot join this meeting because it has reached the maximum number of participant allowed.',
  'apac.dialog.meeting_cannot_join_reached_capacity',
);

// conf locked
export const CONF_LOCKED_WEBINAR_TITLE = iText(
  'The webinar has been locked',
  'apac.dialog.webinar_locked',
);
export const CONF_LOCKED_WEBINAR_DESC = iText(
  'You cannot join this webinar now because the host locked it.',
  'apac.dialog.webinar_cannot_join_locked',
);
export const CONF_LOCKED_MEETING_TITLE = iText(
  'The meeting has been locked',
  'apac.dialog.meeting_locked',
);
export const CONF_LOCKED_MEETING_DESC = iText(
  'You cannot join this meeting now because the host locked it.',
  'apac.dialog.meeting_cannot_join_locked',
);

// about video
export const ABOUT_VIDEO_TITLE = iText('About video', 'apac.wc_about_video');
export const ABOUT_VIDEO_DESC = iText(
  'Zoom does not currently support sharing your video from a browser.',
  '',
);

// join voip timeout
export const JOIN_VOIP_TIMEOUT_TITLE = iText(
  'Unable to use computer audio',
  'apac.wc_unable_pc_audio',
);
export const JOIN_VOIP_TIMEOUT_DESC = iText(
  'Zoom is temporarily unable to use your computer\'s devices to connect to the meeting audio. Click "Join Audio by Computer" to try again.',
  'apac.wc_join_audio_timeout',
);

// make host
export const MAKE_HOST_CONFIRM = iText(
  'Do you want to change the host to',
  'apac.wc_make_host_text',
);

// make co-host
export const tMakeCoHost = (userName) =>
  iText(
    `Do you want to make ${userName} the co-host of this meeting?`,
    'apac.wc_make_co_host_text',
    userName,
  );
// mute video
export const MUTE_VIDEO_DESC_START = iText(
  'The host has asked you to start your video',
  'apac.wc_video.model_start',
);
export const MUTE_VIDEO_DESC_STOP = iText(
  'You cannot start your video because the host has stopped it',
  'apac.wc_video.model_stop',
);
export const MUTE_VIDEO_BTN_START = iText(
  'Start My Video',
  'apac.wc_video.start_my_video',
);
export const MUTE_VIDEO_BTN_LATER = iText('Later', 'apac.wc_video.later');

// unmute audio
export const UNMUTE_AUDIO_CONFIRM = iText(
  'The host is not allowing participants to unmute themselves',
  'apac.wc_audio.cannot_unmute',
);

// request control
export function requestControlText1(userName) {
  return iText(
    `You are about to request remote control of ${userName}'s shared content`,
    'apac.wc_remote_control.text1',
    [userName],
  );
}
export function requestControlText2(userName) {
  return iText(
    `Select Request and wait for ${userName} approval.`,
    'apac.wc_remote_control.text2',
    [userName],
  );
}
export function requestControlPwaTitleText(userName) {
  return iText(
    `Request remote control of ${userName}’s screen?`,
    'apac.wc_remote_control_pwa.tilte',
    [userName],
  );
}
export function requestControlPwaTipText(userName) {
  return iText(
    `${userName} must install the Remote Control app to continue.`,
    'apac.wc_remote_control_pwa.tip',
    [userName],
  );
}
export const REQUEST_CONTROL_TEXT_3 = iText(
  "Select Cancel if you don't want to send a request.",
  'apac.wc_remote_control.text3',
);
export function requestControlText4(userName) {
  return iText(
    `${userName} declined your request`,
    'apac.wc_remote_control.text4',
    [userName],
  );
}
export const REQUEST_CONTROL_TEXT_5 = iText(
  'Select Request to try again',
  'apac.wc_remote_control.text5',
);
export const REQUEST_CONTROL_BTN = iText(
  'Request Remote Control',
  'apac.wc_remote_control.request',
);
export const REMOTE_CONTROL_APPROVE_BTN = iText(
  'Approve',
  'apac.wc_remote_control.approve_btn',
);
export const REMOTE_CONTROL_LAUNCH_BTN = iText(
  'Open App',
  'apac.wc_remote_control.launch_btn',
);
export const remoteControlApproveTitle = (userName) => {
  return iText(
    `${userName} is requesting remote control of your screen`,
    'apac.wc_remote_control.approve_title',
    [userName],
  );
};
export const REMOTE_CONTROL_APPROVE_TIMEOUT_TITLE = iText(
  'Remote control app launch timeout',
  'apac.wc_remote_control.approve_timeout_title',
);
export const REMOTE_CONTROL_APPROVE_TIP = iText(
  'You can regain control at any time by clicking on your screen.',
  'apac.wc_remote_control.approve_tip',
);
export const REMOTE_CONTROL_PWA_APPROVE_TIP1 = iText(
  "By approving the request, the user will gain access to view, navigate, and interact with your screen's content, including control over your computer settings. You can regain control at any time by clicking on your screen.",
  'apac.wc_remote_control_pwa.approve_tip1',
);
export const REMOTE_CONTROL_PWA_APPROVE_TIP2 = iText(
  'To enable remote control of your computer, you need to download and install Zoom Remote Control with a size less than 5MB later.',
  'apac.wc_remote_control_pwa.approve_tip2',
);

// export const REMOTE_CONTROL_PWA_APPROVE_TIP3 = iText(
//   'The app will be deleted automatically after the session ends.',
//   'apac.wc_remote_control_pwa.approve_tip3',
// );

export const remoteControlTimeoutTip = (userName) => {
  return iText(
    `Reapprove ${userName} to control your screen?`,
    'apac.wc_remote_control_pwa.approve_timeout_tip',
    [userName],
  );
};

export const REMOTE_CONTROL_TIMEOUT_TIP = iText(
  "By approving remote control of your screen, the user will gain access to view, navigate, and interact with your screen's content. You can regain control at any time by clicking on your screen",
  'apac.wc_remote_control_pwa.approve_timeout_tip2',
);

export const remoteControlSwitchUserTip = (oldUserName, newUserName) => {
  return iText(
    `Approving this request will transfer control from ${oldUserName} to ${newUserName}.`,
    'apac.wc_remote_control_pwa.approve_switch_tip',
    [oldUserName, newUserName],
  );
};
export const REMOTE_CONTROL_PWA_SWITCH_BTN = iText(
  'Approve and Transfer',
  'apac.wc_remote_control_pwa.approve_switch_btn',
);
export const REMOTE_CONTROL_PWA_SWITCH_DECLINE_BTN = iText(
  'Keep Current Control',
  'apac.wc_remote_control_pwa.approve_switch_decline_btn',
);
export const REMOTE_CONTROL_PWA_LAUNCH_TIP = iText(
  'When you click "Download App", the app\'s installer will be downloaded to your computer. After downloading, simply install the app and then click "Open App" to launch the app and join the remote control session.',
  'apac.wc_remote_control_pwa.launch_tip',
);
export const REMOTE_CONTROL_PWA_LAUNCH_NOT_SCREEN_BTN = iText(
  'Select Entire Screen',
  'apac.wc_remote_control_pwa.launch_not_screen_btn',
);
export const REMOTE_CONTROL_NOT_SCREEN_TIP = iText(
  'To be controlled, you must share your entire screen instead of a tab or window. After sharing the entire screen, you’ll be requested again.',
  'apac.wc_remote_control_pwa.launch_not_screen_tip',
);
export const REMOTE_CONTROL_PWA_LAUNCH_DOWNLOAD_BTN = iText(
  'Download App',
  'apac.wc_remote_control_pwa.download_btn',
);
export const REMOTE_CONTROL_PWA_LAUNCH_AS_ADMIN_TIP = iText(
  'Remote control as admin',
  'apac.wc_remote_control_pwa.launch_as_admin_tip',
);
export const REMOTE_CONTROL_PWA_LAUNCH_AS_ADMIN_TOOLTIP = iText(
  'Enable remote control of all apps, including apps with admin privileges.',
  'apac.wc_remote_control_pwa.launch_as_admin_tooltip',
);
export const REMOTE_CONTROL_PWA_SHARING_NEW_SCREEN_TITLE = iText(
  'Sharing a new screen?',
  'apac.wc_remote_control_pwa.sharing_new_screen_title',
);
export const startNewShareTipWhenBeingControlling = (userName) => {
  return iText(
    `Sharing a new screen will end the remote control of ${userName}.`,
    'apac.wc_remote_control_pwa.sharing_new_screen_tip',
    [userName],
  );
};

// connect error
export const CONNECT_ERROR_MEETING_TITLE = iText(
  'Joining Meeting Timeout or Browser restriction',
  'apac.wc_meeting_network_error_title',
);
export const CONNECT_ERROR_WEBINAR_TITLE = iText(
  'Joining Webinar Timeout or Browser restriction',
  'apac.wc_webinar_network_error_title',
);
export const CONNECT_ERROR_MEETING_CONTENT = iText(
  'Your network connection has timed out or your organization has disabled access to Zoom from the browser. Please verify your network connection or organizational policy.',
  'apac.wc_timeout_network_error_body',
);
export const CONNECT_ERROR_WEBINAR_CONTENT = iText(
  'Your network connection has timed out or your organization has disabled access to Zoom from the browser. Please verify your network connection or organizational policy.',
  'apac.wc_timeout_network_error_body',
);

// asign new cc type
export function asignCCConfirm([target1, target2]) {
  return iText(
    `${target1} is currently assigned to type Closed Caption, do you want to assign ${target2} to type instead?`,
    'apac.wc_assign_cc_confirm',
    [target1, target2],
  );
}

// mute all
export const MUTE_ALL_TITLE = iText('Mute All', 'apac.toolbar_muteall');
export const MUTE_ALL_TITLE_NEW = iText(
  'Mute Current and New Participants',
  'apac.toolbar_muteall_new',
);
export const MUTE_ALL_CONTENT = iText(
  'Mute all current and new participants',
  'apac.wc_audio.mute_all_confirm',
);
export const MUTE_ALL_CHECKBOX_TEXT = iText(
  'Allow participants to unmute themselves',
  'apac.wc_allow_unmute',
);

// stop recording
export const STOP_RECORDING_TITLE = iText(
  'Stop Cloud Recording?',
  'apac.wc_stop_recording_title2',
);
export const STOP_RECORDING_CONTENT = iText(
  'After stopping, you’ll receive an email notification when the cloud recording is ready.',
  'apac.wc_stop_recording_content2',
);

// mute on entry
export const MUTE_ON_ENTRY_TITLE = iText(
  'Mute Participants Upon Entry',
  'apac.wc_mute_on_entry_title',
);
export const MUTE_ON_ENTRY_TITLE_NEW = iText(
  'Mute New Participants Upon Entry',
  'apac.wc_mute_on_entry_title_new',
);
export const MUTE_ON_ENTRY_CONTENT = iText(
  'All the new participants will be muted',
  'apac.wc_mute_on_entry_content',
);
export const MUTE_ON_ENTRY_CHECKBOX_TEXT = MUTE_ALL_CHECKBOX_TEXT;
// promote attendee  to panelist
export const PROMOTE_TO_PANELIST_BTN = iText(
  'Promote to panelist',
  'apac.wc_webinar.promote_to_panelist',
);
export function allowToTalkPromote(target) {
  return iText(
    `Allow to talk is not availiable because ${target} is using an older version of Zoom. Choose Promote Panelist to allow ${target} to talk.`,
    'apac.wc_allow_to_talk_disabled',
    [target, target],
  );
}

export const TALK_BY_HOST_TITLE = iText(
  'The host would like you to speak',
  'apac.wc_audio.unmute_by_host_content_1',
);
// unmute by host
export const UNMUTE_BY_HOST_TITLE = iText(
  'The host would like you to unmute',
  'apac.wc_audio.unmute_by_host_content',
);
export const UNMUTE_BY_HOST_BTN1 = iText(
  'Stay muted',
  'apac.wc_unmute_by_host_btn1',
);
export const UNMUTE_BY_HOST_BTN2 = iText(
  'Switch to Computer Audio to unmute',
  'apac.wc_unmute_by_host_btn2',
);
export const UNMUTE_BY_HOST_BTN3 = iText(
  'Unmute myself',
  'apac.wc_unmute_by_host_btn3',
);
// hotfix 20230811 webinar allow to talk
export const TALK_BY_HOST_WEBINAR_CONTENT = iText(
  'If you choose to unmute, others in the webinar will be able to hear you. If the host or panelists decide to record, livestream, or archive the webinar after you unmute, your voice will be included. If the webinar is recorded it may be shared with Smart Recording which uses AI technology, which may include third-party models. Zoom does not use any audio, video, chat, screen sharing, attachments or other communications-like content (such as poll results, whiteboard and reactions) to train Zoom’s or third-party AI models.',
  'apac.wc_audio.unmute_by_host_content_6_2',
);
// hotfix 20230811 meeting allow to talk
export const TALK_BY_HOST_MEETING_CONTENT = iText(
  'If you choose to unmute, others in the meeting will be able to hear you. If the host or participants decide to record, livestream, or archive the meeting after you unmute, your voice will be included. If the meeting is recorded it may be shared with Smart Recording which uses AI technology, which may include third-party models. Zoom does not use any audio, video, chat, screen sharing, attachments or other communications-like content (such as poll results, whiteboard and reactions) to train Zoom’s or third-party AI models.',
  'apac.wc_audio.unmute_by_host_content_5_2',
);
export const UNMUTE_BY_HOST_CONTENT4 = iText(
  'When you unmute your microphone, your computer sound will stop.',
  'apac.wc_audio.unmute_by_host_content_4',
);
// unmuted by host for being spotlighted
export const UNMUTE_BY_HOST_CONTENT2 = iText(
  'The host has spotlighted your video for everyone. Would you like to unmute your microphone to speak?',
  'apac.wc_unmute_by_host_content2',
);
export const UNMUTE_BY_HOST_CONTENT3 = iText(
  'The host has spotlighted your video for everyone. Would you like to join audio to speak?',
  'apac.wc_unmute_by_host_content3',
);
export const UNMUTE_BY_HOST_BTN4 = iText(
  'Join Audio',
  'apac.toolbar_join_audio',
);
export const UNMUTE_BY_HOST_BTN5 = iText('Later', 'apac.wc_video.later');

// simulive webinar
export const SIMULIVE_AUTO_REPLY_TITLE = iText(
  'Q&A auto reply is On',
  'apac.wc_simulive_auto_reply_title',
);

export const SIMULIVE_WELCOME_MSG_PARAGRAPH1 = iText(
  "This simulive webinar is running automatically. You can't be seen or heard by the attendees. You can interact with the attendees via Q&A.",
  'apac.wc_simulive_welcome_msg_paragraph1',
);

export const SIMULIVE_WELCOME_DISABLE_AUTO_REPLY = iText(
  'Disable Auto Reply',
  'apac.wc_simulive_welcome_disable_auto_reply',
);

export const SIMULIVE_WELCOME_TURN_ON_AUTO_REPLY = iText(
  'Turn on Q&A Auto Reply',
  'apac.wc_simulive_welcome_turn_on_auto_reply',
);

export const SIMULIVE_AUTO_REPLY_TITLE2 = iText(
  'Q&A auto reply is Off',
  'apac.wc_simulive_auto_reply_title2',
);
export const SIMULIVE_AUTO_REPLY_CONTENT = iText(
  'This simulive webinar has enabled Q&A auto reply. You can view the Q&A report and reply later after the webinar.',
  'apac.wc_simulive_auto_reply_content',
);
export const SIMULIVE_NO_AUTO_REPLY_CONTENT = iText(
  'You can view the Q&A report and reply later after the webinar.',
  'apac.wc_simulive_no_auto_reply_content',
);
export const SIMULIVE_AUTO_REPLY_CONTENT2 = iText(
  'This simulive webinar does not enable Q&A auto reply. You will receive online attendee’s questions and you can reply to them manually.',
  'apac.wc_simulive_auto_reply_content2',
);
export const SIMULIVE_AUTO_REPLY_CONTENT3 = iText(
  'This simulive webinar has enabled Q&A auto reply. Do you want to reply by yourself?',
  'apac.wc_simulive_auto_reply_content3',
);
export const SIMULIVE_AUTO_REPLY_BTN1 = iText(
  'Continue Auto Reply',
  'apac.wc_simulive_auto_reply_btn1',
);
export const SIMULIVE_AUTO_REPLY_BTN2 = iText(
  'Reply by Myself',
  'apac.wc_simulive_auto_reply_btn2',
);

// multiple sharing
export const SWITCH_TO_SINGLE_SHARE_TITLE = iText(
  'Switch to single participant share',
  'apac.wc_switch_to_single_title',
);
export const SWITCH_TO_SINGLE_SHARE_CONTENT = iText(
  'More than 2 participants are sharing, switch will stop all sharing, do you want to continue?',
  'apac.wc_switch_to_single_content',
);
export const SHARING_SETTING_TITLE = iText(
  'Advanced Sharing Options',
  'apac.wc_sharing_setting_title',
);
export const SHARING_SETTING_LOCK_CONTENT = iText(
  'You cannot modify the advanced sharing options as these settings are locked by your admin.',
  'apac.wc_sharing_setting_lock',
);

// separate audio
export const SEPARATE_AUDIO_BTN = iText(
  'Separate',
  'apac.wc_separate_audio_btn',
);
export const SEPARATE_AUDIO_TITLE = iText(
  'Separate audio',
  'apac.wc_separate_audio_title',
);
export function separateAudioContent(name) {
  return iText(
    `Separate audio from ${name}'s video?`,
    'apac.wc_separate_audio_content',
    [name],
  );
}

// can not join meeting dialog
export const CANNOT_JOIN_TITLE = iText(
  'Unable to join this meeting',
  'apac.wc_cannot_join_title',
);
export const JOIN_WEBINAR_RESTRICTION_TITLE = iText(
  'Unable to join this webinar',
  'apac.wc_join_webinar_restriction_title',
);

export const JOIN_WEBINAR_RESTRICTION_CONTENT = iText(
  'You are restricted from joining webinars. Contact your account admin for more information.',
  'apac.wc_join_webinar_restriction_content',
);

export const CANNOT_JOIN_CONTENT = iText(
  'Your admin has restricted communication between certain groups and users.',
  'apac.wc_cannot_join_content',
);

export const CANNOT_JOIN_CONTENT2 = iText(
  "You've already joined this meeting on another device.",
  'apac.wc_cannot_join_content2',
);

// can not share dialog
export const CANNOT_SHARE_TITLE = iText(
  'Unable to share screen',
  'apac.wc_cannot_share_screen',
);
export const CANNOT_SHARE_CONTENT = iText(
  'Your admin has disabled screen sharing between certain groups and users.',
  'apac.wc_cannot_share_screen_content',
);
export const CANNOT_RECORDING_TITLE = iText(
  'Unable to record this meeting',
  'apac.wc_cannot_recording',
);
export const CANNOT_RECORDING_CONTENT = iText(
  'Your admin has disabled recording in meetings with certain groups and users.',
  'apac.wc_cannot_recording_content',
);

export const PREVENT_SHARE_ACCESS = iText(
  'Please grant browser access to Screen Recording.',
  'apac.wc_prevent_access_share',
);
export const PREVENT_MICROPHONE_ACCESS = iText(
  'Your browser is preventing access to your microphone.',
  'apac.wc_prevent_access_mic',
);

export const PREVENT_MICROPHONE_ACCESS_INTERPRETER = iText(
  'Your microphone has been disabled for this session',
  'apac.wc_prevent_access_mic_interpreter',
);

export const ALLOW_MICROPHONE_ACCESS_INTERPRETER = iText(
  'Your microphone is now available for this session',
  'apac.wc_allow_access_mic_interpreter',
);
export const LEARN = iText('Learn', 'apac.wc_learn');
export const HOW_TO_ALLOW = iText(
  'how to allow access to your microphone.',
  'apac.wc_how_to_allow',
);
export const DIALOG_END_MEETING_FOR_ALL = iText(
  'End Meeting for All',
  'apac.wc_btn_endconf_meeting_all',
);
export const DIALOG_END_WEBINAR_FOR_ALL = iText(
  'End Webinar for All',
  'apac.wc_btn_end_webinar_all',
);

export const DIALOG_FOR_CANCEL_PIN = iText(
  'Remove all pinned videos',
  'apac.wc_remove_all_pin_title',
);
export const toCancelAllPin = (userName) =>
  iText(
    `Spotlighting ${userName} for everyone will remove all pinned videos, Do you want to continue?`,
    'apac.wc_wc_remove_all_pin_desc',
    userName,
  );

export const DIALOG_FOR_CANCEL_SPOTLIGHT = iText(
  'Remove all spotlighted videos',
  'apac.wc_remove_all_spotlight_title',
);
export const toCancelAllSpotlight = (userName) =>
  iText(
    `Pinning ${userName} for everyone will remove all spotlighted videos, Do you want to continue?`,
    'apac.wc_wc_remove_all_spotlight_desc',
    userName,
  );

export const UNMUTE_NOT_YET_JOIN_2 = iText(
  'Clicking "Unmute" will connect computer audio and unmute you.',
  'apac.wc_unmute_dialog_tip_2',
);
export const UNMUTE_BY_HOST_STAY_MUTED = iText(
  'Stay Muted',
  'apac.wc_unmute_by_host_stay_muted',
);
export function unmuteDialogByPhone(participantId) {
  return iText(
    `Already joined by phone? Enter #${participantId}# on your phone, then click Unmute.`,
    'apac.wc_unmute_dialog_tip2',
    [participantId],
  );
}
export const UNMUTE_BY_HOST_NOT_SUPPORT_UNMUTE = iText(
  'Your browser does not support computer audio, please join audio using other browsers such as Chrome, Firefox and Chromium Edge. ',
  'apac.wc_unmute_by_host_not_support_unmute',
);

export function removedSuccessTipText(userName) {
  return iText(`"${userName}" has been removed`, 'apac.wc_removed_tip', [
    userName,
  ]);
}
export const CANNOT_MOVE_VIDEO = iText(
  'Cannot Move Video',
  'apac.video_cannot_move_video',
);

export const CANNOT_MOVE_VIDEO_HIDE_NON_VIDEO = iText(
  'You cannot move a video while non-video participants are hidden',
  'apac.video_cannot_move_video_hide_non_video',
);

export const CANNOT_MOVE_VIDEO_HIDE_SELF_VIDEO = iText(
  'You cannot move a video while your video is hidden',
  'apac.video_cannot_move_video_hide_self_video',
);

export const CANNOT_MOVE_VIDEO_FOLLOW_HOST_ORDER = iText(
  "You cannot move a video because you are following the host's video order",
  'apac.video_cannot_move_video_follow_host_order',
);

export const CANNOT_MOVE_VIDEO_HAS_PIN = iText(
  'You cannot move a video while people are pinned',
  'apac.video_cannot_move_video_has_pin',
);

export const CANNOT_MOVE_VIDEO_HAS_PIN_NEW = iText(
  'You cannot move a video while people are pinned',
  'apac.video_cannot_move_video_has_pin_new',
);

export const CANNOT_MOVE_VIDEO_HAS_RAISE_HAND = iText(
  'You cannot move a video while someone has their hand raised',
  'apac.video_cannot_move_video_has_raise_hand',
);

export const CANNOT_MOVE_VIDEO_HAS_SPOTLIGHT = iText(
  'You cannot move a video while people are spotlighted',
  'apac.video_cannot_move_video_has_spotlight',
);

export const CLAIM_HOST = iText('Claim Host', 'apac.dialog.claim_host');

export const CLAIM_HOST_TITLE = iText(
  'Enter the Host Key to claim host role',
  'apac.dialog.claim_host_title',
);

export const CLAIM_HOST_TITLE_NEW = iText(
  'Enter host key to claim host',
  'apac.dialog.claim_host_title_new',
);

export const CLAIM_HOST_ERROR_TIPS = iText(
  'The Host Key you entered is invalid',
  'apac.dialog.claim_host_error_tips',
);

export const CLAIM_HOST_ERROR_TIPS_NEW = iText(
  'The host key you entered is not valid',
  'apac.dialog.claim_host_error_tips_new',
);

export const CLAIM_HOST_HELPFUL_TIPS = iText(
  'Host key is a 6-10 digit number',
  'apac.dialog.claim_host_helpful_tips',
);

export const CLAIM_HOST_HELPFUL_TIPS_NEW = iText(
  'Enter 6-digit host key',
  'apac.dialog.claim_host_helpful_tips_new',
);

export const CLAIM_HOST_HELPFUL_TIPS_NEW2 = iText(
  'Enter 6-10 digit host key',
  'apac.dialog.claim_host_helpful_tips_new2',
);

export const CLAIM_HOST_VERIFY = iText(
  'Verifying Host Key...',
  'apac.dialog.claim_host_verify',
);

export const NETWORK_ERROR = iText(
  'Network error,please try again.',
  'apac.dialog.network_error',
);
export const STOP_RECORDING_TOAST = iText(
  'You will get an email notification when the cloud recording is ready',
  'apac.toast_after_stop_recording',
);

export const UPGRAGE_TEXT = iText('Upgrade', 'apac.dialog.upgrade_btn');
export const UPGRAGE_NOW_TEXT = iText(
  'Upgrade Now',
  'apac.dialog.upgrade_now_btn',
);
export const GIFT_TITLE_1 = iText(
  'A gift from Zoom',
  'apac.dialog.gift_title1',
);
export const GIFT_CONTENT_1 = iText(
  'Running out of time? We’ve removed the 40-minute time limit on your group meeting.',
  'apac.dialog.gift_content1',
);
export const GIFT_OK_BTN_1 = iText('Love it!', 'apac.dialog.gift_okbtn1');

export const GIFT_TITLE_2 = iText(
  'We are in a giving mood',
  'apac.dialog.gift_title2',
);
export const GIFT_CONTENT_2 = iText(
  'If you schedule now, we’ll remove the 40-minute time limit on your next group meeting, too.',
  'apac.dialog.gift_content2',
);
export const GIFT_OK_BTN_2 = iText('Schedule Now', 'apac.dialog.gift_okbtn2');

export const GIFT_TITLE_3 = iText('Join Zoom Pro', 'apac.dialog.gift_title3');
export const GIFT_CONTENT_3 = iText(
  'Remove the 40-min time limit on all future meetings',
  'apac.dialog.gift_content3',
);

export const GIFT_TITLE_4 = iText(
  'Your free meeting will end in 10 minutes',
  'apac.dialog.gift_title4_new',
);
export const GIFT_CONTENT_4 = iText(
  'Need more time? Zoom Pro provides unlimited meetings and more.',
  'apac.dialog.gift_content4_new',
);

export const GIFT_TITLE_5 = iText(
  'Your free meeting has ended',
  'apac.dialog.gift_title5_new',
);
export const GIFT_CONTENT_5 = iText(
  'Get unlimited meetings and more with Zoom Pro.',
  'apac.dialog.gift_content5_new',
);

export const GIFT_TITLE_6 = iText(
  'This free Zoom meeting has ended',
  'apac.dialog.gift_title6',
);
export const GIFT_CONTENT_6 = iText(
  'Thank you for choosing Zoom!',
  'apac.dialog.gift_content6',
);

export const GIFT_TITLE_7 = iText(
  'Your meeting will end in 10 minutes',
  'apac.dialog.gift_title7',
);

export const GIFT_CONTENT_7 = iText(
  'Need more time? Contact your administrator to upgrade to a plan with unlimited meetings.',
  'apac.dialog.gift_content7_new',
);

export const GIFT_MEETING_TIP_FOR_OTHERS = iText(
  'This meeting has been upgraded',
  'apac.dialog.gift_tip_for_others',
);
export const GIFT_MEETING_TIP_CONTENT_FOR_OTHERS = iText(
  'This meeting has been upgraded by the host and now includes unlimited minutes.',
  'apac.dialog.gift_tip_content_for_others',
);
export const GIFT_MEETING_TIP_CONTENT_FOR_OTHERS2 = iText(
  'This meeting now includes unlimited minutes.',
  'apac.dialog.gift_tip_content_for_others2',
);

export const DLP_SEND_MESSAGE = iText('Send message?', 'apac.dlp.title1');

export const DLP_WARNING = iText(
  'Your message contains some words or phrases that are defined as unwanted by a Chat Policy.',
  'apac.dlp.warning_2',
);

export const DLP_NO_SEND_MESSAGE = iText(
  'Unable to send message',
  'apac.dlp.title2',
);

export const DLP_BLOCK = iText(
  'Your message contains some words or phrases that are prohibited by a Chat Policy and cannot be sent. Contact your account admin for more information.',
  'apac.dlp.block_2',
);

export const DLP_CHAT_WINDOW_TIP = iText(
  'Unwanted words are limited by a Chat Policy',
  'apac.dlp.chat_window_tip',
);

export const NEW_DLP_WINDOW_TIP = iText(
  'DLP has been enabled',
  'apac.dlp.new_dlp_tip',
);

export const NEW_DLP_TOOLTIP = iText(
  'DLP has been enabled, so any messages (public & private) that trigger policies defined by your account will be deleted. For more information, please contact your account admin',
  'apac.dlp.new_tooltip',
);

export const CHAT_DELETED_DLP_TITLE = iText(
  'This message has been deleted',
  'apac.dlp.chat_deleted_dlp_title',
);

export const CHAT_DELETED_DLP_CONTENT = iText(
  'Your message has been deleted because it triggers one or more policies defined by your account. Please contact your admin for more information',
  'apac.dlp.chat_deleted_dlp_content',
);

export const CHAT_DISABLED_DLP_TITLE = iText(
  'Chat disabled',
  'apac.dlp.chat_disabled_dlp_title',
);

export const PROMOTE_CONFIRM_TITLE = iText(
  'The host would like to promote you to be a panelist',
  'apac.promote.title',
);

export const PROMOTE_CONFIRM_CONTENT_1 = iText(
  'As a panelist, you can unmute and start video which means you will be visible to others in the webinar.',
  'apac.promote.content_1',
);
// hotfix 20230811 promote
export const PROMOTE_CONFIRM_CONTENT_2 = iText(
  'If the host or panelists decide to record, livestream, or archive the webinar after you unmute, your participation will be included. If the webinar is recorded it may be shared with Smart Recording which uses AI technology, which may include third-party models. Zoom does not use any audio, video, chat, screen sharing, attachments or other communications-like content (such as poll results, whiteboard and reactions) to train Zoom’s or third-party AI models.',
  'apac.promote.content_3_2',
);

export const GETTY_DIALOG_WORDING_WEBINAR_1 = iText(
  'Participants with relevant permissions also may save and share the webinar transcript.',
  'apac.getty.content_webinar_1',
);

export const GETTY_DIALOG_WORDING_MEETING_1 = iText(
  'Participants with relevant permissions also may save and share the meeting transcript.',
  'apac.getty.content_meeting_2',
);
export const PROMOTE_CONFIRM_CANCEL = iText(
  'Stay as Attendee',
  'apac.promote.cancel',
);

export const PROMOTE_CONFIRM_OK = iText('Join as Panelist', 'apac.promote.ok');
export const NDI_TITLE = iText(
  'This meeting is being livestreamed on NDI\u00ae',
  'apac.ndi.title',
);
export const NDI_CONTENT_1 = iText(
  "Video and audio feeds from this meeting are being livestreamed on a network and may be recorded, modified, or otherwise used in accordance with the host organization's #1*.",
  'apac.ndi.content_1',
  '#1*',
);
export const NDI_CONTENT_2 = iText(
  'By staying in this meeting, you consent to being livestreamed.',
  'apac.ndi.content_2',
);
export const PRIVACY_POLICY = iText(
  'Privacy Policy',
  'apac.ndi.privacy_policy',
);
export const exist = iText(
  'This meeting is being controlled by an admin.',
  'apac.rmc.exist_notification_for_client_host',
);
export const notExist = iText(
  'Admin has stopped controlling the meeting.',
  'apac.rmc.not_exist_notification_for_client_host',
);

export const CANNOT_START_VIDEO_TIPS1 = iText(
  'Your video cannot be started',
  'apac.settings.cannot_start_video_tips1',
);

export const CANNOT_START_VIDEO_TIPS2 = iText(
  'Your admin has required that you use a background to start video, but there are no available backgrounds for you to use yet.',
  'apac.settings.cannot_start_video_tips2',
);

export const CANNOT_START_VIDEO_TIPS3 = iText(
  'Please try again later.',
  'apac.settings.cannot_start_video_tips3',
);

export const ENABLE_VB_TIP1 = iText(
  'Enable Virtual Background to start video',
  'apac.settings.enable_vb_tip1',
);

export const ENABLE_VB_TIP2 = iText(
  'Your admin has required that you use a background, but you have disabled "Virtual Background". To enable this setting, go to Meeting Settings.',
  'apac.settings.enable_vb_tip2',
);

export const ENABLE_VB_TIP3 = iText(
  'To enable this setting, go to Meeting Settings.',
  'apac.settings.enable_vb_tip3',
);

export const ENABLE_VB_TIP4 = iText(
  'Go to Meeting Settings',
  'apac.settings.enable_vb_tip4',
);

export const CANNOT_SHARE_WHEN_SHARING_TO_BO = iText(
  'Unable to share your screen while someone is sharing from the main session',
  'apac.dialog.cannot_share_when_sharing_to_bo',
);

export const UNABLE_TO_CREATE_POLL_TITLE = iText(
  'Unable to create poll',
  'apac.dialog.unable_to_create_poll_title',
);
export const UNABLE_TO_CREATE_POLL_OR_QUIZ_TITLE = iText(
  'Unable to create poll or quiz',
  'apac.dialog.unable_to_create_poll_or_quiz_title',
);
export const UNABLE_TO_CREATE_POLL_CONTENT = iText(
  'You have reached the maximum number of polls that can be created for this meeting.',
  'apac.dialog.unable_to_create_poll_content',
);
export const UNABLE_TO_CREATE_POLL_OR_QUIZ_CONTENT = iText(
  'You have reached the maximum number of polls/quizzes that can be created for this meeting.',
  'apac.dialog.unable_to_create_poll_or_quiz_content',
);

// share maximum
export const REACHED_SHARE_MAXIMUM_TITLE = iText(
  'You have reached the maximum allowed screen share sessions',
  'apac.dialog.reached_share_maximum_title',
);
export const REACHED_SHARE_MAXIMUM_CONTENT = iText(
  'Ask someone to stop sharing and try again.',
  'apac.dialog.reached_share_maximum_content',
);
export const SHAREING_FAILED_DUO_TO_MAXIMUM_TITLE = iText(
  'The maximum number of sessions have been reached',
  'apac.dialog.sharing_failed_duo_to_maximum_title',
);
export const SHAREING_FAILED_DUO_TO_MAXIMUM_CONTENT = iText(
  'Sharing wasn’t successful because the maximum number of sessions are already active. Ask someone to stop sharing and try again.',
  'apac.dialog.sharing_failed_duo_to_maximum_content',
);

// practice session
export const PRACTICE_SESSION_WEBINAR_START_TITLE = iText(
  "Let's get this started",
  'apac.dialog.practice_session_webinar_start_title',
);
export const PRACTICE_SESSION_WEBINAR_START_CONTENT = iText(
  'Once you click start, attendees can join this webinar.',
  'apac.dialog.practice_session_webinar_start_content',
);

export const UNABLE_START_CLOUD_RECORDING_TITLE = iText(
  'Unable to Start Cloud Recording',
  'apac.dialog.unable_start_cloud_recording_title',
);
export const unableStartCloudRecordingContent = (placeholder) =>
  iText(
    `Your account has used up all of the available cloud storage space. You can go to ${placeholder} web page to view details.`,
    'apac.dialog.unable_start_cloud_recording_content',
    placeholder,
  );
export const UNABLE_START_CLOUD_RECORDING_CONTENT1 = iText(
  'Your account has used up all of the available cloud storage space. Please contact your account admin for more information.',
  'apac.dialog.unable_start_cloud_recording_content1',
);
export const UNABLE_START_CLOUD_RECORDING_CONTENT2 = iText(
  'Contact the meeting host or their account admin for more information.',
  'apac.dialog.unable_start_cloud_recording_content2',
);
export const RECORDING_MANAGEMENT_LINK = iText(
  'Recording Management',
  'apac.dialog.recording_management_link',
);

export const CLOUD_RECORDING_STORAGE_FULL_TITLE = iText(
  'Cloud recording storage is full',
  'apac.dialog.cloud_recording_storage_full_title',
);
export const cloudRecordingGracePeriodContent = (placeholder) =>
  iText(
    `You have been granted a one-time temporary extension until ${placeholder}.`,
    'apac.dialog.cloud_recording_grace_period_content',
    placeholder,
  );
export const cloudRecordingGoRecordingManagement = (placeholder) =>
  iText(
    `You can go to ${placeholder} web page to view details.`,
    'apac.dialog.cloud_recording_go_recording_management',
    placeholder,
  );
export const CLOUD_RECORDING_STORAGE_FULL_CONTENT1 = iText(
  'Please contact your account admin for assistance.',
  'apac.dialog.cloud_recording_storage_full_content1',
);
export const CLOUD_RECORDING_STORAGE_FULL_CONTENT2 = iText(
  'Please contact the meeting host or their account admin for more information.',
  'apac.dialog.cloud_recording_storage_full_content2',
);
export function autoReplyModalTitle(topic) {
  return iText(
    `Welcome to ${topic} simulive`,
    'apac.wc_simulive_welcome_title',
    [topic],
  );
}

export const REPORT_PROBLEM = iText(
  'Report Problem',
  'apac.dialog.report_problem',
);
export const SEND = iText('Send', 'apac.dialog.send');
export const CANCEL = iText('Cancel', 'apac.dialog.cancel');
export const PRODUCT = iText('Product', 'apac.dialog.product');
export const PROBLEM = iText('Problem', 'apac.dialog.problem');
export const SELECT_PROBLEM = iText(
  'Select a problem',
  'apac.dialog.select_problem',
);
export const TIME_OF_OCCURRENCE = iText(
  'Time of Occurrence',
  'apac.dialog.time_of_occurrence',
);
export const DESCRIPTION = iText('Description', 'apac.dialog.description');
export const BREAFLY_DESCRIPT = iText(
  'Briefly describe what happened',
  'apac.dialog.breafly_descript',
);
export const INCLUDE_LOG_FILE = iText(
  'Include log file',
  'apac.dialog.include_log_file',
);
export const HAVE_A_TICKET = iText(
  'I have a ticket ID',
  'apac.dialog.have_a_ticket',
);
export const report_tip_content = (placeholder) =>
  iText(
    `Log and system data sent will be processed in the United States. By sending this report, you agree to Zoom’s access of the data necessary to respond to this problem, subject to Zoom’s ${placeholder}`,
    'apac.dialog.report_tip',
    placeholder,
  );
export const THANK_YOU_REPORT = iText(
  'Thank you for your report!',
  'apac.dialog.thank_you_report',
);
export const TRACKING_ID = iText('tracking ID:', 'apac.dialog.tracking_id');
export const COPY_TO_CLIPBOARD = iText(
  'Copied to Clipboard',
  'apac.dialog__copied',
);
export const reportProductOptions = [
  {
    label: iText('Meeting', 'apac.dialog.label_meeting'),
    value: 'Meeting',
  },
  {
    label: iText('Webinar', 'apac.dialog.label_webinar'),
    value: 'Webinar',
  },
];

export const reportProblemOptions = [
  {
    label: iText('Join Meeting', 'apac.dialog.label_join_meeting'),
    value: 'Join Meeting',
  },
  {
    label: iText('Audio Quality', 'apac.dialog.label_audio_quality'),
    value: 'Audio Quality',
  },
  {
    label: iText('Video Quality', 'apac.dialog.label_video_quality'),
    value: 'Video Quality',
  },
  {
    label: iText('Screen Sharing', 'apac.dialog.label_screen_sharing'),
    value: 'Screen Sharing',
  },
  {
    label: iText('Remote Control', 'apac.wc_remote_control.help_title'),
    value: 'Remote Control',
  },
  {
    label: iText('Recording', 'apac.dialog.label_recording'),
    value: 'Recording',
  },
  {
    label: iText('Dial-In', 'apac.dialog.label_dial_in'),
    value: 'Dial-In',
  },
  {
    label: iText('Others', 'apac.dialog.label_others'),
    value: 'Others',
  },
];
export const SPECIAL_OFFER_TEXT = iText(
  'SPECIAL OFFER',
  'apac.dialog.special_offer',
);
export const requestControlCamera = (requesterName) =>
  iText(
    `${requesterName} would like to control your camera`,
    'apac.dialog.request_control_camera',
    [requesterName],
  );
export const takeOverControlCamera = (requesterName, controllerName) =>
  iText(
    `${requesterName} would like to take over control of your camera from ${controllerName}`,
    'apac.dialog.take_over_control_camera',
    [requesterName, controllerName],
  );
export const STOP_CAMERA_CONTROL = iText(
  'Stop camera control?',
  'apac.dialog.stop_camera_control',
);
export const addControlCameraGroup = (requesterName) =>
  iText(
    `Add ${requesterName} to your Camera Control Group`,
    'apac.dialog.add_control_camera_group',
    [requesterName],
  );
export const ADD_CAMERA_CONTROL_GROUP_TEXT = iText(
  'If you click "Add", they will be able to control your device\'s camera for this meeting and all future meetings.',
  'apac.dialog.add_control_camera_group_text',
);
export const startControlCameraGroup = (requesterName) =>
  iText(
    `${requesterName} has given you camera control privileges`,
    'apac.dialog.start_control_camera_group',
    [requesterName],
  );

export const START_CAMERA_CONTROL_GROUP_TEXT = (requesterName) =>
  iText(
    `If you click "Start Camera Control", you can control ${requesterName}'s camera device by zooming in and out or dragging the camera view.`,
    'apac.dialog.start_control_camera_group_text',
    [requesterName],
  );
export const NOT_NOW_CAMERA_CONTROL_GROUP_TEXT = iText(
  'If you click "Not now", camera control will not be activated. You can also access camera control later in their dropdown menu by selecting "Start Camera Control".',
  'apac.dialog.not_now_control_camera_group_text',
);
export const CAMERA_CONTROL_NOT_NOW = iText(
  'Not now',
  'apac.dialog.control_camera_not_now',
);
export const CAMERA_CONTROL_START = iText(
  'Start Camera Control',
  'apac.dialog.control_camera_start',
);
export const RESTRICTED_EXTERNAL_PARTICIPANTS = iText(
  'Remote control with external participants restricted in this meeting',
  'apac.dialog.restricted_external_participants',
);
