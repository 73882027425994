import meetingConfig from 'meetingConfig';
import * as socketEventTypes from '../constants/ZoomSocketEventTypes';
import * as meetingActions from './MeetingActions';
import * as meetingUIActions from './MeetingUIActions';
import * as socketActionTypes from '../constants/SocketActionTypes';
import * as types from '../constants/MeetingActionTypes';
import {
  getUserById,
  isJoinAudio,
  isJoinByPhone,
  isJoinVoIP,
  setAvStatusInSessionStorage,
  updateOptInStorage,
  isShouldDisableMessageOnTeslaMode,
  isNewJoinFlowEnabled,
  isNewChatUIReady,
  isAddNewUser,
  isUserFromWaitingRoom,
  isRemoveUser,
  hideJoinLoading,
  isSupportWaitingRoomWithoutFailoverFlow,
} from '../global/service';
import { globalVariable } from '../global/global-variable';
import * as AVNotifyMediaSDKTypes from '../constants/AVNotifyMediaSDKTypes';
import {
  decodeBase64,
  decodeBase64ToBuffer,
  sliceStringByCodeLength,
  iText,
  getStringLength,
  replaceStringByCodeLength,
  isExternalControlledMode,
  removeByKey,
  isTeslaMode,
  isSupportMultiView,
  isEnableAutoAcceptFECCInPwa,
} from '../global/util';
import * as meetingConstants from '../constants/Constants';
import {
  EVT_TYPE_WS_SHARING_SSRC_INDICATION,
  SHARER_STATUS_ENUM,
  WS_SHARING_DATA_RECEIVE_INDICATION,
} from '../features/sharing/enum';
import {
  setSharerSsrc,
  setSharingContentOriginalDimension,
  setHasNotSameAccountUser,
  setHasLimitSharingConfirmed,
} from '../features/sharing/redux/sharing-action';
import {
  updateIsVideoShare,
  tryLaunchOrCloseLocalSharer,
  prepareForRemoteControl,
  grabRemoteControl,
  prepareForReceiveSharing,
  toggleSharerIndicationThunk,
  resetIsSharingToBO,
  receiveSharingChannelReady,
  receiveSharingChannelClose,
  changeLockShare,
  handleRcAppClose,
  handleRecvUrlScheme,
  handleRcAppConnected,
  handleQrCodeResponse,
} from '../features/sharing/redux/sharing-thunk-action';
import {
  setSimuliveAutoreplyEnable,
  setSpotlightVideoList,
  setHasChangedVideoOrder,
  setIsViewOrderSaved,
  setIsUploadingViewOrder,
  setNameTagTemplates,
  setSimuliveLoading,
  setVideoActionInProgress,
} from '../features/video/redux/video-action';
import { stopCaptureVideo } from '../features/video/redux/thunks/start-stop-capture-video-thunk';
import {
  receivetActiveVideoIdThunk,
  receivetActiveVideoTalkingIdThunk,
  handlePendingActiveFrameVideoThunk,
} from '../features/video/redux/thunks/receive-active-ssrc-thunk';
import {
  receiveSpotlight,
  updateSpotlightList,
  clearSpotlightVideoListThunk,
} from '../features/video/redux/thunks/update-spotlight-thunk';
import {
  updatePinList,
  clearPinVideoListThunk,
} from '../features/video/redux/thunks/update-pin-thunk';
import {
  receiveFollowHostViewThunk,
  updateFollowHostViewThunk,
  receiveLayoutThunk,
  receiveLayoutFlagThunk,
} from '../features/video/redux/thunks/follow-host-view-thunk';

import { renderWatermark } from '../features/video/redux/thunks/water-mark-thunk';
import {
  onLoadCustomVideoOrderThunk,
  setGalleryOrderTypeThunk,
  newHostGallerySortTypeModifyThunk,
} from '../features/video/redux/thunks/gallery-custom-order-thunk';

import { rwgOutboundMsg, infoLog } from '../global/web-client-logger';
import { currentCCSelector } from '../global/redux/selector';
import { checkOnAttendees } from '../global/aria-helper';
import {
  SOCKET_NAME_ENUM,
  EventQueueProvider,
  setIsHostAtLocal,
  storeLeaveParticipant,
  updateByKey,
  SESSIONSTORAGE_KEYS,
  cacheMainSessionEncryptKey,
  isCMRRecordingSuccess,
  isCMRRecordingInit,
  JOIN_MEETING_POLICY,
  FAILOVER_REASON,
  meetingChatListSelector,
  CONF_SESSION_STATUS,
} from '../global';
import {
  handleUpdateBoAttributeIndication,
  handleBoCommandIndication,
  handleAddRoomIndication,
  handleHostUpdateIndication,
  handleBoJoinRes,
  tryCheckPutOnHoldAttendeeInBoRoom,
  handleBoPreAssignRes,
  handleSaveBoRes,
  handleBoActivityStatusFromRosterIndication,
} from '../features/breakout-room/redux/bo-room-thunk-aciton';
import {
  setAttendeeRoomStatus,
  setIsHostBeforeJoinBo,
} from '../features/breakout-room/redux/bo-room-action';
import {
  updateaddNewUserUniq,
  updateRemoveUserUniq,
  addFailoverUsersInWR,
  addFailoverUsersInMeeting,
  updateWaitingRoomUserUniq,
  removeFailoverUsersInWR,
  removeFailoverUsersInMeeting,
  updateRainseHandUniq,
  resetVideoOrder,
  saveWebSDKUser,
  setDragLayoutThunk,
} from '../reducers/AttendeesListReducer';
import {
  isJoiningRoom,
  isBoMainWebsocket,
  isInRoom,
} from '../features/breakout-room/utils';
import { isMessageSentByUser } from '../features/chat/utils';
import { ATTENDEE_STATUS } from '../features/breakout-room/constant';
import {
  tryUpdateChatReceiverName,
  onReceiveChatMessage,
} from '../features/chat/redux/chat-thunk-action';
import {
  CHAT_CMD_DELETE,
  CHAT_DELETED_OR_MODIFIED_BY_DLP,
  CHAT_DISABLED_BY_DLP,
  CHAT_DISABLED_BY_NONE,
  DLPActionType,
} from '../features/chat/constants';
import {
  setJoinVoipLoading,
  setAudioSsrcIndication,
  setWebinarAttendeePhoneIndication,
  setDialOutReturnCode,
  setDialOutPhoneNumber,
  setDialOutSelectedCountry,
  setSelfMuteOrUnmute,
  setDialOutSequence,
  setHostMutedAll,
} from '../features/audio/redux/audio-action';
import {
  sendChangeAudioStatusRequest,
  setJoinAudioDialogVisible,
  webinarAttendeeJoinVoip,
  leaveVoipAudio,
  initAudioEncodeWhenUserRoleChange,
  getAsn,
  umnuteAfterSpotlighted,
  handleDialOutResponse,
  handleDialOutCancelResponse,
  unmutedByHost,
  mutedOrUnMutedByHost,
  setDialOutReturnCodeThunk,
  audioLogger,
} from '../features/audio/redux/audio-thunk-action';

import { handleCoHostUpdateIndication } from '../features/breakout-room/redux/bo-room-thunk-service';
import { wcToast } from '../global/components/widget/toast/wc-toast';
import { setRequestEnableWaitingRoom } from '../features/security/redux/security-action';
import {
  beginDecrypt,
  encryptType,
  ivType,
  sdkIvTypeKeyEnum,
} from '../global/crypto';
import {
  setAppSignalInfo,
  setMeetingTopicThunk,
  setNDIInfo,
  setSharePronounsType,
} from './MeetingActions';
import {
  setLiveStreamStatus,
  setLiveStreamBroadcastInfo,
  setLiveStreamTip,
} from '../features/live-stream/redux/live-stream-action';
import { storeType, easyStore } from '../global/easy-store';
import { externalController, CONTROL_MODE_ZOOM_MSG_TYPE } from '../controller';
import { mergeAddRoomIndications } from '../features/breakout-room/cache';
import { handleSuspendRes } from '../features/security/redux/security-thunk-action';
import {
  setTurnOnOrOffLiveTranscriptionResponse,
  deleteMessage,
} from '../features/live-transcription/redux/live-transcription-action';
import {
  updateLiveTranscriptionMessage,
  onReceiveLiveTranscriptionOn,
  onHostReceiveLtRequests,
  onChangeRequestLtPermission,
  onHostChangeResetRequestLtCap,
  tryDismissRequestLtDialog,
} from '../features/live-transcription/redux/live-transcription-thunk-action';
import { CC_TYPE } from '../features/live-transcription/constant';
import {
  setIsRwgEnablePolling,
  setHasPollingInMeeting,
  setQuizShareOption,
} from '../features/poll/redux/poll-action';
import { pollingErrorPopUp } from '../features/poll/redux/poll-thunk-action';
import { checkReclaimHostStatus } from './MeetingUIActions';
import { deleteChat, setSelfChatId } from '../features/chat/redux/chat-action';
import {
  handleArchivingChange,
  showDLPPopupThunk,
  showPromoteConfirmThunk,
  showApplyPanelistAppearanceFailedDialog,
  setClaimHostDialog,
  muteVideo,
  showStartRecordingErrorDialog,
  showStartRecordingGracePeriodDialog,
  showWhiteboardErrorDialog,
} from '../features/dialog/redux/dialog-thunk-action';
import {
  handleInterpretationStart,
  handleSignLanguageInterpretationStart,
  handleSignLanguageInterpretersChange,
  handleSignLanguageAllowInterpreterToTalk,
  handleCurrentInterpretationLanguageChange,
  handleInterpretationLanguageListChange,
  handleInterpretersChange,
  handleChangeSignLanguageInterpreterHostOrCoHostPrivilege,
} from '../features/interpretation/redux/interpretation-thunk-action';
import {
  onReceiveReactionResult,
  onReceiveWebinarReaction,
  allowWebinarReaction,
} from '../features/reaction/redux/reaction-thunk-action';
import { LEAVEREASON_USERREQUEST } from '../constants/LeaveReasons';
import { CHAT_RESPONSE } from '../constants/Constants';
import { SESSION_TYPE } from '../global/constant';
import {
  setShareToBoConfirmDialogVisible,
  setChatDeletedDlpDialogVisible,
  setShareMaximumDialogVisible,
  setRemoteControlBlockDialogVisible,
} from '../features/dialog/redux/dialog-action';
import {
  onRecvAttentionModeList,
  onSetShareAttentionModeList,
  onAttentionModeChangeThunk,
  showAttentionModeGotToastForSpotlight,
  onShareAttentionModeChangeThunk,
  setIsMmrSupportFocusMode,
  handleFocusModal,
  setIsFocusModeEndingThunk,
} from '../features/attention-mode/redux/attention-mode-thunk';
import { updateUserRosterInfoToSDK } from '../features/sharing/service';
import { EventTimeoutTracker } from '../global/timeout-tracker/event-timeout-tracker';
import {
  onRecvNotJoinedList,
  promptParticipantA11yInfo,
} from '../features/participants/redux/participants-thunk-action';
import { CallMeStatus, AudioConnectionStatus } from '../features/audio/enum';
import {
  isUserSignLanguageInterpreter,
  getUserActiveSignLanguageInterpretationLanguageID,
} from '../features/interpretation/util';
import { NONE_TEMPLATE } from '../features/video/container/session-branding-window/NONE_TEMPLATE_ID';
import {
  PWAMeetingEvent,
  sendMsgToPWA,
  sendIakToPWA,
} from '../global/pwa-integration';
import {
  setInterpretationWindowVisible,
  signLanguageInterpretationLangSelected,
} from '../features/interpretation/redux/interpretation-action';
import { isUIConnectingCMR } from '../features/recording/service';
import { setIsZoomIQ } from '../features/recording/redux/recording-action';
import _ from 'lodash';
import { setZoomAppToken } from '../features/zoom-app/redux/zoom-app-action';

import { updateExpiredReactions } from '../features/reaction/redux/reaction-action';
import {
  handleLiveStreamTokenResponse,
  handleLlsInfoUpdate,
  handleRequestPermissionInfo,
} from '../features/live-stream/redux/live-stream-thunk-action';
import {
  QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_SUCCESS,
  QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_FAILED,
} from '../features/q-a/resource';
import AliveToast from '../global/containers/notification-manager/alive-toast';
import { isAudioBridge } from '../global/op-feature-option';
import {
  updateQuestionThunk,
  updateAnswerThunk,
  updateCmdThunk,
  getQaEnabledThunk,
} from '../features/q-a-meeting/redux/q-a-thunk-action';
import {
  setBAllowAnonymousQuestion,
  setBAllowAttendeeViewAllQuestion,
  setBAllowAttendeeUpvoteQuestion,
  setBAllowAttendeeCommentQuestion,
  setEnableQuestion,
} from './../features/q-a/redux/q-a-action';
import { makeLogger } from '../global/logger/index';
import {
  setIsShowWhiteboardWindow,
  setIsWhiteboardShareStreamOn,
  setWhiteboardConfig,
  setWhiteboardSharer,
  setWhiteboardStatus,
} from '../features/whiteboard/redux/whiteboard-action';
import {
  handleWithdrawCoOrHostPermission,
  handleWhiteboardPermissionCode,
  handlePreviousWhiteboard,
  closeWhiteboard,
  updateWBDashboardBasicInfoForHostJoin,
  handleCoOrHostChangeForWhiteboard,
} from '../features/whiteboard/redux/whiteboard-thunk-action';
import {
  updateSmsId,
  updateSmsErrorCode,
  updateSmsSendStatus,
} from '../features/invite/redux/invite-action';
import { SEND_SMS_STATUS } from '../features/invite/invite-constant';
import { clearNewChat } from '../features/new-chat/redux/new-chat-action';
import { happyRoster } from '../global/service/roster/happy-roster';
import { updateOtherNetworkQuality } from '../features/video/redux/thunks/network-quality-thunk';
import { WHITEBOARD_STATUS } from '../features/whiteboard/enum';
import { loadWhiteboard } from '../tasks/model';
import { shouldHideUILoadingInRoster } from '../global/service/hide-ui-loading';
import { HOST_STOP_VIDEO } from '../features/video/resource';
import { updateWBDashboardBasicInfo } from '../features/whiteboard/service';
import { startRecordingErrorRef } from '../features/dialog/service/command-dialog';
// import { LIVE_STREAM_STATUS } from '../features/live-stream/constant';
import {
  doRWGAction,
  makeKey,
} from '../features/zoom-feature-init/socketHandlerCollector';
import { setAVStatus } from '../global/service/avStatus';
import { reportConnectionInfo } from '../global/logger/log-service/connection-info-report';
import { handleSimuLiveVideoMessage } from '../features/video/redux/thunks/simu-live-video-thunk';
import { isTestMeeting } from '../features/test-meeting/service';
import { showTestMeetingModalThunk } from '../features/test-meeting/redux/test-meeting-thunk';
import { isWebinar } from '../global/service/meeting-types';
import {
  isAllowOnlyCanTalk,
  isHost,
  isViewOnly,
} from '../global/service/user-types';
import { closeXMPP } from '../components/XMPPSocket';
import {
  handleFarEndCameraLeft,
  handleFarEndCameraControl,
  handleUserIsInCameraControlGroup,
  handleStartCameraControlGroup,
} from '../features/video/redux/thunks/camera-control-thunk';
import { CAMERA_CONTROL_GROUP_ACTION_TYPE } from '../features/video/enum';
import { showLiveTranscriptionToastThunk } from '../features/new-live-transcription/service/show-live-transcription-toast-thunk';
import { Job } from '@zoom/common-utils';
import {
  closeRWGSocket,
  sendRWGMessage,
} from '../tasks/global/websocket/webclient-websocket';
import {
  SocketType,
  WCSockets,
} from '../tasks/global/websocket/websocket-utils';
import googleMeetAdaptor from '../controller/vendors/google-meet/index';
import { CONTENT_SHARE_STATE } from '../controller/vendors/google-meet/enum';
import { defaultSocketStatus } from '../reducers/SocketStatusReducer';
import {
  handleMultiSpeakerActiveList,
  refreshActiveSpeakerList,
} from '../features/video/redux/thunks/multi-speaker-video-thunk';
import { JOB_ENUM } from '../job_enum';
import { directReport } from '../global/logger/log-service/direct-report';
import { logJoinFlowTelemetry } from '../global/logger/log-service/join-flow-telemetry';

const logger = makeLogger(['Command Socket Event']);

function avMuteOrUnmuteCurrentUser(message) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      socketStatus: { initAudioEncodeStatus },
    } = getState();
    const initAudioEncodeSuccess = initAudioEncodeStatus === 'success';
    if (message.body.update) {
      const currentUserId = getState().meeting.currentUser.userId;
      message.body.update.forEach((item) => {
        if (currentUserId !== item.id) return;
        if (typeof item.muted === 'undefined') return;

        if (isJoinVoIP(currentUser) && initAudioEncodeSuccess) {
          if (item.muted) {
            audioLogger.log('audio mute', ['AUDIO CAPTURE FLOW']);
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.STOP_CAPTURE_AUDIO,
              {
                ssrc: 0,
              },
            );
          } else {
            audioLogger.log('audio unmute', ['AUDIO CAPTURE FLOW']);
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.ADD_CAPTURE_AUDIO,
              {
                ssrc: 0,
                AudioSelectValue: 0,
              },
            );
          }
        }
      });
    }
  };
}

/**
 * event send to RWG
 * @param {Object} message - event object.
 * @param {string} message.evt - The EventType.
 * @param {Object} message.body - The body of event.
 */
export function sendSocketMessage(message) {
  return (dispatch) => {
    const type = message.evt;

    const seq = sendRWGMessage(message);
    EventTimeoutTracker.getInstance().listenCommandSocoket(message);
    rwgOutboundMsg(message, type);
    switch (type) {
      case socketEventTypes.WS_AUDIO_DIALOUT_REQ:
        if (message.body.bCallme) {
          dispatch(setDialOutSequence(seq));
        } else {
          dispatch({
            type: types.SHOW_INVITE_STATUS,
            invite: {
              phoneNumber: message.body.pn,
              showIndication: true,
              seq,
            },
          });

          const data = {
            seq,
            userName: message.body.dn2,
            result: 1,
          };
          dispatch({ type: types.ADD_INVITE_SEQ, data });
          setTimeout(() => {
            dispatch({ type: types.DELETE_INVITE_SEQ, data });
          }, 15000);
        }
        return dispatch({ type: types.WS_AUDIO_DIALOUT_REQ, message });
      case socketEventTypes.WS_AUDIO_CANCEL_DIALOUT_REQ:
        if (message.body.bCallme) {
          return dispatch(setDialOutSequence(seq));
        }
        return dispatch({ type: types.WS_AUDIO_CANCEL_DIALOUT_REQ, message });

      case socketEventTypes.WS_CONF_RECORD_REQ:
        return dispatch({ type: types.RECORD_MEETING_START, message });
      case socketEventTypes.WS_CONF_LOCK_REQ:
        return dispatch({ type: types.LOCK_MEETING_START, message });
      case socketEventTypes.WS_CONF_LEAVE_REQ:
      case socketEventTypes.WS_CONF_LEAVE_AND_PUT_ALL_INTO_WR_REQ:
      case socketEventTypes.WS_CONF_END_REQ: {
        /* eslint-disable no-use-before-define */
        dispatch(
          meetingActions.endMeetingJob({
            body: {
              reason: message.body.reason || LEAVEREASON_USERREQUEST,
              isLeave: type === socketEventTypes.WS_CONF_LEAVE_REQ,
              isHungUpRemotely: message.body.isHungUpRemotely,
              isFailover: message.body.isFailover,
            },
          }),
        );
        break;
      }
      case socketEventTypes.WS_CONF_INVITE_CRC_DEVICE_REQ:
        return dispatch({ type: types.WS_CONF_INVITE_CRC_DEVICE_REQ, message });
      case socketEventTypes.WS_CONF_CANCEL_INVITE_CRC_DEVICE_REQ:
        return dispatch({
          type: types.WS_CONF_CANCEL_INVITE_CRC_DEVICE_REQ,
          message,
        });
      case socketEventTypes.WS_CONF_RAISE_LOWER_HAND_REQ:
        return dispatch({ type: types.WS_CONF_RAISE_LOWER_HAND_REQ, message });
      case socketEventTypes.WS_AUDIO_VOIP_JOIN_CHANNEL_REQ:
        return dispatch({
          type: types.WS_AUDIO_VOIP_JOIN_CHANNEL_REQ,
          message,
        });
      case socketEventTypes.WS_VIDEO_SUBSCRIBE_REQ:
        // return dispatch({ type: types.WS_VIDEO_SUBSCRIBE_REQ, message });
        break;
      case socketEventTypes.WS_VIDEO_UNSUBSCRIBE_REQ:
        //   return dispatch({ type: types.WS_VIDEO_UNSUBSCRIBE_REQ, message });
        break;
      case socketEventTypes.WS_VIDEO_MUTE_VIDEO_REQ:
        return dispatch({ type: types.WS_VIDEO_MUTE_VIDEO_REQ, message });
      case socketEventTypes.WS_CONF_ALLOW_RAISE_HAND_REQ:
        return dispatch({ type: types.WS_CONF_ALLOW_RAISE_HAND_REQ, message });
      case socketEventTypes.WS_CONF_ALLOW_UNMUTE_AUDIO_REQ:
        return dispatch({
          type: types.WS_CONF_ALLOW_UNMUTE_AUDIO_REQ,
          message,
        });
      case socketEventTypes.WS_CONF_ALLOW_PARTICIPANT_RENAME_REQ:
        return dispatch({
          type: types.WS_CONF_ALLOW_PARTICIPANT_RENAME_REQ,
          message,
        });
      case socketEventTypes.WS_MEETING_RWG_CONNECT_TIME:
        return null;
      default:
        return null;
    }
    return null;
  };
}

function updatePlayChimeUniqFlag(message) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId },
      },
      attendeesList: {
        attendeesList,
        failoverUsersInWaitingRoom,
        failoverUsersInMeeting,
      },
    } = getState();
    if (!_.isEmpty(message.body.add)) {
      message.body.add.some((user) => {
        if (
          userId !== user.id &&
          isAddNewUser(user) &&
          !isAllowOnlyCanTalk(user.role)
        ) {
          // a new user added,not myself
          dispatch(updateaddNewUserUniq(`${_.uniqueId()};${user.zoomID}`));
          return true;
        } else if (
          userId !== user.id &&
          isUserFromWaitingRoom(user) &&
          !isAllowOnlyCanTalk(user.role)
        ) {
          const waitingRoomUser = failoverUsersInWaitingRoom.find(
            (zoomID) => zoomID === user.zoomID,
          );
          if (waitingRoomUser) {
            dispatch(removeFailoverUsersInWR(user.zoomID));
            dispatch(updateWaitingRoomUserUniq(_.uniqueId()));
          } else if (isWebinar()) {
            const targetUser = failoverUsersInMeeting.find(
              (zoomID) => zoomID === user.zoomID,
            );
            if (!targetUser) {
              // a new user promoted from attendee list
              dispatch(updateaddNewUserUniq(`${_.uniqueId()};${user.zoomID}`));
            } else {
              dispatch(removeFailoverUsersInMeeting(user.zoomID));
            }
          }
        }
      });
    }
    if (!_.isEmpty(message.body.update)) {
      message.body.update.some((user) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          user.hasOwnProperty('bRaiseHand') &&
          userId !== user.id &&
          user.bRaiseHand
        ) {
          dispatch(updateRainseHandUniq(_.uniqueId()));
          return true;
        }
      });
    }
    if (!_.isEmpty(message.body.remove)) {
      message.body.remove.some((user) => {
        const removedAttendee = attendeesList.find(
          (attendee) => attendee.userId === user.id,
        );
        // it will not play chime when a attendee was removed from waiting room
        if (removedAttendee) {
          if (isRemoveUser(user) && !removedAttendee.bHold) {
            dispatch(updateRemoveUserUniq(_.uniqueId()));
            dispatch(removeFailoverUsersInWR(removedAttendee.zoomID));
            return true;
          } else if (!isRemoveUser(user) && removedAttendee.bHold) {
            // add the user to failover user list in waitingroom
            dispatch(addFailoverUsersInWR(removedAttendee.zoomID));
          } else if (
            !isRemoveUser(user) &&
            !removedAttendee.bHold &&
            isWebinar()
          ) {
            // add the user to failover user list in meeting
            dispatch(addFailoverUsersInMeeting(removedAttendee.zoomID));
          }
        }
      });
    }
  };
}

export function checkNewUserIsInWaitingRoom(zoomID) {
  return (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
    } = getState();
    const targetUser = attendeesList.find((user) => user.zoomID === zoomID);
    return targetUser?.bHold;
  };
}

export function updateCurrentUser(message) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      socketStatus: { commandSocketUrl },
      breakoutRoom: {
        attendee: { status: breakoutRoomAttendeeStatus },
      },
      audio: {
        isLeavingAudioForSharingDesktopAudio,
        dialOut: { returnCode },
        UI: { isJoinAudioDialogVisible },
      },
      video: { videoActionInProgress },
      interpretation: { signLanguages },
    } = getState();
    const { userId: currentUserId, audio } = currentUser;
    let needUpdateCurrentUser = false;
    if (!_.isEmpty(message.body.add)) {
      let iHost = false;
      message.body.add.forEach((addItem) => {
        if (currentUserId === addItem.id) {
          needUpdateCurrentUser = true;
          iHost = isHost(addItem.role);
          if (isViewOnly(addItem.role) && !isAllowOnlyCanTalk(addItem.role)) {
            dispatch(
              setAvStatusInSessionStorage({
                audio: 'mute',
                video: 'mute',
              }),
            );
          }
          dispatch(meetingActions.setCurrentUserRole(addItem));
          dispatch(meetingActions.setHost(iHost));
          setIsHostAtLocal(iHost);
          dispatch(meetingActions.coHostChange(!!addItem.bCoHost));
          dispatch(initAudioEncodeWhenUserRoleChange(addItem.role));
          if (shouldHideUILoadingInRoster()) {
            hideJoinLoading();
          }
          if (isTestMeeting()) {
            dispatch(showTestMeetingModalThunk());
          }
          if (typeof addItem.bHold !== 'undefined') {
            // dispatch(setOnHold(addItem.bHold));
          }
          if (
            !isBoMainWebsocket(commandSocketUrl) &&
            !isJoiningRoom(breakoutRoomAttendeeStatus)
          ) {
            dispatch(meetingActions.setIsWaitingRoomLoading(false));
            if (isSupportWaitingRoomWithoutFailoverFlow()) {
              dispatch(meetingUIActions.setNewWaitingRoomFlowLoading(false));
            }
          }

          if (
            isJoiningRoom(breakoutRoomAttendeeStatus) &&
            isBoMainWebsocket(commandSocketUrl)
          ) {
            dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.IN_ROOM));
          }
          if (
            typeof addItem.bLogIn !== 'undefined' &&
            typeof addItem.userEmail !== 'undefined' &&
            isUserSignLanguageInterpreter(addItem, signLanguages)
          ) {
            dispatch(
              signLanguageInterpretationLangSelected(
                getUserActiveSignLanguageInterpretationLanguageID(
                  addItem,
                  signLanguages,
                ),
              ),
            );
          }
          if (typeof addItem.dn2 !== 'undefined') {
            easyStore.easySet(
              'sessionUserName',
              decodeBase64(addItem.dn2, true),
              storeType.sessionStorage,
            );
          }
        }
        dispatch(meetingActions.updatebCCEditor(addItem));
      });
    }

    if (message.body.update !== null) {
      const waitingRoomToUpdateUsers = [];

      message.body.update.forEach((item) => {
        if (currentUserId === item.id) {
          needUpdateCurrentUser = true;
          // isJoinAudio need both rwg subscribe and sdk join
          if (
            typeof item.audio !== 'undefined' &&
            (isJoinAudio(item) || isLeavingAudioForSharingDesktopAudio)
          ) {
            dispatch(setJoinVoipLoading(false));
            dispatch(setJoinAudioDialogVisible(false, item));
            if (isJoinByPhone(item) && isJoinVoIP(currentUser)) {
              dispatch(leaveVoipAudio());
            }
          }

          if (
            isJoinByPhone(item) &&
            !audio &&
            returnCode !== CallMeStatus.success
          ) {
            const returnCode = easyStore.easyGet(
              SESSIONSTORAGE_KEYS.callMeStatusCode,
            );
            if (
              returnCode !== null &&
              returnCode > CallMeStatus.disconnected &&
              returnCode <= CallMeStatus.success
            ) {
              dispatch(setDialOutReturnCode(8));
              const callMePhoneNumber = easyStore.easyGet(
                SESSIONSTORAGE_KEYS.callMePhoneNumber,
              );
              if (callMePhoneNumber) {
                const [
                  phoneNumber,
                  selectedCountryCode,
                  selectedCountryValue,
                  selectedCountryLabel,
                ] = callMePhoneNumber.split('#');
                dispatch(setDialOutPhoneNumber(phoneNumber));
                dispatch(
                  setDialOutSelectedCountry({
                    code: selectedCountryCode,
                    value: selectedCountryValue,
                    label: selectedCountryLabel,
                  }),
                );
              }
            }
          }
          if (typeof item.bHold !== 'undefined') {
            // dispatch(setOnHold(item.bHold));
          }
          if (audio === 'phone' && item.audio === '') {
            dispatch(setDialOutReturnCodeThunk(-1));
          }
          const meetingOptions = meetingConfig.meetingOptions;
          const autoDisplayJoinDialog =
            meetingOptions.isEnableAutomaticDisplayJoinAudioDialog;
          if (
            'audio' in item &&
            !isJoinAudio(item) &&
            !isLeavingAudioForSharingDesktopAudio &&
            !isExternalControlledMode()
          ) {
            if (autoDisplayJoinDialog == null || autoDisplayJoinDialog) {
              if (isJoinAudio(currentUser)) {
                dispatch(setJoinAudioDialogVisible(true, item));
              }
              if (isJoinByPhone(currentUser) && !isJoinByPhone(item)) {
                dispatch(setDialOutReturnCodeThunk(-1));
              }
            } else if (isJoinAudioDialogVisible) {
              // we should not auto join audio, but if the join dialog is already up, send connecting to audio status.
              dispatch(
                sendChangeAudioStatusRequest(
                  currentUser.audioConnectionStatus,
                  AudioConnectionStatus.CONNECTING,
                ),
              );
            }
          }
          if (item.audio === 'phone') {
            // if get the value 'phone' of audio, that means user join the phone by itself
            // at the same time it will get the audio mute/unmute status, that will call the 'host mute/unmute you' checking
            // so we need to mock a value of SelfMuteOrUnmute and it should be true
            dispatch(setSelfMuteOrUnmute(true));
            // sometime, it will get 'phone' more than once when phone call accepted, we need to dismiss the useless SelfMuteOrUnmute
            setTimeout(() => {
              const {
                audio: { isSelfMuteOrUnmute },
              } = getState();
              if (isSelfMuteOrUnmute) {
                dispatch(setSelfMuteOrUnmute(false));
              }
            }, 1200);
          }
          if (isJoinAudio(currentUser) && item.muted !== undefined) {
            dispatch(mutedOrUnMutedByHost(item));
          }

          if (typeof item.role !== 'undefined') {
            if (isViewOnly(item.role) && !isAllowOnlyCanTalk(item.role)) {
              dispatch(
                setAvStatusInSessionStorage({
                  audio: 'mute',
                  video: 'mute',
                }),
              );
            }
            if (isHost(item.role)) {
              dispatch(meetingActions.setHost(true));
              setIsHostAtLocal(true);
            } else {
              dispatch(meetingActions.setHost(false));
              setIsHostAtLocal(false);
            }
          }

          if (typeof item.dn2 !== 'undefined') {
            easyStore.easySet(
              'sessionUserName',
              decodeBase64(item.dn2, true),
              storeType.sessionStorage,
            );
          }
          if (
            typeof item.bCanPinMultiVideo !== 'undefined' &&
            !item.bCanPinMultiVideo
          ) {
            dispatch(clearPinVideoListThunk(true));
          }
        }
        dispatch(meetingActions.updatebCCEditor(item));
        if (item?.bHold && item?.id) {
          waitingRoomToUpdateUsers.push({ userId: item.id });
          dispatch(
            setSpotlightVideoList({
              type: 'remove',
              userId: item.id,
            }),
          );
        }

        // reset gallery sort type
        // new host not support gallery sort
        // set sorttype none
        if (isHost(item.role)) {
          dispatch(newHostGallerySortTypeModifyThunk(item));
        }
        // if videoOn changed, it represents video action complete.
        if (item.bVideoOn !== currentUser.bVideoOn && videoActionInProgress) {
          dispatch(setVideoActionInProgress(false));
        }
      });
      dispatch(updateExpiredReactions(waitingRoomToUpdateUsers));
    }
    if (needUpdateCurrentUser) {
      dispatch({ type: types.UPDATE_CURRENT_USER, message });
    }
  };
}

function kvUpdateIndication(message) {
  return (dispatch) => {
    if (message.body.key === 'bRaiseHand' && message.body.value === 0) {
      dispatch({ type: types.SET_ATTENDEE_RAISEHAND, message });
    }
    if (message.body.key === 'feedback' && message.body.value === 0) {
      dispatch({ type: types.CLEAR_ATTENDEE_FEEDBACK, message });
      dispatch({ type: types.CLEAR_CURRENTUSER_FEEDBACK, message });
    }
  };
}

export function openSocket(commandSocketUrl) {
  return { type: socketActionTypes.OPEN_SOCKET, commandSocketUrl };
}

export function attendeeListReset() {
  return { type: types.CLEAR_ATTENDEES_LIST };
}

export function closeSocket(
  triggerFailover,
  reason = FAILOVER_REASON.NORMAL_CASE,
) {
  return (dispatch, getState) => {
    attendeeListReset();
    infoLog('--close socket--');
    const {
      meeting: { isNowFailoverInProgress },
      socketStatus: { xmppSocketStatus },
    } = getState();
    if (!triggerFailover) {
      dispatch({ type: types.IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
      dispatch({ type: socketActionTypes.CLOSE_SOCKET });
    }
    if (xmppSocketStatus !== 'close') {
      dispatch({ type: socketActionTypes.CLOSE_XMPP_SOCKET, data: null });
      closeXMPP();
    }
    if (WCSockets.checkStatus(SocketType.RWG)) {
      closeRWGSocket(reason);
    } else {
      if (triggerFailover && !isNowFailoverInProgress) {
        // if force close before, we need to force call the onclose which is a backup handler now
        WCSockets.get(SocketType.RWG)?.backupHandlers?._onClose?.(
          {},
          FAILOVER_REASON.NORMAL_CASE,
        );
      }
    }
  };
}

export function avLeaveMeeting() {
  return (dispatch, getState) => {
    const {
      sharing: { isReceiveSharing, shareeCurrentActiveNodeId },
    } = getState();
    if (!_.isEmpty(globalVariable.avSocket)) {
      globalVariable.avSocket.sendSocket(AVNotifyMediaSDKTypes.END_MEDIA, {});
      globalVariable.avSocket.closeSocket();
    }
    if (!sendSocketMessage) return;
    const body = {
      id: 1,
    };
    const data = {
      evt: socketEventTypes.WS_VIDEO_UNSUBSCRIBE_REQ,
      body,
    };
    dispatch(sendSocketMessage(data));

    if (isReceiveSharing) {
      const body = {
        id: shareeCurrentActiveNodeId,
      };
      const data = {
        evt: socketEventTypes.WS_SHARING_UNSUBSCRIBE_REQ,
        body,
      };
      dispatch(sendSocketMessage(data));
    }
  };
}

function setIsMuteVideo(message) {
  return (dispatch, getState) => {
    const { currentUser } = getState().meeting;
    if (message.body.id === currentUser.userId) {
      const data = message.body.bVideoMute;
      dispatch(meetingActions.setCurrentCurrentUserIsMuteVideo(data));
      if (data) {
        dispatch(stopCaptureVideo());
        wcToast({
          text: HOST_STOP_VIDEO,
          type: 'notify',
        });
      }

      if (!data) {
        dispatch(muteVideo({ type: meetingConstants.MUTE_VIDEO_MODAL_START }));
      }
    }
  };
}

function handleMuteAllAction(message) {
  return (dispatch) => {
    const bMuteAll = message.body.bMuteAll;
    if (bMuteAll) {
      dispatch(stopCaptureVideo());
    }
  };
}

function readRealTimeClosedCaption(message, preMessage, zoomID) {
  const { changedContent, where, operation } = message.body;
  if (operation === 'delete') {
    return Promise.resolve(sliceStringByCodeLength(preMessage, where));
  }
  return beginDecrypt({
    decryptedText: changedContent,
    type: ivType.CC_MESSAGE,
    userId: zoomID,
  }).then(({ message: newMessage }) => {
    let messageNow = preMessage;
    switch (operation) {
      case 'start':
      case 'replace':
        messageNow = newMessage;
        break;
      case 'insert':
        messageNow += newMessage;
        break;
      default:
        break;
    }
    return messageNow;
  });
}

//
function readRealTimeLiveTranscription(message, preMessage, zoomID) {
  const { changedContent, where, operation, count } = message.body;
  return beginDecrypt({
    decryptedText: changedContent !== undefined ? changedContent : '',
    type: ivType.CC_MESSAGE,
    userId: zoomID,
  }).then(({ message: newMessage }) => {
    let messageNow = preMessage;
    switch (operation) {
      case 'add':
        messageNow = newMessage;
        break;
      case 'replace':
        // delete
        if (
          (count === -1 || count > getStringLength(messageNow)) &&
          newMessage === '' &&
          where === 0
        ) {
          messageNow = false;
          break;
        }
        if (where < 0 || count < -1) {
          break;
        }
        messageNow = replaceStringByCodeLength(
          messageNow,
          newMessage,
          where,
          count,
        );
        break;
      default:
        break;
    }
    return messageNow;
  });
}

function updateClosedCaption(message) {
  return (dispatch, getState) => {
    const {
      meeting,
      attendeesList: { attendeesList },
      liveTranscription: { allMessages, messagesOrder },
    } = getState();

    const { restrictFeatures = {} } = meeting;
    if (restrictFeatures[JOIN_MEETING_POLICY.CC]) return;
    // real time closed caption
    if (
      (message.body.changedContent || message.body.operation === 'delete') &&
      meeting.closedCaption.listenClosedCaption &&
      message.body.type !== CC_TYPE.LIVE_TRANSCRIPTION
    ) {
      const realTimeUserId = currentCCSelector(getState()).zoomID;
      const preMessage = meeting.closedCaption.tempRealTimeClosedCaption;
      readRealTimeClosedCaption(message, preMessage, realTimeUserId).then(
        (newMessage) => {
          dispatch(meetingActions.updateMessageLatest(newMessage));
          dispatch(meetingActions.updateTempRealTimeCC(newMessage));
        },
      );
    }

    // real time live transcription
    if (
      message.body.changedContent &&
      message.body.type === CC_TYPE.LIVE_TRANSCRIPTION &&
      message.body.destNodeID !== 0 &&
      message.body.srcMsgID
    ) {
      if (restrictFeatures[JOIN_MEETING_POLICY.DISABLE_NEW_LT]) return;
      const realTimeUserId = meeting.gatewayInfoList.find(
        (g) => g.userid === message.body.LtGwID,
      ).sn;
      const preMessage = allMessages[message.body.srcMsgID]
        ? allMessages[message.body.srcMsgID].message
        : '';
      readRealTimeLiveTranscription(message, preMessage, realTimeUserId).then(
        (newMessage) => {
          if (newMessage === false) {
            dispatch(deleteMessage(message.body.srcMsgID));
            for (let i = messagesOrder.length - 1; i >= 0; i--) {
              if (allMessages[messagesOrder[i]].isFinished === false) {
                dispatch(
                  meetingActions.updateMessageLatest(
                    allMessages[messagesOrder[i]].message,
                  ),
                );
              } else if (i === 0) {
                dispatch(meetingActions.updateMessageLatest(''));
              }
            }
          } else {
            dispatch(
              updateLiveTranscriptionMessage({
                ccType: message.body.type,
                decryptedMessage: newMessage,
                destNodeID: message.body.destNodeID,
                srcMsgID: message.body.srcMsgID,
              }),
            );
            dispatch(meetingActions.updateMessageLatest(newMessage));
          }
        },
      );
    }

    // final live transcription
    if (
      message.body.changedContent &&
      message.body.type === CC_TYPE.LIVE_TRANSCRIPTION &&
      message.body.destNodeID === 0 &&
      message.body.srcMsgID &&
      !message.body.operation
    ) {
      if (restrictFeatures[JOIN_MEETING_POLICY.DISABLE_NEW_LT]) return;
      const realTimeUserId = meeting.gatewayInfoList.find(
        (g) => g.userid === message.body.LtGwID,
      ).sn;

      beginDecrypt({
        decryptedText: message.body.changedContent,
        type: ivType.CC_MESSAGE,
        userId: realTimeUserId, // This is speaker Id! It is stored in gatewayInfo.
      }).then(({ message: ltText }) => {
        dispatch(
          updateLiveTranscriptionMessage({
            ccType: message.body.type,
            decryptedMessage: ltText,
            destNodeID: message.body.destNodeID,
            srcMsgID: message.body.srcMsgID,
            isFinished: true,
          }),
        );
      });
    }
    // first closed caption start
    if (
      message.body.changedContent &&
      !meeting.closedCaption.enableClosedCaption
    ) {
      dispatch(meetingActions.enableClosedCaption(true));
      dispatch(showLiveTranscriptionToastThunk(true));
      sendMsgToPWA(PWAMeetingEvent.LIVE_TRANSCRIPTION_ENABLED);
    }

    // final close caption
    // update closed caption message which sent through pressing Enter key
    if (!_.isEmpty(message.body.text)) {
      const ccMessages = meeting.closedCaption.closedCaptionMessages;
      let userid;
      if (message.body.destNodeID) {
        if (!userid) {
          const tmpUser = getUserById(attendeesList, message.body.destNodeID);
          if (tmpUser) {
            userid = tmpUser.zoomID;
          }
        }

        const { gatewayInfoList } = meeting;

        if (!userid && gatewayInfoList && gatewayInfoList.length > 0) {
          const gateway = gatewayInfoList.find(
            (g) => g.userid === message.body.destNodeID,
          );
          if (gateway) {
            userid = gateway.sn;
          }
        }
      }

      beginDecrypt({
        decryptedText: message.body.text,
        type: ivType.CC_MESSAGE,
        userId: userid,
      }).then(({ message: ccText }) => {
        dispatch(
          meetingActions.pushClosedCaptionMessage(ccMessages.concat(ccText)),
        );
        dispatch(
          updateLiveTranscriptionMessage({
            decryptedMessage: ccText,
            destNodeID: message.body.destNodeID,
            ccType: message.body.type,
            isFinished: true,
            srcMsgID: message.body.srcMsgID,
          }),
        );
        if (meeting.closedCaption.listenClosedCaption) {
          dispatch(meetingActions.updateMessageLatest(ccText));
          dispatch(meetingActions.updateTempRealTimeCC(''));
        } else {
          dispatch(meetingActions.updateMessageTemp(ccText));
        }
      });
    }
  };
}

export function sendClosedCaptionMessage(message) {
  return (dispatch) => {
    const data = {
      evt: socketEventTypes.WS_CONF_CLOSED_CAPTION_REQ,
      body: {
        text: message,
        seq: 1,
      },
    };
    dispatch(sendSocketMessage(data));
  };
}

export function sendRTClosedCaptionMessage(message) {
  return (dispatch) => {
    const data = {
      evt: socketEventTypes.WS_CONF_CLOSED_CAPTION_REQ,
      body: message,
    };
    dispatch(sendSocketMessage(data));
  };
}

export function saveWebSDKUserThunk(data, type) {
  return (dispatch, getState) => {
    let {
      attendeesList: {
        sdkUsers: { normalParticipants, webinarAttendees },
      },
      breakoutRoom: {
        attendee: { status: boStatus },
      },
    } = getState();
    const isInBO = isInRoom(boStatus) || isJoiningRoom(boStatus);
    if (
      (isInBO && type === 'cmdOrBoSession') ||
      (!isInBO && type === 'mainSessionForBO')
    ) {
      return;
    }
    const preNormalParticipants = normalParticipants;
    const preWebinarAttendees = webinarAttendees;
    if (data.add && data.add.length !== 0) {
      const addSDKList = data.add.filter((v) => v.sdkKey);
      normalParticipants = updateByKey(normalParticipants, addSDKList, 'id');
    }

    if (data.remove && data.remove.length !== 0) {
      const removeSDKList = data.remove;
      normalParticipants = removeByKey(normalParticipants, removeSDKList, 'id');
    }
    if (data.xmppAttendees && data.xmppAttendees.length !== 0) {
      webinarAttendees = data.xmppAttendees; //.filter((v) => v.sdkKey);
    }

    if (
      preNormalParticipants.length !== normalParticipants.length ||
      preWebinarAttendees.length !== data.currentAttendeeCount
    ) {
      dispatch(setAppSignalInfo({ status: 'loading' }));
    }

    dispatch(saveWebSDKUser({ normalParticipants, webinarAttendees }));
  };
}

export const onLostHostOrCohostPrivilege =
  ({ isCoHost, isHost }) =>
  (dispatch, getState) => {
    if (!isHost || !isCoHost) {
      const {
        dialog: {
          shareToBoConfirm: { visible: shareToBoConfirmVisible },
        },
      } = getState();
      if (shareToBoConfirmVisible) {
        dispatch(setShareToBoConfirmDialogVisible(false));
      }
      dispatch(resetIsSharingToBO());
    }
  };

function isSignLanguageInterpretationEnabled(boStatus) {
  return (
    meetingConfig.signInterpreterInfo?.isEnable &&
    isSupportMultiView() &&
    !isInRoom(boStatus) &&
    !isJoiningRoom(boStatus) &&
    !isTeslaMode()
  );
}

export function handleSocketMessage(message) {
  return (dispatch, getState) => {
    const {
      dialog: {
        confEnded: { visible: isConfEndedDialogVisible },
      },
      meeting: {
        currentUser: { userId: currentUserId, userRole, bid },
        bFollowHostVideo,
        isHost: iamHost,
        isTransfer,
        originalMeetingStatus,
      },
      video: {
        hasChangedVideoOrder,
        galleryOrderType,
        isEnableAutoAcceptCameraControl,
      },
      socketStatus: { commandSocketUrl },
      meetingUI: { loadingNotificationValues, isOnHold },
      breakoutRoom: {
        attendee: { status: boStatus },
      },
    } = getState();
    const meetingChatList = meetingChatListSelector(getState());

    let adaptor = googleMeetAdaptor({ meetingConfig });

    const isNewChatUI = isNewChatUIReady(!!bid);

    // when the wssocket is disconnected we'll not receive any messages
    if (!message || isConfEndedDialogVisible) {
      return null;
    }

    const type = message.evt;
    if (isExternalControlledMode()) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.RWG,
        type,
        message,
      );
    }
    // here to disable all unnecessary messages on Tesla Mode
    if (isShouldDisableMessageOnTeslaMode(type)) {
      return false;
    }
    EventTimeoutTracker.getInstance().notifyCommandSocoket(message);
    switch (type) {
      case socketEventTypes.WS_CONF_JOIN_REQ:
        return dispatch(meetingActions.startJoinMeeting(message));
      case socketEventTypes.WS_CONF_JOIN_RES: {
        // send Req
        if (
          meetingConfig.meetingOptions.isWebclientInMeetingWhiteboardEnabled &&
          !isExternalControlledMode() &&
          !isViewOnly(message?.body?.role) &&
          navigator.hardwareConcurrency > 2 &&
          typeof window.WhiteboardSdk === 'undefined'
        ) {
          loadWhiteboard();
        }
        logJoinFlowTelemetry('meeting_handle_4098');
        dispatch(meetingActions.joinMeetingSuccess(dispatch, message));
        dispatch(reportConnectionInfo(message)); // get 4098
        return;
      }
      case socketEventTypes.WS_CONF_SEND_MEETING_TOKEN_RES: {
        meetingActions.setMeetingToken(message);
        break;
      }
      case socketEventTypes.WS_CONF_ROSTER_INDICATION:
        dispatch(correctSomeRosterAttributeInBO(message));
        if (globalVariable.avSocket?.sendSocket) {
          if (message.body.add) {
            updateUserRosterInfoToSDK({
              addUsers: message.body.add,
              isFromMainSession: false,
              mediaActionType: sdkIvTypeKeyEnum.SHARING_DECODE,
            });
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.USER_NODE_LIST,
              {
                userList: message.body.add.map(
                  ({ id: userid, zoomID: sn }) => ({
                    userid,
                    sn: sn && decodeBase64ToBuffer(sn),
                  }),
                ),
                encryptKey: easyStore.getRawSessionKey(),
              },
            );

            dispatch(checkReclaimHostStatus(message.body.add));
          }

          if (message.body.remove && message.body.remove.length > 0) {
            updateUserRosterInfoToSDK({
              removeUserIds: message.body.remove.map(({ id }) => id),
              isFromMainSession: false,
              mediaActionType: sdkIvTypeKeyEnum.SHARING_DECODE,
            });
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.USER_NODE_LIST,
              {
                userList: message.body.remove.map(({ id: userid }) => ({
                  userid,
                  bremove: true,
                })),
                encryptKey: easyStore.getRawSessionKey(),
              },
            );
            dispatch(
              handlePendingActiveFrameVideoThunk(message.body.remove, 'remove'),
            );
          }

          /** audiobridge need mute/unmute status to reduce monitor log size */
          if (isAudioBridge()) {
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.USER_NODE_AUDIO_STATUS_LIST,
              {
                update: (message.body.update || [])
                  .filter((user) => user.muted !== undefined)
                  .map((user) => ({
                    userId: user.id,
                    muted: user.muted,
                  })),
                remove: (message.body.remove || []).map(({ id: userId }) => ({
                  userId,
                })),
              },
            );
          }
        }

        if (!_.isEmpty(message.body.update)) {
          checkOnAttendees(
            message.body.update,
            getState().meeting.isHost,
            getState().attendeesList,
            dispatch,
          );
          dispatch(checkReclaimHostStatus(message.body.update));
          // dispatch(expireWaitingRoomUserReaction(message.body.update));
        }
        if (!_.isEmpty(message.body.remove)) {
          EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
            'userLeave',
          ]).emit('userLeave', message);
        }
        // if (!_.isEmpty(message.body.add)) {
        //   EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
        //     'userJoin',
        //   ]).emit('userJoin', message);
        // }

        dispatch(updatePlayChimeUniqFlag(message));
        storeLeaveParticipant(getState(), message, true);
        // dispatch(announcAttendeesOnHold(message));
        // add these property use for other reducer module
        message.body.currentUserId = currentUserId;
        message.body.hasChangedVideoOrder = hasChangedVideoOrder;
        message.body.galleryOrderType = galleryOrderType;
        message.body.isFollowHost = bFollowHostVideo;
        message.body.isWebinarAttendee = isWebinar() && isViewOnly(userRole);

        dispatch(promptParticipantA11yInfo(message));
        dispatch({ type: types.WS_CONF_ROSTER_INDICATION, message });
        dispatch(tryDismissRequestLtDialog(message));
        dispatch(updateIsVideoShare(message));
        dispatch(tryLaunchOrCloseLocalSharer(message));
        dispatch(tryCheckPutOnHoldAttendeeInBoRoom(message));
        dispatch(saveWebSDKUserThunk(message.body, 'cmdOrBoSession'));
        dispatch(avMuteOrUnmuteCurrentUser(message));
        dispatch(updateCurrentUser(message));
        dispatch(tryUpdateChatReceiverName(message));
        dispatch(updateSpotlightList(message));
        dispatch(updatePinList(message));
        if (!isExternalControlledMode()) {
          dispatch(handleFarEndCameraLeft(message));
          dispatch(handleUserIsInCameraControlGroup(message));
        }
        dispatch(updateFollowHostViewThunk(message));
        dispatch(handleBoActivityStatusFromRosterIndication(message));
        dispatch(happyRoster(message));
        if (!_.isEmpty(message?.body?.update)) {
          dispatch(
            handlePendingActiveFrameVideoThunk(message.body.update, 'update'),
          );
        }
        dispatch(updateWBDashboardBasicInfoForHostJoin(message));
        dispatch(handleLlsInfoUpdate(message));
        if (message.body.add || message.body.remove) {
          dispatch(refreshActiveSpeakerList());
        }
        break;
      case socketEventTypes.WS_CONF_ATTRIBUTE_INDICATION: {
        const store = getState();
        if (!message.body) {
          break;
        }
        Object.entries(message.body).forEach(([key, value]) => {
          dispatch(doRWGAction(value, makeKey(type, key)));
        });
        if (typeof message.body.bHoldUponEntry !== 'undefined') {
          if (store.security.UI.isRequestEnableWaitingRoom) {
            if (message.body.bHoldUponEntry) {
              wcToast({
                text: iText(
                  'You have enabled the waiting room',
                  'apac.security.waiting_room_notification_1',
                ),
                type: 'notify',
              });
            } else {
              wcToast({
                text: iText(
                  'You have disabled the waiting room',
                  'apac.security.waiting_room_notification_2',
                ),
                type: 'notify',
              });
            }
            dispatch(setRequestEnableWaitingRoom(false));
          }
        } else if (_.has(message, 'body.lockShare')) {
          dispatch(changeLockShare(message.body.lockShare));
          if (message.body.lockShare === SHARER_STATUS_ENUM.ED) {
            adaptor.notifyControllerMeetingUpdate({
              contentShare: { state: CONTENT_SHARE_STATE.INACTIVE },
            });
          }
          return null;
        } else if (_.has(message, 'body.bAllowAttendeeQAAutoReply')) {
          // simulive auto-reply indication
          dispatch(setSimuliveAutoreplyEnable(message.body));
        } else if (_.has(message, 'body.bMutedAll')) {
          if (isJoinAudio(store.meeting.currentUser)) {
            dispatch(setHostMutedAll(message.body.bMutedAll));
          }
        }
        if (message.body.encryptKey) {
          easyStore.gcmInit(
            store.meeting.zoomId,
            message.body.encryptKey,
            store.meeting.encType === encryptType.AES_GCM,
          );
        }
        if (message.body.gatewayKey) {
          const [, userid, sn] = message.body.gatewayKey.split('.');
          dispatch(
            meetingActions.updateGatewayInfoList({
              userid: Number(userid),
              sn,
            }),
          );
          if (globalVariable.avSocket?.sendSocket) {
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.USER_NODE_LIST,
              {
                userList: [
                  {
                    userid: Number(userid),
                    sn: sn && decodeBase64ToBuffer(sn),
                  },
                ],
                encryptKey: easyStore.getRawSessionKey(),
              },
            );
          }
        }

        if (_.has(message, 'body.bAllowAnonymousQuestion') && !isWebinar()) {
          dispatch(
            setBAllowAnonymousQuestion(message.body.bAllowAnonymousQuestion),
          );
        }
        if (
          _.has(message, 'body.bAllowAttendeeViewAllQuestion') &&
          !isWebinar()
        ) {
          dispatch(
            setBAllowAttendeeViewAllQuestion(
              message.body.bAllowAttendeeViewAllQuestion,
            ),
          );
        }
        if (
          _.has(message, 'body.bAllowAttendeeUpvoteQuestion') &&
          !isWebinar()
        ) {
          dispatch(
            setBAllowAttendeeUpvoteQuestion(
              message.body.bAllowAttendeeUpvoteQuestion,
            ),
          );
        }
        if (
          _.has(message, 'body.bAllowAttendeeCommentQuestion') &&
          !isWebinar()
        ) {
          dispatch(
            setBAllowAttendeeCommentQuestion(
              message.body.bAllowAttendeeCommentQuestion,
            ),
          );
        }
        if (_.has(message, 'body.QaEnabled')) {
          dispatch(getQaEnabledThunk(message.body.QaEnabled));
        }
        if (_.has(message, 'body.QaQuestionEnabled') && !isWebinar()) {
          dispatch(setEnableQuestion(message.body.QaQuestionEnabled));
        }

        // cancel follow host  reset
        if (
          _.has(message, 'body.bFollowHostVideo') &&
          !iamHost &&
          !message.body.bFollowHostVideo
        ) {
          dispatch(setGalleryOrderTypeThunk(0, true));
          dispatch(resetVideoOrder(currentUserId));
          dispatch(setHasChangedVideoOrder(false));
        }

        // meeting users/ webinar attendeee
        if (
          _.has(message, 'body.bFollowHostVideo') &&
          !iamHost &&
          (!isWebinar() || (isWebinar() && isViewOnly(userRole))) &&
          message.body.bFollowHostVideo
        ) {
          dispatch(setHasChangedVideoOrder(true));
          dispatch(setDragLayoutThunk({ forceFollowDrag: true }));
        }

        // panelist
        if (
          _.has(message, 'body.bFollowHostVideo') &&
          !iamHost &&
          isWebinar() &&
          !isViewOnly(userRole) &&
          message.body.bFollowHostVideo
        ) {
          dispatch(setHasChangedVideoOrder(false));
          dispatch(resetVideoOrder(currentUserId));
        }

        if (
          _.has(message, 'body.GalleryViewSortType') &&
          // 1 not host case -> unchecked follow host order host not reset
          // 2 have gallery view sort type case -> fail over
          (!iamHost || !!message.body.GalleryViewSortType) &&
          (!isWebinar() || (isWebinar() && isViewOnly(userRole)))
        ) {
          dispatch(setGalleryOrderTypeThunk(message.body.GalleryViewSortType));
        }

        if (message.body.bEnablePolling && !isTeslaMode()) {
          dispatch(setIsRwgEnablePolling(true));
        }
        if (message.body.bHasPollingInMeeting && !isTeslaMode()) {
          dispatch(setHasPollingInMeeting(true));
        }
        if (
          (message.body.bEnablePolling || message.body.bHasPollingInMeeting) &&
          message.body.strTk &&
          !isTeslaMode()
        ) {
          easyStore.easySet(
            'ZM-POLL-TOKEN',
            message.body.strTk,
            storeType.sessionStorage,
          );
        }
        if (
          typeof message.body.AttendeeVideoControlMode !== 'undefined' &&
          typeof message.body.AttendeeVideoControlModeEx !== 'undefined'
        ) {
          dispatch(
            receiveFollowHostViewThunk(
              message.body.AttendeeVideoControlMode,
              message.body.AttendeeVideoControlModeEx,
            ),
          );
        }

        if (typeof message.body.AttendeeVideoLayoutMode !== 'undefined') {
          dispatch(receiveLayoutThunk(message.body.AttendeeVideoLayoutMode));
        }

        if (typeof message.body.AttendeeVideoLayoutFlag !== 'undefined') {
          dispatch(
            receiveLayoutFlagThunk(message.body.AttendeeVideoLayoutFlag & 0x0f),
          );
        }
        if (typeof message.body.ExternalLimitShare !== 'undefined') {
          if (message.body.ExternalLimitShare === false) {
            dispatch(setHasLimitSharingConfirmed(false));
          }
          dispatch(setHasNotSameAccountUser(message.body.ExternalLimitShare));
        }

        if (_.has(message, 'body.AttentionMode')) {
          // attention mode on
          dispatch(onAttentionModeChangeThunk(message.body.AttentionMode));
        }

        if (_.has(message, 'body.ShareAttentionMode')) {
          // share attention mode on
          dispatch(
            onShareAttentionModeChangeThunk(message.body.ShareAttentionMode),
          );
        }
        if (_.has(message, 'body.confStatus')) {
          if (
            message.body.confStatus ===
            CONF_SESSION_STATUS.MEETING_STAT_PUSH_READY
          ) {
            dispatch(meetingActions.setConfReady(true));
          }
        }

        if (
          _.has(message, 'body.cmrServerStatus') &&
          isCMRRecordingSuccess(message.body.cmrServerStatus)
        ) {
          dispatch(
            meetingUIActions.setStartRecordingNotificationVisible(false),
          );
          startRecordingErrorRef.current?.close();
        } else if (
          _.has(message, 'body.cmrServerStatus') &&
          isCMRRecordingInit(message.body.cmrServerStatus) &&
          !isUIConnectingCMR(loadingNotificationValues)
        ) {
          const mockData = {
            evt: socketEventTypes.WS_CONF_RECORD_REQ,
            body: {
              isAutoRecording: true,
            },
          };
          EventTimeoutTracker.getInstance().listenCommandSocoket(mockData);
        }

        if (typeof message.body.bWaterMarkOn !== 'undefined') {
          dispatch(renderWatermark(message.body.bWaterMarkOn));
        }
        if (_.has(message, 'body.bGalleryVideoOrderSaved')) {
          dispatch(setIsViewOrderSaved(message.body.bGalleryVideoOrderSaved));
        }
        if (_.has(message, 'body.SalesRecordingAnalytics')) {
          dispatch(setIsZoomIQ(message.body.SalesRecordingAnalytics));
        }
        if (
          _.has(message, 'body.docId') &&
          !isExternalControlledMode() &&
          navigator.hardwareConcurrency > 2 &&
          meetingConfig.meetingOptions.isWebclientInMeetingWhiteboardEnabled
        ) {
          const {
            whiteboard: {
              whiteboardConfig: { docId },
              whiteboardSharer: { dcsID, presenterID },
            },
            meeting: {
              currentUser: { userId },
            },
          } = getState();
          if (
            message.body.docId &&
            message.body.docId !== docId &&
            presenterID === userId
          ) {
            dispatch(closeWhiteboard());
          }
          dispatch(setWhiteboardConfig(message.body));
          if (dcsID && message.body.docId === docId) {
            globalVariable.whiteboardSdk?.conn?.updateTokenFromChannel?.({
              cgk: message.body.confGroupToken,
            });
          }
        }

        EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
          socketEventTypes.WS_CONF_ATTRIBUTE_INDICATION,
        ]).emit(socketEventTypes.WS_CONF_ATTRIBUTE_INDICATION, message);
        return dispatch(meetingActions.setConfAttributeIndication(message));
      }
      case socketEventTypes.WS_CONF_MEDIA_SESSION_READY_INDICATION: {
        const bReady = _.get(message, 'body.bReady');
        const type = _.get(message, 'body.type');
        if (bReady) {
          if (type === SESSION_TYPE.SESSION_TYPE_AUDIO) {
            return dispatch(meetingActions.setAudioSessionReady(true));
          } else if (type === SESSION_TYPE.SESSION_TYPE_VIDEO) {
            return dispatch(meetingActions.setVideoSessionReady(true));
          } else if (type === SESSION_TYPE.SESSION_TYPE_DESKSHARE) {
            return dispatch(meetingActions.setShareSessionReady(true));
          }
        }
        break;
      }
      case socketEventTypes.WS_CONF_CAN_ADMIT_WHEN_NOHOST_PRESENT_INDICATION:
        return dispatch(
          meetingActions.updateCurrentUserCanAdmit(message.body.bCanAdmit),
        );
      case socketEventTypes.WS_CONF_LOCK_RES:
        return dispatch(meetingActions.getLockRes(message));
      case socketEventTypes.WS_CONF_END_RES:
      case socketEventTypes.WS_CONF_LEAVE_RES:
        // just return cause WS_CONF_LEAVE_REQ and WS_CONF_END_REQ have done the same thing
        return null;
      case socketEventTypes.WS_AUDIO_DIALOUT_RES:
        return dispatch(handleDialOutResponse(message));
      case socketEventTypes.WS_AUDIO_CANCEL_DIALOUT_RES:
        return dispatch(handleDialOutCancelResponse(message));
      case socketEventTypes.WS_CONF_END_INDICATION:
        return dispatch(meetingActions.endMeetingJob(message));
      case socketEventTypes.WS_CONF_HOST_CHANGE_INDICATION:
        EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
          socketEventTypes.WS_CONF_HOST_CHANGE_INDICATION,
        ]).emit(socketEventTypes.WS_CONF_HOST_CHANGE_INDICATION);
        dispatch(handleHostUpdateIndication(message));
        // if the host is not the host anymore,we need set the value false.
        dispatch(setIsHostBeforeJoinBo(message.body.bHost));
        dispatch(handleFocusModal(message.body.bHost));
        dispatch(onLostHostOrCohostPrivilege({ isHost: message.body.bHost }));
        // reset lt request to default true if host transfer
        // !failOver will trigger WS_CONF_HOST_CHANGE_INDICATION
        dispatch(onHostChangeResetRequestLtCap(true, message.body.bHost));
        dispatch(setInterpretationWindowVisible(false));
        dispatch(
          handleChangeSignLanguageInterpreterHostOrCoHostPrivilege(
            message.body.bHost,
          ),
        );
        if (message.body.bHost) {
          dispatch(meetingUIActions.setReclaimHostNotification(false));
        } else {
          dispatch(handleWithdrawCoOrHostPermission());
        }
        dispatch(handleCoOrHostChangeForWhiteboard(true, message.body.bHost));
        updateWBDashboardBasicInfo(getState());
        return dispatch(meetingActions.getHostChangeRes(message));
      case socketEventTypes.WS_AUDIO_ASN_INDICATION:
        return dispatch(getAsn(message));
      case socketEventTypes.WS_SHARING_STATUS_INDICATION:
        return dispatch(prepareForReceiveSharing(message));
      case socketEventTypes.WS_SHARING_SIZE_CHANGE_INDICATION:
        return dispatch(setSharingContentOriginalDimension(message));
      case socketEventTypes.WS_CONF_HOLD_CHANGE_INDICATION:
        easyStore.easySet(
          'isOnHold',
          message.body.bHold,
          storeType.sessionStorage,
        );
        directReport(
          {
            name: `${message.body.bHold ? 'enter' : 'admit'}_${
              isSupportWaitingRoomWithoutFailoverFlow() ? 'new' : 'old'
            }`,
          },
          {
            tags: ['WAITING_ROOM'],
          },
        );
        if (message.body.bHold) {
          // when failover in waiting room, isOnHold is default true,
          // do not destroy avSocket in this case.
          if (
            isNewJoinFlowEnabled() &&
            !isOnHold &&
            !isSupportWaitingRoomWithoutFailoverFlow()
          ) {
            globalVariable.avSocket.destroy();
            dispatch(socketStatusReset());
          }
          if (globalVariable.hasBeenInMeetingTimer) {
            clearTimeout(globalVariable.hasBeenInMeetingTimer);
          }
          easyStore.easySet('enterWRTime', Date.now(), storeType.memory);
          dispatch(meetingUIActions.setOnHold(message.body.bHold));
          if (isSupportWaitingRoomWithoutFailoverFlow()) {
            closeXMPP('new waiting room flow');
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.USER_HOLD_STATUS_SWITCH,
              {
                hold: true,
              },
            );
          }
          // if join meeting by transferring meeting, av status should follow original av status
          if (isTransfer) {
            if (originalMeetingStatus.isWaitingRoomAudioOn) {
              setAVStatus({ audioJoin: 'joined', audio: 'unmute' });
            } else if (originalMeetingStatus.isWaitingRoomAudioOn === false) {
              setAVStatus({ audioJoin: 'joined', audio: 'mute' });
            }
            if (originalMeetingStatus.isWaitingRoomVideoOn) {
              setAVStatus({ video: 'unmute' });
            } else {
              setAVStatus({ video: 'mute' });
            }
          }
        } else {
          if (!easyStore.easyGet(SESSIONSTORAGE_KEYS.hasBeenInMeeting)) {
            easyStore.easySet(
              SESSIONSTORAGE_KEYS.hasBeenInMeeting,
              true,
              storeType.sessionStorage,
            );
          }
        }
        if (isSupportWaitingRoomWithoutFailoverFlow()) {
          // Do not reset A/V status in session storage
          dispatch(stopCaptureVideo(false));
        }
        dispatch(clearNewChat());
        break;
      case socketEventTypes.WS_CONF_INVITE_CRC_DEVICE_RES:
        return dispatch({ type: types.WS_CONF_INVITE_CRC_DEVICE_RES, message });
      case socketEventTypes.WS_CONF_KV_UPDATE_INDICATION:
        return dispatch(kvUpdateIndication(message));
      case socketEventTypes.WS_VIDEO_ACTIVE_INDICATION: {
        return dispatch(receivetActiveVideoIdThunk(message));
      }
      case socketEventTypes.WS_CONF_CHAT_REQ:
        // sending chat
        // EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
        //   socketEventTypes.WS_CONF_CHAT_REQ,
        // ]).emit(socketEventTypes.WS_CONF_CHAT_REQ, message);
        return dispatch({ type: types.WS_CONF_CHAT_REQ, message });
      case socketEventTypes.WS_CONF_CHAT_INDICATION:
        // receive chat
        if (isNewChatUI) {
          EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
            socketEventTypes.WS_CONF_CHAT_INDICATION,
          ]).emit(socketEventTypes.WS_CONF_CHAT_INDICATION, message);
          return;
        }
        return dispatch(onReceiveChatMessage(message));
      case socketEventTypes.WS_CONF_CHAT_FILE_INDICATION:
        if (isNewChatUI) {
          EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
            socketEventTypes.WS_CONF_CHAT_FILE_INDICATION,
          ]).emit(socketEventTypes.WS_CONF_CHAT_FILE_INDICATION, message);
          return;
        }
        return dispatch(onReceiveChatMessage(message));
      case socketEventTypes.WS_CONF_CHAT_PRIVILEDGE_REQ:
        return dispatch({ type: types.WS_CONF_CHAT_PRIVILEDGE_REQ, message });
      case socketEventTypes.WS_CONF_HOST_RECEIVED_ENABLE_LIVE_TRANSCRIPT_REQ_INDICATION:
        return dispatch(onHostReceiveLtRequests(message));
      case socketEventTypes.WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_INDICATION:
        return dispatch(onChangeRequestLtPermission(message));
      case socketEventTypes.WS_CONF_CLOSED_CAPTION_INDICATION:
        return dispatch(updateClosedCaption(message));
      case socketEventTypes.WS_CONF_CLOSED_CAPTION_RES: {
        const { meeting } = getState();
        if (!meeting.closedCaption?.enableClosedCaption) {
          dispatch(meetingActions.enableClosedCaption(true));
          dispatch(showLiveTranscriptionToastThunk(true));
          sendMsgToPWA(PWAMeetingEvent.LIVE_TRANSCRIPTION_ENABLED);
        }
        break;
      }
      case socketEventTypes.WS_CONF_ASSIGN_CC_REQ:
        return dispatch({ type: types.WS_CONF_ASSIGN_CC_REQ, message });
      case socketEventTypes.WS_VIDEO_MUTE_INDICATION:
        sendMsgToPWA(PWAMeetingEvent.VIDEO_REQUEST);
        return dispatch(setIsMuteVideo(message));
      case socketEventTypes.WS_VIDEO_HOST_MUTE_ALL_VIDEO_INDICATION:
        return dispatch(handleMuteAllAction(message));
      case socketEventTypes.WS_VIDEO_SSRC_INDICATION:
        return dispatch({ type: types.WS_VIDEO_SSRC_INDICATION, message });
      case socketEventTypes.WS_AUDIO_SSRC_INDICATION:
        return dispatch(setAudioSsrcIndication(message.body.ssrc));
      case socketEventTypes.WS_VIDEO_LEADERSHIP_INDICATION: {
        //  When there is only one person in spotlight, clear the spotlightVideoList list
        //  before the next operation for compatibility with the old version
        const {
          video: { spotlightVideoList },
        } = getState();
        if (
          spotlightVideoList.length === 1 &&
          message.body.bLeadershipOn === false
        ) {
          dispatch(clearSpotlightVideoListThunk(false));
        }

        if (message.body.bLeadershipOn) {
          if (spotlightVideoList.length === 0) {
            dispatch(umnuteAfterSpotlighted(message.body));
          }
          dispatch(
            setSpotlightVideoList({
              type: 'receive',
              list: [message.body.id & 0xfffffc00],
            }),
          );
          dispatch(showAttentionModeGotToastForSpotlight(message.body));
        }

        return;
      }

      case socketEventTypes.WS_SHARING_REMOTE_CONTROL_INDICATION: {
        return dispatch(prepareForRemoteControl(message));
      }
      case socketEventTypes.WS_CONF_GET_RC_URL_SCHEME_RES: {
        return dispatch(handleRecvUrlScheme(message));
      }
      case socketEventTypes.WS_SHARING_REMOTE_CONTROLED_CLOSE_INDICATION: {
        return dispatch(handleRcAppClose());
      }
      case socketEventTypes.WS_SHARING_REMOTE_CONTROLED_CONNECTED_INDICATION: {
        return dispatch(handleRcAppConnected());
      }
      case socketEventTypes.WS_SHARING_REMOTE_CONTROLED_AUTH_RES: {
        return dispatch(handleQrCodeResponse(message));
      }
      case socketEventTypes.WS_SHARING_REMOTE_CONTROL_BLOCK_INDICATION: {
        return dispatch(
          setRemoteControlBlockDialogVisible({ visible: message.body.bBlock }),
        );
      }
      case socketEventTypes.WS_SHARING_REMOTE_CONTROLLER_GRAB_INDICATION:
        return dispatch(grabRemoteControl(message));
      case EVT_TYPE_WS_SHARING_SSRC_INDICATION:
        return dispatch(setSharerSsrc(message.body.ssrc));
      case WS_SHARING_DATA_RECEIVE_INDICATION:
        // will receive {bReceiveOk: true}
        return dispatch(toggleSharerIndicationThunk(message.body.bReceiveOk));
      case socketEventTypes.EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER:
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.COMMAND_SOCKET_MESSAGE_NOTIFY,
          message,
        );
        break;
      case socketEventTypes.WS_AUDIO_ALLOW_TALK_INDICATION: {
        const {
          body: { bAllowTalk, promoterID },
        } = message;
        return dispatch(webinarAttendeeJoinVoip(bAllowTalk, promoterID));
      }
      case socketEventTypes.WS_CONF_BO_TOKEN_RES: {
        const {
          breakoutRoom: { preRoomSize },
        } = getState();
        if (preRoomSize === 0) {
          return dispatch(handleAddRoomIndication(message));
        }
        return mergeAddRoomIndications(dispatch, message);
      }
      case socketEventTypes.WS_CONF_BO_ATTRIBUTE_INDICATION: {
        const { body } = message;
        return dispatch(handleUpdateBoAttributeIndication(body));
      }
      case socketEventTypes.WS_CONF_BO_COMMAND_INDICATION: {
        const { body } = message;
        return dispatch(handleBoCommandIndication(body));
      }
      case socketEventTypes.WS_CONF_BO_JOIN_RES: {
        const { body } = message;
        dispatch(handleBoJoinRes(body));
        break;
      }
      case socketEventTypes.WS_CONF_BO_PRE_ASSIGN_RES: {
        const { body } = message;
        dispatch(handleBoPreAssignRes(body));
        break;
      }
      case socketEventTypes.WS_CONF_COHOST_CHANGE_INDICATION: {
        dispatch(correctSomeRosterAttributeInBO(message));
        dispatch(meetingActions.coHostChange(message.body.bCoHost));
        handleCoHostUpdateIndication(message.body.bCoHost);
        dispatch(
          onLostHostOrCohostPrivilege({ isCoHost: message.body.bCoHost }),
        );
        dispatch(handleFocusModal(message.body.bCoHost));
        dispatch(
          handleChangeSignLanguageInterpreterHostOrCoHostPrivilege(
            message.body.bCoHost,
          ),
        );
        if (!message.body.bCoHost) {
          dispatch(handleWithdrawCoOrHostPermission());
        }
        dispatch(
          handleCoOrHostChangeForWhiteboard(false, message.body.bCoHost),
        );
        updateWBDashboardBasicInfo(getState());
        break;
      }
      case socketEventTypes.WS_CONF_BIND_UNBIND_INDICATION: {
        dispatch(meetingActions.audioMergeNotification(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_ADMIT_ALL_SILENT_USERS_INDICATION: {
        dispatch(meetingActions.setAdmitAllSilentUsers(message.body.bStarted));
        break;
      }
      case socketEventTypes.WS_CONF_OPTION_INDICATION: {
        updateOptInStorage(message);
        break;
      }
      case socketEventTypes.WS_CONF_PROMOTE_CONSENT_RES: {
        dispatch(showPromoteConfirmThunk(message.body));
        sendMsgToPWA(PWAMeetingEvent.PROMOTE_TO_PANELIST);
        break;
      }
      case socketEventTypes.WS_CONF_ROLE_CHANGE_RES: {
        dispatch(meetingActions.handlePromoteOrDepromote(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_DC_REGION_INDICATION: {
        dispatch({
          type: types.SET_MEETING_REGION,
          payload: message.body,
        });
        break;
      }
      case socketEventTypes.WS_AUDIO_SSRC_ASK_UNMUTE_INDICATION: {
        dispatch(unmutedByHost(message.body));
        break;
      }
      case socketEventTypes.WS_WEBINAR_VIEW_ONLY_TELEPHONY_INDICATION: {
        dispatch(setWebinarAttendeePhoneIndication(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_SUSPEND_MEETING_REQ_RESULT:
        dispatch(handleSuspendRes());
        break;
      case socketEventTypes.WS_CONF_GROUP_LAYOUT_INDICATION: {
        dispatch(receiveSpotlight(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_DRAG_LAYOUT_INDICATION: {
        const {
          //video: { attendeeVideoControlMode },
          meeting: {
            isHost,
            currentUser: { userId, userRole },
          },
        } = getState();
        if (message.body && message.body.drag_list) {
          // webinar attendee
          if (
            isWebinar() &&
            // webinar attendee always follow order  follow view/ unfollow view
            // 2023.4.11 sync window client logic
            //attendeeVideoControlMode === 2 &&
            isViewOnly(userRole)
          ) {
            dispatch(
              setDragLayoutThunk({
                dragList: message.body.drag_list,
                forceFollowDrag: true,
              }),
            );
          }
          // meeting user
          if (!isWebinar()) {
            dispatch(setDragLayoutThunk({ dragList: message.body.drag_list }));
          }
        } else if (!isHost) {
          //dispatch(setDragLayoutThunk({ dragList: [] }));
          dispatch(resetVideoOrder(userId));
          dispatch(setHasChangedVideoOrder(false));
        }
        break;
      }
      case socketEventTypes.WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_RES: {
        dispatch(setTurnOnOrOffLiveTranscriptionResponse(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_LIVE_TRANSCRIPTION_STATUS_INDICATION: {
        dispatch(onReceiveLiveTranscriptionOn(message.body.status));
        break;
      }
      case socketEventTypes.WS_CONF_POLLING_USER_ACTION_ERROR: {
        dispatch(pollingErrorPopUp(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_HOST_KEY_RES: {
        dispatch(setClaimHostDialog(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_CHAT_RES: {
        if (isNewChatUI) {
          EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
            socketEventTypes.WS_CONF_CHAT_RES,
          ]).emit(socketEventTypes.WS_CONF_CHAT_RES, message);
          return;
        }
        const {
          meeting: {
            currentUser: { userId },
          },
        } = getState();
        const { destNodeID, msgID, result, fileID } = message.body;
        if (result === CHAT_RESPONSE.BLOCK_BY_DLP) {
          dispatch(deleteChat(msgID));
          dispatch(showDLPPopupThunk(DLPActionType.block));
        } else if (result === CHAT_RESPONSE.DELETE_MESSAGE) {
          dispatch(deleteChat(msgID));
        } else if (result === CHAT_RESPONSE.CHAT_MESSAGE_OK) {
          dispatch(setSelfChatId({ destNodeID, msgID, userId, fileID }));
        }
        break;
      }
      case socketEventTypes.WS_CONF_CHAT_CMD_RES: {
        // delete chat res
        if (isNewChatUI) {
          EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
            socketEventTypes.WS_CONF_CHAT_CMD_RES,
          ]).emit(socketEventTypes.WS_CONF_CHAT_CMD_RES, message);
          return;
        }
        // If there's error during processing chat command, server will send this msg.
        if (message.body && message.body.bSuccess) {
          switch (message.body.cmd) {
            case CHAT_CMD_DELETE:
              dispatch(deleteChat(message.body.msgID));
              break;
            default:
              break;
          }
        }
        break;
      }
      case socketEventTypes.WS_CONF_CHAT_CMD_INDICATION: {
        EventQueueProvider.getRegister(SOCKET_NAME_ENUM.COMMAND, [
          socketEventTypes.WS_CONF_CHAT_CMD_INDICATION,
        ]).emit(socketEventTypes.WS_CONF_CHAT_CMD_INDICATION, message);
        if (!message.body || message.body.cmd !== CHAT_CMD_DELETE) break;
        // deleted message
        if (
          message.body.deletedOrModifiedBy ===
            CHAT_DELETED_OR_MODIFIED_BY_DLP &&
          meetingChatList.length &&
          isMessageSentByUser(
            currentUserId,
            message.body.msgID,
            meetingChatList,
          )
        ) {
          dispatch(setChatDeletedDlpDialogVisible(true));
        }
        dispatch(deleteChat(message.body.msgID));
        break;
      }
      case socketEventTypes.WS_CONF_CHAT_DISABLED_BY_SERVER_INDICATION: {
        if (!message.body || message.body.disabled === false) {
          dispatch(meetingActions.setIsChatDisabledByDlp(false));
          break;
        }
        dispatch(
          meetingActions.setIsChatDisabledByDlp(
            message.body.by === CHAT_DISABLED_BY_NONE ||
              message.body.by === CHAT_DISABLED_BY_DLP,
          ),
        );
        break;
      }
      case socketEventTypes.WS_CONF_MEETING_TOPIC_CHANGE: {
        if (message.body.meetingTopicChange) {
          const meetingTopic = decodeBase64(message.body.meetingTopic);
          sendMsgToPWA(PWAMeetingEvent.MEETING_TOPIC_UPDATE, {
            name: meetingTopic,
          });
          dispatch(setMeetingTopicThunk(meetingTopic));
        }
        break;
      }
      case socketEventTypes.WS_CONF_MEETING_TOPIC_CHANGE_FAILED: {
        if (!message.body.meetingTopicChange) {
          // nothing
        }
        break;
      }
      case socketEventTypes.WS_CONF_PARTICIPANT_LIST_CHANGE: {
        dispatch(handleInterpretationLanguageListChange(message.body));
        break;
      }
      case socketEventTypes.WS_AUDIO_PARTICIPANT_LANGUAGE_CHANGE_RES: {
        dispatch(handleCurrentInterpretationLanguageChange(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_INTERPRETER_START: {
        dispatch(handleInterpretationStart(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_PARTICIPANT_LANGUAGE_INVALID: {
        break;
      }
      case socketEventTypes.WS_CONF_SIGN_INTERPRETER_STATUS_INDICATION: {
        if (isSignLanguageInterpretationEnabled(boStatus)) {
          dispatch(handleSignLanguageInterpretationStart(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_SIGN_INTERPRETER_LIST_CHANGE_INDICATION: {
        if (isSignLanguageInterpretationEnabled(boStatus)) {
          dispatch(handleSignLanguageInterpretersChange(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_ALLOW_SIGN_INTERPRETER_TO_TALK_INDICATION: {
        if (isSignLanguageInterpretationEnabled(boStatus)) {
          dispatch(handleSignLanguageAllowInterpreterToTalk(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_INTERPRETER_TYPE_CHANGE: {
        if (meetingConfig.languageInterpretation) {
          dispatch(handleInterpretersChange(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_RECEIVE_REACTION_RESULT: {
        dispatch(onReceiveReactionResult(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_RECEIVER_WEBINAR_EMOJI_REACTION_INDICATION: {
        dispatch(onReceiveWebinarReaction(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_ALLOW_WEBINAR_EMOJI_REACTION_INDICATION: {
        dispatch(allowWebinarReaction(message.body?.bEnable ?? false));
        break;
      }
      case socketEventTypes.WS_CONF_ARCHIVING_STATUS: {
        dispatch(handleArchivingChange(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_ARCHIVING_OPTIONS: {
        dispatch(handleArchivingChange(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_NDI_PRIVACY_LINK_INDICATION: {
        const { strPrivacy } = message.body || {};
        dispatch(setNDIInfo({ strPrivacy }));
        break;
      }
      case socketEventTypes.WS_CONF_LIVE_STREAM_STATUS: {
        const {
          channelName,
          liveStreamOn: isLiveStreamOn,
          isApiCall,
          status,
          viewerUrl,
        } = message.body || {};
        dispatch(
          setLiveStreamStatus({
            channelName,
            isLiveStreamOn,
            isApiCall,
            status,
            viewerUrl,
          }),
        );
        if (isLiveStreamOn) {
          // eslint-disable-next-line @babel/new-cap
          dispatch(setLiveStreamTip(channelName));
        }
        break;
      }
      case socketEventTypes.WS_CONF_LIVE_STREAM_BROADCAST_INFO: {
        const { broadcastToken, channels, liveStreamViewUrl, maxWallUsers } =
          message?.body ?? {};
        const channelNameMap = channels ? {} : undefined;
        if (Array.isArray(channels)) {
          channels.forEach((channel) => {
            channelNameMap[channel?.key] = channel?.value;
          });
        }
        dispatch(
          setLiveStreamBroadcastInfo({
            broadcastToken,
            liveStreamViewUrl,
            maxWallUsers,
            channelNameMap,
          }),
        );
        break;
      }
      case socketEventTypes.WS_CONF_REQ_LOCAL_LIVE_STREAM_PERMISSION_REC: {
        if (!isExternalControlledMode()) {
          dispatch(handleRequestPermissionInfo(message.body));
        }
        break;
      }
      // case socketEventTypes.WS_CONF_TOGGLE_LIVE_STREAM: {
      //   const { bOn } = message.body || {};
      //   if (!bOn) {
      //     dispatch(
      //       setLiveStreamStatus({
      //         isLiveStreamOn: false,
      //         status: LIVE_STREAM_STATUS.OFF,
      //       }),
      //     );
      //   }
      //   break;
      // }
      case socketEventTypes.WC_CONF_APP_SIGNAL_DETAIL_RES: {
        const { domain, list, result, title, summary } = message.body || {};
        dispatch(
          setAppSignalInfo({
            title: title || '',
            summary: summary || '',
            list: (list || []).map((v) => ({
              ...v,
              link: v.link && domain ? domain + v.link : undefined,
              icon: v.icon && domain ? domain + v.icon : undefined,
            })),
            status: result ? 'success' : 'fail',
          }),
        );
        break;
      }
      case socketEventTypes.WC_CONF_APP_SIGNAL_APP_LINK_RES: {
        const { appId, link, result, domain } = message.body || {};
        const validLink = link && domain ? domain + link : undefined;
        if (validLink) {
          window.open(validLink, '_blank');
        }
        dispatch(
          setAppSignalInfo({
            linkInfo: { appId, link: validLink, result },
          }),
        );
        break;
      }
      case socketEventTypes.WS_AUDIO_ENCRYPT_KEY_INDICATION: {
        const { encryptKey, additionalType } = message.body;
        cacheMainSessionEncryptKey(
          SESSIONSTORAGE_KEYS.webClient_audioEncryptKey,
          {
            encryptKey,
            additionalType,
          },
          isBoMainWebsocket(commandSocketUrl),
        );
        dispatch(
          meetingActions.setCurrentAVSEncryptKey({
            audioEncryptKey: encryptKey,
            audioEncryptType: additionalType,
          }),
        );
        break;
      }
      case socketEventTypes.WS_VIDEO_ENCRYPT_KEY_INDICATION: {
        const { encryptKey, additionalType } = message.body;
        cacheMainSessionEncryptKey(
          SESSIONSTORAGE_KEYS.webClient_videoEncryptKey,
          {
            encryptKey,
            additionalType,
          },
          isBoMainWebsocket(commandSocketUrl),
        );
        dispatch(
          meetingActions.setCurrentAVSEncryptKey({
            videoEncryptKey: encryptKey,
            videoEncryptType: additionalType,
          }),
        );
        break;
      }
      case socketEventTypes.WS_SHARING_ENCRYPT_KEY_INDICATION: {
        const { encryptKey, additionalType } = message.body;
        cacheMainSessionEncryptKey(
          SESSIONSTORAGE_KEYS.webClient_sharingEncryptKey,
          {
            encryptKey,
            additionalType,
          },
          isBoMainWebsocket(commandSocketUrl),
        );
        dispatch(
          meetingActions.setCurrentAVSEncryptKey({
            sharingEncryptKey: encryptKey,
            sharingEncryptType: additionalType,
          }),
        );
        break;
      }
      case socketEventTypes.WS_SHARING_RECEIVING_CHL_READY_INDICATION: {
        dispatch(receiveSharingChannelReady(message.body, false));
        break;
      }
      case socketEventTypes.WS_SHARING_RECEIVING_CHL_CLOSE_INDICATION: {
        const { ssrc } = message.body;
        dispatch(receiveSharingChannelClose(ssrc, false));
        break;
      }
      case socketEventTypes.WS_SHARING_WEBINAR_OUT_LIMIT_INDICATION: {
        dispatch(setShareMaximumDialogVisible({ visible: true }));
        break;
      }
      // #region process attention mode white list
      case socketEventTypes.WS_CONF_VIDEO_ATTENTION_MODE_LIST: {
        const { list } = message.body;
        dispatch(onRecvAttentionModeList(list));
        break;
      }
      case socketEventTypes.WS_CONF_SHARE_ATTENTION_MODE_LIST: {
        // body maybe null
        const { shareList } = message.body || {};
        dispatch(onSetShareAttentionModeList(shareList));
        break;
      }
      case socketEventTypes.WS_CONF_MMR_SUPPORT_MEETING_ATTENTION_MODE_INDICATION: {
        if (!_.isEmpty(message.body)) {
          dispatch(setIsMmrSupportFocusMode(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_MEETING_VIDEO_ATTENTION_MODE_ENDING_INDICATION: {
        const { bEnding } = message.body;
        dispatch(setIsFocusModeEndingThunk({ videoEnding: bEnding }));
        break;
      }
      case socketEventTypes.WS_CONF_MEETING_SHARE_ATTENTION_MODE_ENDING_INDICATION: {
        const { bEnding } = message.body;
        dispatch(setIsFocusModeEndingThunk({ shareEnding: bEnding }));
        break;
      }
      // #endregion
      case socketEventTypes.WS_CONF_USER_SHARE_PRONOUN_TYPE_INDICATION: {
        const { nShareType } = message.body;
        dispatch(setSharePronounsType(nShareType));
        break;
      }
      case socketEventTypes.WS_CONF_POLLING_IN_MEETING_OPTIONS_INDICATION: {
        dispatch(setQuizShareOption(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_FETCH_MEETING_INVITEES_INDICATION: {
        const { data = [] } = message.body;
        dispatch(onRecvNotJoinedList(data));
        break;
      }
      case socketEventTypes.WS_VIDEO_ACTIVE_SOURCE_INDICATION:
        dispatch(receivetActiveVideoTalkingIdThunk(message));
        break;
      case socketEventTypes.WS_CONF_UPLOAD_GALLERY_VIDEO_ORDER_RES: // 4280
        // // {"body":{"res":0},"evt":4280,"seq":89}
        dispatch(setIsUploadingViewOrder(false));
        break;
      case socketEventTypes.WS_CONF_DOWNLOAD_GALLERY_VIDEO_ORDER_RES: // 4282
        if (message.body && message.body.galleryViewOrder) {
          dispatch(onLoadCustomVideoOrderThunk(message.body.galleryViewOrder));
        }
        break;
      case socketEventTypes.WS_CONF_WEBINAR_SESSION_BRANDING_TEMPLATES_AND_VBS:
        dispatch(
          setNameTagTemplates(
            [NONE_TEMPLATE].concat(message.body.nameTagTemplates),
          ),
        );
        break;
      case socketEventTypes.WS_CONF_REQUEST_TOKEN_RES: {
        dispatch(handleLiveStreamTokenResponse(message.body));
        switch (message.body.type) {
          case 1: {
            dispatch(
              setZoomAppToken({
                accessAppToken: message.body.utk,
                accessAppTokenUpdateTime: new Date().getTime(),
              }),
            );
            break;
          }
          case 4: {
            dispatch(setZoomAppToken({ userInfoToken: message.body.utk }));
            break;
          }
          default:
            break;
        }
        // resolve job
        if (Job.check(JOB_ENUM.WS_CONF_REQUEST_TOKEN)) {
          Job.complete(JOB_ENUM.WS_CONF_REQUEST_TOKEN, message.body.utk);
        }
        break;
      }
      case socketEventTypes.WS_CONF_WEBINAR_SESSION_BRANDING_APPLY_CHANGES_RESULT:
        dispatch(showApplyPanelistAppearanceFailedDialog(message.body.result));
        break;
      case socketEventTypes.WS_CONF_BO_SAVE_GROUP_RES:
        dispatch(handleSaveBoRes(message.body));
        break;
      case socketEventTypes.WS_CONF_INVITEE_IAK_RES:
        dispatch(sendIakToPWA(message.body));
        break;
      case socketEventTypes.WS_CONF_CHECK_CMR_PRIVILEGE_RES:
        // eslint-disable-next-line no-case-declarations
        const { can_start_record: canStartRecord, gracePeriodDate = '' } =
          message.body;
        if (!canStartRecord && !gracePeriodDate) {
          dispatch(
            meetingUIActions.setStartRecordingNotificationVisible(false),
          );
          dispatch(showStartRecordingErrorDialog(message.body));
        } else if (!!canStartRecord && gracePeriodDate > 0) {
          dispatch(showStartRecordingGracePeriodDialog(message.body));
        }
        break;
      case socketEventTypes.WS_CONF_ALLOW_QA_AUTO_REPLY_RES:
        dispatch(setSimuliveLoading(false));
        if (message.body.bSuccess) {
          AliveToast.toast(QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_SUCCESS);
        } else {
          AliveToast.toast(QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_FAILED);
        }
        break;
      case socketEventTypes.WS_CONF_AB_TOKEN_RES: {
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.COMMAND_SOCKET_MESSAGE_NOTIFY,
          message,
        );
        break;
      }
      case socketEventTypes.WS_CONF_QA_INDICATION:
        if (_.has(message, 'body.questions')) {
          dispatch(updateQuestionThunk(message.body.questions));
        }
        if (_.has(message, 'body.answers')) {
          dispatch(updateAnswerThunk(message.body.answers));
        }
        // cmd is and array [object] or just a object
        if (message.body?.cmd) {
          [message.body.cmd]
            .flatMap((v) => v)
            .filter((v) => v)
            .forEach((v) => {
              dispatch(updateCmdThunk(v));
            });
        }
        break;
      case socketEventTypes.WS_WB_WHITEBOARD_INDICATION: {
        if (
          !(isWebinar() && isViewOnly(userRole)) &&
          !isExternalControlledMode() &&
          navigator.hardwareConcurrency > 2
        ) {
          dispatch(handlePreviousWhiteboard());
          dispatch(setIsShowWhiteboardWindow(false));
          dispatch(setWhiteboardSharer(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_WB_LOCK_SHARE_INDICATION: {
        dispatch(
          handleWhiteboardPermissionCode(message.body?.shareWbPermission),
        );
        break;
      }
      case socketEventTypes.WS_WB_WHITEBOARD_ERROR: {
        dispatch(setWhiteboardStatus(WHITEBOARD_STATUS.OFF));
        dispatch(showWhiteboardErrorDialog(message.body));
        break;
      }
      case socketEventTypes.WS_WB_AS_STREAM_ON_OFF: {
        dispatch(setIsWhiteboardShareStreamOn(message.body?.bOn));
        break;
      }
      case socketEventTypes.WS_CONF_SMS_INVITE_RES: {
        const {
          invite: { smsSendStatus },
        } = getState();
        if (smsSendStatus === 1) {
          if (message.body.result === 0) {
            dispatch(updateSmsId(message.body.messageId));
          } else {
            dispatch(updateSmsId('0'));
            dispatch(updateSmsSendStatus(SEND_SMS_STATUS.FAIL));
            dispatch(updateSmsErrorCode(message.body.result));
          }
        }
        break;
      }
      case socketEventTypes.WS_CONF_NOTIFY_USER_NETWORK_QUALITY_INDICATION: {
        dispatch(updateOtherNetworkQuality(message.body));
        break;
      }

      case socketEventTypes.WS_CONF_GET_LCP_RES: {
        const { bSuccess, lcp } = message.body || {};
        if (bSuccess && lcp) {
          sendMsgToPWA(PWAMeetingEvent.LCP_RESPONSE, { lcp });
        }
        break;
      }

      case socketEventTypes.WS_AUDIO_FEATURE_INDICATION: {
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.SET_CODEC_MODE,
          message.body,
        );
        break;
      }
      case socketEventTypes.WS_MULTI_SPEAKER_INDICATION: {
        dispatch(handleMultiSpeakerActiveList(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_SIMULIVEPLAYER_INDEX_INDICATION: {
        dispatch(handleSimuLiveVideoMessage(message.body));
        break;
      }
      case socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_INDICATION: {
        if (!isExternalControlledMode()) {
          dispatch(handleFarEndCameraControl(message.body));
        }
        break;
      }
      case socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_GROUP_RES: {
        if (
          isEnableAutoAcceptFECCInPwa() &&
          isEnableAutoAcceptCameraControl &&
          !isExternalControlledMode()
        ) {
          if (
            message.body?.type === CAMERA_CONTROL_GROUP_ACTION_TYPE.EXISITED
          ) {
            setTimeout(() => {
              dispatch(handleStartCameraControlGroup(message.body));
            }, 2000);
          } else {
            dispatch(handleStartCameraControlGroup(message.body));
          }
        }
        break;
      }
      case socketEventTypes.WS_CONF_MSDK_BYPASS_MSG:
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.RWG_COMMAND_BYPASS_TO_WCL,
          message,
        );
        break;
      default: {
        dispatch(doRWGAction(message, type));
      }
    }
    return null;
  };
}

export function socketStatusReset() {
  return (dispatch) => {
    dispatch({
      type: socketActionTypes.SOCKETSTATUS_RESET,
      data: defaultSocketStatus,
    });
  };
}

export const monitorRWGSendCurrentUserVideoRoster =
  (mute) => (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    dispatch(
      handleSocketMessage({
        evt: socketEventTypes.WS_CONF_ROSTER_INDICATION,
        body: {
          add: null,
          remove: null,
          update: [
            {
              bVideoOn: !mute,
              id: userId,
            },
          ],
        },
      }),
    );
  };

export const monitorRWGSendCurrentUserAudioRoster =
  (mute) => (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    const { userId } = currentUser;
    if (isJoinAudio(currentUser)) {
      dispatch(
        handleSocketMessage({
          evt: socketEventTypes.WS_CONF_ROSTER_INDICATION,
          body: {
            add: null,
            remove: null,
            update: [
              {
                muted: mute,
                id: userId,
              },
            ],
          },
        }),
      );
    }
  };

export function setCameraConnect(bOn) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    const body = {
      id: userId,
      bOn,
    };
    const data = {
      evt: socketEventTypes.WS_VIDEO_CAMERA_REQ,
      body,
    };
    dispatch(sendSocketMessage(data));
  };
}

const correctSomeRosterAttributeInBO = (message) => (dispatch, getState) => {
  const {
    attendeesList: { attendeesList },
    socketStatus: { commandSocketUrl },
    breakoutRoom: { mainSessionAttendeeList },
  } = getState();
  const { body, evt } = message;
  if (!isBoMainWebsocket(commandSocketUrl)) {
    return;
  }

  if (evt === socketEventTypes.WS_CONF_ROSTER_INDICATION) {
    const rosterArrays = [];
    if (!_.isEmpty(body.add)) {
      rosterArrays.push(...body.add);
    }
    if (!_.isEmpty(body.update)) {
      rosterArrays.push(...body.update);
    }
    rosterArrays.forEach((user) => {
      if (user.bCoHost !== undefined || user.role !== undefined) {
        const attendee = attendeesList.find((item) => item.userId === user.id);
        if (attendee) {
          const mainSessionAttendee = mainSessionAttendeeList.find(
            (item) => item.zoomID === attendee.zoomID,
          );
          if (mainSessionAttendee) {
            if (user.bCoHost !== undefined) {
              const coHostValueInMainSession = !!mainSessionAttendee.bCoHost;
              if (user.bCoHost !== coHostValueInMainSession) {
                logger.print(
                  `++++++ roster cohost value: roster-change: cohost ${user.bCoHost}, main session cohost value:${mainSessionAttendee.bCoHost}`,
                );
                user.bCoHost = coHostValueInMainSession;
              }
            }
            if (
              user.role !== undefined &&
              mainSessionAttendee.userRole !== undefined
            ) {
              const roleValueInMainSession = mainSessionAttendee.userRole;
              if (user.role !== roleValueInMainSession) {
                logger.print(
                  `++++++ roster cohost value: roster-change: role ${user.role}, main session role value:${roleValueInMainSession}`,
                );
                user.role = roleValueInMainSession;
              }
            }
          }
        }
      }
    });
  } else if (socketEventTypes.WS_CONF_COHOST_CHANGE_INDICATION) {
    const zoomId = easyStore.easyGet('zoomId');
    if (zoomId) {
      const currentUserInMainSession = mainSessionAttendeeList.find(
        (item) => item.zoomID === zoomId,
      );
      if (currentUserInMainSession) {
        const coHostValueInMainSession = !!currentUserInMainSession.bCoHost;
        if (body.bCoHost !== coHostValueInMainSession) {
          logger.print(
            `++++++ roster cohost value: cohost-change: ${body.bCoHost}, main session cohost value:${currentUserInMainSession.bCoHost}`,
          );
          body.bCoHost = coHostValueInMainSession;
        }
      }
    }
  }
};
